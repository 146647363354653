import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions, Box, Stack, Divider } from '@mui/material';
import FacilityBirthdayDetailsCard from "./facility-birthday-details-card.component";

const FacilityBirthdayCard = ({ facility, filterType }) => {

    const { reset } = useContext(SearchForReservationContext);
    const { facilityName, facilitySearchTerm, facilityPhotoUrl, facilityId, address = {}, birthdayParties } = facility;
    const { city, state } = address;

    const navigate = useNavigate();

    const handleClick = () => {
        reset();
        if (filterType === 'birthday-parties') {
            navigate(`birthday-parties/${facilitySearchTerm ? facilitySearchTerm : facilityId}`);
        } else {
            navigate(`/facilityinfo/${facilityId}`);
        }
    }

    let singleBirthdayParties = [];
    let groupedBirthdayParties = [];
    const allParties = Object.values(birthdayParties);

    for (const bdayParty of allParties) {
        if (bdayParty.groupId) {
            const foundGroups = groupedBirthdayParties.filter((group) => { return group.groupId === bdayParty.groupId });
            if (!foundGroups || foundGroups.length <= 0) {
                const allPartiesForGroup = allParties.filter((party) => { return party.groupId === bdayParty.groupId });
                let lowestPriced = allPartiesForGroup.sort((grp1, grp2) => {
                    if (parseFloat(grp1.price) < parseFloat(grp2.price)) {
                        return -1;
                    }
            
                    if (parseFloat(grp1.price) > parseFloat(grp2.price)) {
                        return 1;
                    }
            
                    return 0;
                });
                bdayParty.price = lowestPriced[0].price;
                groupedBirthdayParties.push(bdayParty);
            }
        } else {
            singleBirthdayParties.push(bdayParty);
        }
    }

    groupedBirthdayParties = groupedBirthdayParties.sort((grp1, grp2) => {
        if (parseFloat(grp1.price) < parseFloat(grp2.price)) {
            return -1;
        }

        if (parseFloat(grp1.price) > parseFloat(grp2.price)) {
            return 1;
        }

        return 0;
    });

    singleBirthdayParties = singleBirthdayParties.sort((grp1, grp2) => {
        if (parseFloat(grp1.price) < parseFloat(grp2.price)) {
            return -1;
        }

        if (parseFloat(grp1.price) > parseFloat(grp2.price)) {
            return 1;
        }

        return 0;
    });

    return (
        <Card variant="none" sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'stretch', alignItems: 'stretch' }}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>

                <CardMedia sx={{
                    "&.MuiCardMedia-img": {
                        width: { xs: "40vw", sm: '40vw', md: "16vw" },
                        height: { xs: "40vw", sm: '40vw', md: "16vw" },
                        borderRadius: '5px',
                        aspectRatio: '16/9'
                    }
                }}
                    component="img"
                    image={facilityPhotoUrl}
                    alt="facility photo"
                />
                <Stack mt={1}>
                    <Typography sx={{ fontSize: { xs: '2.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'600'} color={'#14254C'} component="div">
                        {facilityName}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} component="div" mt={0}>
                        {city}, {state}
                    </Typography>
                </Stack>
            </Box>
            <CardContent sx={{ display: "flex", flexDirection: 'row', flexGrow: '1', justifyContent: {xs: 'center', sm: 'center', md: 'flex-start'}}}>
                <Stack width={'100%'} display={'flex'} direction={'row'} flexWrap={'wrap'} columnGap={2} rowGap={2}>
                    {
                        groupedBirthdayParties.map((bdayParty) => {
                            return <FacilityBirthdayDetailsCard key={`facility-bday-card-${bdayParty.id}`} facilityName={facilityName} birthdayParty={bdayParty}></FacilityBirthdayDetailsCard>
                        })
                    }
                    {
                        singleBirthdayParties.map((bdayParty) => {
                            return <FacilityBirthdayDetailsCard key={`facility-bday-card-${bdayParty.id}`} facilityName={facilityName} birthdayParty={bdayParty}></FacilityBirthdayDetailsCard>
                        })
                    }
                </Stack>
            </CardContent>
        </Card >
    )
}

export default FacilityBirthdayCard;