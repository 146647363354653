import { TextField, Stack } from "@mui/material";
import { useContext, useState } from "react";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import { Box } from "@mui/material";
import { FacilityCustomerListContainer } from './facility-customer-search.styles';
import { searchFacilityCustomers } from "../../../utils/facility-api/facility-service";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceSnackbarAlert from "../../alert-snackbar/alert-snackbar.component";
import FacilityCustomerAdd from "../facility-customer-add/facility-customer-add.component";
import Popover from '@mui/material/Popover';


const FacilityCustomerSearch = ({ marginRight = '1.0vw', marginLeft = '1.0vw', setIsSearchingForCustomers, handleCustomerChange, searchCustomerName, customer}) => {

    const { userData, isAdmin } = useContext(UserContext);

    // const { reservationSearchData, setReservationSearchData, setIsSearchingForReservations } = useContext(SearchForReservationContext);
    // const { customer, searchCustomerName } = reservationSearchData;
    // const [ isSearchingForCustomers, setIsSearchingForCustomers] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showList, setShowList] = useState(false);
    const [showAddCustomer, setAddShowCustomer] = useState(false);
    const [ searchingForCustomers, setSearchingForCustomers ] = useState(false);

    const { userId } = customer ?  customer : {};
    const { internalBookingId } = userData ? userData : { internalBookingId: ""};

    const setIsSearching = (isSearching) => {
        setSearchingForCustomers(isSearching)
        if (setIsSearchingForCustomers) {
            setIsSearchingForCustomers(isSearching);
        }
    }

    const handleNameChange = (event) => {
        setCustomers([]);

        if (handleCustomerChange) {
            handleCustomerChange(event.target.value, null);
        }
    }

    const handleClick = (e) => {
        const customerId = e.currentTarget.dataset.id;
        const filteredCustomers = customers.filter(cust => cust.userId === customerId);

        const customer = filteredCustomers[0];
        if (handleCustomerChange) {
            handleCustomerChange(`${customer.userFirstName} ${customer.userLastName}`, customer);
        }

        setCustomers([]);
        setShowList(false);
    }

    const handleCustomerBlur = (event) => {
        if (!customer && searchCustomerName && searchCustomerName.trim().length > 0) {
            searchForCustomer(event);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setAnchorEl(event.currentTarget);
            doCustomerSearch();
        }
      };

    const searchForCustomer = async (event) => {
        setAnchorEl(event.currentTarget);
        doCustomerSearch();
    }

    const doCustomerSearch = async () => {
        if (searchCustomerName.trim().length > 0) {
           setIsSearching(true);
            const response = await searchFacilityCustomers(userData.facilityId, searchCustomerName.trim(), false);
            if (response && response.data && response.data.customers) {
                const allCustomers = response.data.customers.filter((cust) => {
                    return cust.userFirstName !== "Internal" && cust.userLastName !== "Booking";
                });
                if (allCustomers.length === 0) {
                    setOpenAlert(true);
                } else {
                    setShowList(true);
                    setCustomers(allCustomers);
                }
            } else {
                setOpenAlert(true);
            }

        }
       setIsSearching(false);
    }

    const closeAlertMessage = () => {
        setOpenAlert(false);
    }

    const addNewCustomer = () => {
        setAddShowCustomer(true);
    }

    const closeNewCustomer = () => {
        setAddShowCustomer(false);
    }

    const customerWasAdded = (customer) => {
        // let request = Object.assign({}, reservationSearchData);
        // request.customer = customer;
        // request.searchCustomerName = `${request.customer.userFirstName} ${request.customer.userLastName}`;
        // setReservationSearchData(request);
        handleCustomerChange(`${customer.userFirstName} ${customer.userLastName}`, customer);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setShowList(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <FacilityCustomerListContainer>
            <FacilityCustomerAdd isOpen={showAddCustomer} selectCustomer={customerWasAdded} close={closeNewCustomer}></FacilityCustomerAdd>
            <MySportSpaceSnackbarAlert isOpen={openAlert} close={closeAlertMessage} message={"No Customer Found"} buttonText={'Add Customer'} action={addNewCustomer} />
            <TextField  fullWidth sx={{ ml: { marginLeft }, mr: '1.0vw' }} id="facility-customer-search" variant="outlined" label="Search by first name, last name, email address, or phone number." type="text" name="searchCustomerName" value={searchCustomerName} onKeyDown={handleKeyDown}  onChange={handleNameChange} onBlur={handleCustomerBlur} disabled={userId === internalBookingId || searchingForCustomers} ></TextField>
            <Popover
                id={id}
                open={showList}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <List key={'facility-customers-for-search-list-key'}>
                        {
                            customers.map((c) => {

                                const { userId, userEmailAddress, userFirstName, userLastName } = c
                                return <ListItemButton selected={customer && customer.userId === userId} divider={true} key={userId} onClick={handleClick.bind(this)} data-id={userId}>
                                    <ListItemText key={`${userId}-facility-search-customer`} primary={`${userFirstName} ${userLastName}`} secondary={userEmailAddress} primaryTypographyProps={{
                                        letterSpacing: 0,
                                        color: '#14254C'
                                    }}></ListItemText>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Box>
            </Popover>
            {
                userId !== internalBookingId &&
                <Stack sx={{ mr: { marginRight } }} direction={'row'} spacing={2} mt={'1.0vh'} mb={'1.0vh'} justifyContent={'center'} >
                    <Button onClick={searchForCustomer} variant="contained" disabled={!searchCustomerName || searchCustomerName.trim().length === 0 || searchingForCustomers} size="large" >Find</Button>
                    {
                        isAdmin &&
                        <Button disabled={searchingForCustomers} variant="outlined" size="large" onClick={addNewCustomer} >New</Button>
                    }
                </Stack>
            }

        </FacilityCustomerListContainer>
    )
}

export default FacilityCustomerSearch;