import { Divider, Typography, Box, Stack, Grid } from "@mui/material";
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useContext } from "react";
import { GetBirthdayPartiesForFacility } from "../../../utils/birthday-api/birthday-service";
import BirthdayPartyInfoCard from "./birthday-info-card.component";
import BirthdayPartyGroupInfoCard from "./birthday-group-info-card.component";
import { UserContext } from "../../../contexts/user.context";


const logoImageStyle = {
    height: '15.0vw',
    width: '15.0vw',
    objectFit: 'contain'
};

const facilityImageStyle = {
    height: '25.0vw',
    width: '25.0vw',
    objectFit: 'fill',
    borderRadius: "5px",
};



const FacilityBirthdayPartiesView = () => {

    const [searchParams] = useSearchParams();

    const { isLoading, setIsLoading, facilityData, setFacilityData } = useContext(BirthdayPartyContext);
    const { facilityName } = useParams();
    const { userData } = useContext(UserContext);

    const { facilityInfo = {}, birthdayParties = [], birthdayPartyGroups } = facilityData;
    const { mainFacilityColor = "#14254C", secondaryFacilityColor = "#A9A9A9" } = !userData || (userData && !userData.isFacilityUserAccount) && facilityInfo ? facilityInfo : {};

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const fetchFacilityInfo = async () => {
        setIsLoading(true);
        const facilityResponse = await GetBirthdayPartiesForFacility(facilityName, userData && userData.isFacilityUserAccount);
        if (facilityResponse && facilityResponse.status == 200 && facilityResponse.data && facilityResponse.data.status === 'success') {
            setFacilityData(facilityResponse.data);
        } else {
            setFacilityData({});
        }
        setIsLoading(false);

        const scrollToId = searchParams.get('scrollToId');
        if (scrollToId) {
            scrollToElement(scrollToId);
        }
        
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFacilityInfo();
    }, [])

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <Stack sx={{
                height: '100%',
                width: '98%',
            }}>
                <Box>
                    <Stack mb={2} direction={'row'} display={'flex'} justifyContent={'space-evenly'}>
                        <Box borderRadius={'5px'} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                            <img style={facilityImageStyle} className="facility-logo-image" src={facilityInfo.facilityPhotoUrl ? facilityInfo.facilityPhotoUrl : "/birthday-party-table-image.webp"} alt="Logo" />
                        </Box>
                        <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                            <Typography fontSize={{ xs: '2.5vh', sm: '2.5vh', md: '3.5vh', lg: '5.0vh', xl: '5.0vh' }} mt={2} textAlign={'center'} gutterBottom color={mainFacilityColor} component="div" variant={facilityInfo.facilityName ? "h2" : "body1"}>
                                Birthday Parties At {facilityInfo.facilityName ? facilityInfo.facilityName : !isLoading ? 'We were unable to find the facility you are looking for.' : ''}
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ marginBottom: '5.0vh', marginLeft: '2.0vw', marginRight: '1.0vw'}}></Divider>
                    <Stack sx={{ marginLeft: '2.0vw', marginRight: '2.0vw', marginBottom: '2.0vh' }} spacing={2} useFlexGap flexWrap="wrap" justifyContent={'center'}>
                        {
                            birthdayParties.map((birthdayParty) => {
                                return <Stack  key={`${birthdayParty.id}-stack`}spacing={2}>
                                    <BirthdayPartyInfoCard key={birthdayParty.id} birthdayParty={birthdayParty} facilityName={facilityName}></BirthdayPartyInfoCard>
                                    <Divider key={`${birthdayParty.id}-stack-div`}></Divider>
                                </Stack>
                            })
                        }
                        {
                            birthdayPartyGroups && Object.keys(birthdayPartyGroups).map((bkey) => {
                                return <Stack key={`${bkey}-stack`} spacing={2}>
                                    <BirthdayPartyGroupInfoCard key={bkey} birthdayPartyGroup={birthdayPartyGroups[bkey]} facilityName={facilityName}></BirthdayPartyGroupInfoCard>
                                    <Divider key={`${bkey}-stack-div`}></Divider>
                                </Stack>
                            })
                        }
                    </Stack>
                </Box>
            </Stack>
        </Box >
    )
}

export default FacilityBirthdayPartiesView;