import { Card, Typography, Stack, Button, Box } from "@mui/material"
import { useNavigate } from 'react-router-dom';

const BirthdayCardForPartyInGroup = ({ birthdayParty, mainColor = "#14254C", secondaryColor = 'gray' }) => {

    const navigate = useNavigate();

    const goToPartyDetails = () => {
        navigate(`/birthday-parties/${birthdayParty.facilityId}/${birthdayParty.searchTerm ? birthdayParty.searchTerm : birthdayParty.id}`);
    }
    return (
        <Card variant='none' sx={{
            display: "flex", flexDirection: 'row', alignItems: 'center', border: `1px solid ${mainColor}`, maxWidth: '15.0vw', height: '100%'
        }}>
            <Stack height={'100%'} margin={'1.0vw'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <Stack height={'90%'} mb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography mt={1} sx={{ fontSize: { xs: '1.0vw', sm: '1.0vw', md: '1.0vw', lg: '1.25vw', xl: '1.25vw' }, color: `${mainColor}` }} textAlign={'center'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                        {birthdayParty.name}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '1.25vw', sm: '1.25vw', md: '1.50vw', lg: '1.75vw', xl: '1.75vw' }, color: `${secondaryColor}` }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                        ${birthdayParty.price}
                    </Typography>
                </Stack>
                <Button onClick={goToPartyDetails} height={'5.0vw'} variant="contained" sx={{
                    color: 'white', backgroundColor: `${mainColor}`, marginBottom: '1.0vw', ':hover': {
                        bgcolor: `${secondaryColor}`
                    }
                }} >Learn More</Button>
            </Stack>
        </Card>
    )
}

export default BirthdayCardForPartyInGroup