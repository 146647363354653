import { UserContext } from "../../../../contexts/user.context";
import mssLogo from "../../../../assets/logo-01.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchForReservationContext } from "../../../../contexts/search-for-reservation/search-for-reservation.context";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Divider, Stack } from "@mui/material";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const SearchResultCard = ({ searchResult, bookSingleSpace }) => {

    const { reservationId, areaReserved, areaAddress = "", areaAtFacility = true, facilityAreaType, facilityName, startDate, endDate, price, distance = 10, areaImageUrl, facilityImageUrl, customerMessage } = searchResult;
    const { addOrRemoveSearchResultToBook, searchResultsToBook, backToSearchUrl } = useContext(SearchForReservationContext);
    const { currentUser } = useContext(UserContext);

    const navigate = useNavigate();

    const resStartTime = moment(startDate, "YYYY-MM-DD h:mm a").format('h:mm a');
    const resEndTime = moment(endDate, "YYYY-MM-DD h:mm a").format('h:mm a');
    const isPlaceHolder = reservationId === 'PLACEHOLDER';
    const isMembersOnly = reservationId === 'MEMBERSHIPS_ONLY';
    const isPlaceHolderOrMembersOnly = isPlaceHolder || isMembersOnly ? true : false

    const searchResultClicked = () => {

        if (!isMembersOnly) {
            addOrRemoveSearchResultToBook(searchResult);

            if (bookSingleSpace) {
                navigate('/bookings/booking-summary');
            }
        } else {

            if (currentUser) {
                navigate('/memberships');
            } else {
                navigate(`/auth?redirectUrl=${backToSearchUrl}`);
            }

        }

    }

    return (
        <Card sx={{
            width: { xs: '75vw', sm: '75vw', md: '15vw' }, display: "flex", flexDirection: 'column', alignItems: 'stretch', cursor: "pointer", ":hover": { boxShadow: "0 3px 10px #14254C" }
        }}>
            <CardMedia
                component="img"
                image={areaImageUrl ? areaImageUrl : facilityImageUrl ? facilityImageUrl : "/logo192.png"}
                height="140"
                alt="facility photo"
                sx={{ borderRadius: "4px" }}
            />
            {/* <CardContent sx={{ display: "flex", flexDirection: 'column', flexGrow: '1', alignItems: 'stretch' }}> */}
            <Stack height={'100%'} ml={1} mr={1} display={'flex'} alignItems={'stretch'} justifyContent={'stretch'}>
                <Stack width={'100%'} height={'90%'} mb={2} display={'flex'}  alignItems={'stretch'}>
                    <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} fontSize={{ xs: '2.0vh', sm: '2.0vh', md: '1.0vw' }}>
                        {isMembersOnly ? areaReserved : isPlaceHolder ? 'No open spaces are available.' : `${resStartTime} - ${resEndTime}`}
                    </Typography>
                    <Typography textAlign={'center'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} fontSize={{ xs: '1.5vh', sm: '1.5vh', md: '1.0vw' }}  >
                        {customerMessage}
                    </Typography>
                    <Typography textAlign={'right'} fontWeight={'bold'} fontStyle={'Helvetica'} color={isPlaceHolderOrMembersOnly ? '#F4FAFC ' : '#14254C'} fontSize={{ xs: '2.0vh', sm: '2.0vh', md: '1.0vw' }} >
                        {!isPlaceHolderOrMembersOnly ? `$${parseFloat(price).toFixed(2)}` : "Membership"}
                    </Typography>
                    <Divider></Divider>
                    <Typography textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'#14254C'} fontSize={{ xs: '2.0vh', sm: '2.0vh', md: '1.0vw' }} >
                        {facilityName}
                    </Typography>
                    {
                        !areaAtFacility &&
                        <Typography ml={1} textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} fontWeight={'bold'} fontSize={{ xs: '1.5vh', sm: '1.5vh', md: '0.9vw' }} >
                            Area Location
                        </Typography>
                    }
                    <Typography textAlign={'right'} fontStyle={'Helvetica'} color={'dark-gray'} fontSize={{ xs: '1.5vh', sm: '1.5vh', md: '0.75vw' }} >
                        {areaAddress}
                    </Typography>
                    <Typography textAlign={'right'} fontWeight={'bold'} fontStyle={'Helvetica'} color={distance > 0 ? 'gray' : '#F4FAFC'} fontSize={{ xs: '1.5vh', sm: '1.5vh', md: '0.75vw' }} >
                        {distance > 0 ? `${distance} miles` : 'Distance '}
                    </Typography>
                    <Stack sx={{ height: '100%' }} display={'flex'} justifyContent={'flex-end'}>
                        <Typography gutterBottom textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'#14254C'} fontSize={{ xs: '2.0vh', sm: '2.0vh', md: '1.0vw' }} >
                            {isMembersOnly ? facilityName : isPlaceHolder ? 'No Open Areas' : `${areaReserved}`}
                        </Typography>
                        <Typography gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'dark-gray'} fontSize={{ xs: '1.9vh', sm: '1.9vh', md: '0.9vw' }}  >
                            {isPlaceHolderOrMembersOnly ? isMembersOnly ? 'Members Only' : "No Areas" : facilityAreaType}
                        </Typography>
                    </Stack>
                </Stack>
                {
                    !isPlaceHolderOrMembersOnly &&
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        <Button sx={{ fontSize: { xs: '1.5vh', sm: '1.5vh', md: '1.25vh' } }} startIcon={!bookSingleSpace ? searchResultsToBook.find((searchResultBooked) => searchResultBooked.reservationId === reservationId) ? <CheckIcon /> : <CheckBoxOutlineBlankIcon /> : null} onClick={searchResultClicked} variant="contained" size="medium">
                            {bookSingleSpace ? "Book This Space" : searchResultsToBook.find((searchResultBooked) => searchResultBooked.reservationId === reservationId) ? "Space Added" : "Add Space"}
                        </Button>
                    </Box>

                }
            </Stack>
            {/* </CardContent> */}
            {/* {
                !isPlaceHolderOrMembersOnly &&
                <Divider sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }}></Divider>
            }
            {
                !isPlaceHolderOrMembersOnly &&
                <CardActions sx={{ display: 'flex', justifyContent: 'center', marginTop: '1.0vh', marginBottom: '1.0vh' }}>
                    <Box sx={{ width: '95%', marginBottom: { xs: '1.0vh', sm: '1.0vh', md: '1.0vh' } }} display="flex" justifyContent="center" alignItems="center">

                        <Button sx={{ fontSize: { xs: '1.5vh', sm: '1.5vh', md: '1.25vh' } }} startIcon={!bookSingleSpace ? searchResultsToBook.find((searchResultBooked) => searchResultBooked.reservationId === reservationId) ? <CheckIcon /> : <CheckBoxOutlineBlankIcon /> : null} onClick={searchResultClicked} variant="contained" size="medium">
                            {bookSingleSpace ? "Book This Space" : searchResultsToBook.find((searchResultBooked) => searchResultBooked.reservationId === reservationId) ? "Space Added" : "Add Space"}
                        </Button>
                    </Box>
                </CardActions>
            } */}
        </Card>
    )
}

export default SearchResultCard;