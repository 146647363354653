import { BottomButtonBarContainer } from './bottom-button-bar.styles'
import Button from '@mui/material/Button';
import { Stack } from "@mui/material";

const BottomButtonBar = ({ okButtonText, okAction, cancelButtonText, cancelAction, mainColor='#14254C', secondaryColor = "#A9A9A9", okButtonIsDisabled = false }) => {

    return (
        <BottomButtonBarContainer>
            <Stack mt={5} direction={'row'} spacing={2} justifyContent={'center'} >
                {
                    cancelAction && cancelButtonText &&
                    <Button sx={{ mr: 1 , color: `${secondaryColor}` , ':hover': {
                        bgcolor: mainColor,
                        color: secondaryColor,
                    }}} onClick={cancelAction} >{cancelButtonText}</Button>
                }
                <Button disabled={okButtonIsDisabled} sx={{backgroundColor: `${mainColor}`, ':hover': {
                                bgcolor: secondaryColor,
                                color: 'white',
                            }}} variant="contained" onClick={okAction}>{okButtonText}</Button>
            </Stack>
        </BottomButtonBarContainer>
    )
}

export default BottomButtonBar