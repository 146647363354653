import React, { useState, useContext } from 'react';
import { CreditCardFormContainer } from './credit-card-form.styles';
import AddressForm from '../address-form/address-form.component';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { UserContext } from '../../contexts/user.context';
import validator from 'validator';
import { Stack, Typography, TextField, Button, Box } from '@mui/material';
import { CreditCardExpiryFormat } from '../../utils/text-field-utils/text-field-utils';

const defaultNewCreditCardFields = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: ""
}

const CreditCardForm = ({ addFormOfPayment, cancel, mainColor = "#14254C", secondaryColor = "#A9A9A9" }) => {

    const [formFields, setFormFields] = useState(defaultNewCreditCardFields);
    const [useProfileAddress, setUserProfileAddress] = useState(false);
    const { number, name, expiry, cvc, zipCode } = formFields;
    const { userData } = useContext(UserContext);

    const cancelAdd = () => {
        if (cancel) {
            cancel();
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const regex = /^[0-9\b]+$/;
        let allowSet = false;
        if (value === "") {
            allowSet = true;
        }
        // else if (name === "expiry") {
        //     if (value.length === 1 || value.length === 2) {
        //         allowSet = regex.test(value);

        //         if (parseInt(value) > 12) {
        //             allowSet = false;
        //         }
        //     }

        //     if (value.length === 3 && value.substring(2, 3) === '/') {
        //         allowSet = true;
        //     }

        //     if (value.length === 4) {
        //         allowSet = regex.test(value.substring(3, 4));
        //     }

        //     if (value.length === 5) {
        //         allowSet = regex.test(value.substring(4, 5));
        //     }
        // } 
        else if (name === 'cvc') {
            allowSet = regex.test(value);
            if (value.length > 4) {
                allowSet = false;
            }
        }
        else if (name === 'number') {
            allowSet = regex.test(value);
            if (value.length > 18) {
                allowSet = false;
            }
        }
        else {
            allowSet = true;
        }

        if (allowSet) {
            setFormFields({ ...formFields, [name]: value });
        }

    };

    const toggleUseProfileAddress = () => {

        if (!useProfileAddress && userData.contactInfo) {
            const { billingAddress1, billingAddress2, billingState, billingCity, billingZipCode } = userData.contactInfo;
            const newFormFields = { ...formFields, 'address1': billingAddress1, 'address2': billingAddress2, 'state': billingState, 'city': billingCity, ["zipCode"]: billingZipCode };
            setFormFields(newFormFields);
        } else {
            const newFormFields = { ...formFields, 'address1': '', 'address2': '', 'state': '', 'city': '', ["zipCode"]: '' };
            setFormFields(newFormFields);
        }

        setUserProfileAddress(!useProfileAddress);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validator.isCreditCard(number)) {
            alert("Please enter a valid credit card number.");
            return
        }

        if (!validator.isPostalCode(zipCode, "any")) {
            alert("Please enter a valid zip code.");
            return
        }

        if (addFormOfPayment) {
            addFormOfPayment(formFields);
        }
    }

    return (
        <CreditCardFormContainer>
            <form onSubmit={handleSubmit} className='credit-card-form'>
                <Stack spacing={1}>
                    <Typography fontSize={{xs: '4.25vw', sm: '4.25vw', md: '1.0vw'}} fontWeight={'bold'} textAlign={'center'}>Add Form Of Payment</Typography>
                    <TextField required fullWidth id="card-holder-name" variant="outlined" label="Cardholder Name" type="text" name="name" value={name} onChange={handleChange} ></TextField>
                    <TextField required fullWidth maxLength="18" id="card-number" variant="outlined" label="Card Number" type="text" name="number" value={number} onChange={handleChange} ></TextField>
                    <Stack direction={'row'}>
                        <TextField InputProps={{
                            inputComponent: CreditCardExpiryFormat
                        }} required fullWidth id="card-expiry" variant="outlined" label="Expiry (MM/YY)" type="tel" name="expiry" value={expiry} onChange={handleChange} ></TextField>
                        <TextField required maxLength="4" fullWidth id="card-cvc" variant="outlined" label="CVC (ex. 123)" type="tel" name="cvc" value={cvc} onChange={handleChange} ></TextField>
                    </Stack>
                    <Stack spacing={2} direction={'row'}>
                        <Box display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} sx={{ color: mainColor }} fontSize={{xs: '3.0vw', sm: '3.0vw', md: '0.9vw'}} >
                                Billing Address
                            </Typography>
                        </Box>
                        {userData.contactInfo &&
                            <FormControlLabel control={<Checkbox 
                                edge="start"
                                checked={useProfileAddress}
                                tabIndex={-1}
                                disableRipple
                                onChange={toggleUseProfileAddress}
                            />} label="Use Profile Address" />
                        }
                    </Stack>
                    <AddressForm defaultFormFields={formFields} handleChange={handleChange}></AddressForm>
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Stack margin={'auto'} spacing={1} direction={'row'}>
                            <Button variant="outlined" onClick={cancelAdd} sx={{fontSize: {xs: '3.25vw', sm: '3.25vw', md: '0.75vw'}}} >
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" sx={{fontSize: {xs: '3.25vw', sm: '3.25vw', md: '0.75vw'}}} >Add Payment</Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>
        </CreditCardFormContainer>
    )
};

export default CreditCardForm;