import { useEffect, useContext, useState, Fragment } from "react";
import { UserContext } from "../../../contexts/user.context";
import { retrieveCustomerReservations } from "../../../utils/facility-api/facility-service";
import { GetBirthdayPartyBookings } from "../../../utils/birthday-api/birthday-service";
import { FacilityCustomerReservationsContainer } from "./facility-view-customer-reservations.styles";
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import { Stack, Typography, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import FacilityViewCustomerReservationRow from "./facility-view-customer-reservation-row.component";
import BirthdayPartyViewBooked from "../birthday-party/birthday-party-view-booked.component";
import FacilityViewCustomerBirthdayPartyRow from "./facility-view-customer-birthday-party-row.component";
import FacilityViewReservation from "../facility-view-reservation/facility-view-reservation.component";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';


const FacilityViewCustomerReservations = ({ title, customerId, startDate, endDate, failedOnly, filterCanceled }) => {

    const { userData } = useContext(UserContext);
    const [reservations, setReservations] = useState([]);
    const [failedBirthdayPartyPayments, setFailedBirthdayPartyPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [birthdayPartyToView, setBirthdayPartyToView] = useState(null);
    const [reservationToView, setReservationToView] = useState(null);
    const [ showCanceled, setShowCanceled ] = useState(true);

    async function getReservations(customerId, facilityId, startDate, endDate, showCanceled) {
        setIsLoading(true);
        const response = await retrieveCustomerReservations(facilityId, customerId, startDate, endDate, failedOnly, showCanceled);
        if (response && response.data) {
            setReservations(response.data);
        }

        if (failedOnly) {
            const failedBirthdayPaymentsResponse = await GetBirthdayPartyBookings(facilityId, null, null, null, customerId, 'asc', true);
            if (failedBirthdayPaymentsResponse && failedBirthdayPaymentsResponse.status === 200) {
                if (!failedBirthdayPaymentsResponse.data.status || failedBirthdayPaymentsResponse.data.status === 'success') {
                    setFailedBirthdayPartyPayments(failedBirthdayPaymentsResponse.data.birthdayParties);
                }
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (userData) {
            getReservations(customerId, userData.facilityId, startDate, endDate, false);
        }

    }, [userData, customerId]);

    const viewBirthdayParty = (birthdayParty) => {
        setBirthdayPartyToView(birthdayParty);
    }

    const closeViewBirthdayParty = () => {
        setBirthdayPartyToView(null);
    }

    const closeViewReservation = () => {
        setReservationToView(null);
    }

    const viewReservation = (reservation) => {
        setReservationToView(reservation);
    }

    const refreshList = () => {
        if (userData) {
            getReservations(customerId, userData.facilityId, startDate, endDate);
        }
    }

    const toggleShowCanceled = () => {
        getReservations(customerId, userData.facilityId, startDate, endDate, showCanceled ? true : false);
        setShowCanceled(!showCanceled);
    }

    return (
        <FacilityCustomerReservationsContainer key={`cust-reses-${startDate}-${endDate}`}>
            {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!isLoading &&
                <Fragment>
                    <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-end'}>
                        <FormControlLabel control={<Checkbox checked={showCanceled} onChange={toggleShowCanceled} />} label="Show Canceled" />
                        <IconButton onClick={refreshList} aria-label="refresh" color="primary">
                            <RefreshOutlinedIcon />
                        </IconButton>
                    </Stack>
                    <Stack spacing={1}>
                        {reservations && reservations.length > 0 &&
                            reservations.map(reservation => (
                                <FacilityViewCustomerReservationRow key={reservation.reservationId} reservation={reservation} viewReservation={viewReservation}></FacilityViewCustomerReservationRow>
                            ))
                        }
                        {failedBirthdayPartyPayments && failedBirthdayPartyPayments.length > 0 &&
                            failedBirthdayPartyPayments.map(bdayParty => (
                                <FacilityViewCustomerBirthdayPartyRow key={bdayParty.id} birthdayParty={bdayParty} viewBirthdayParty={viewBirthdayParty}></FacilityViewCustomerBirthdayPartyRow>
                            ))
                        }
                        {
                            reservations.length === 0 && failedBirthdayPartyPayments.length == 0 &&
                            <Typography ml={3} fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                None
                            </Typography>
                        }
                    </Stack>
                </Fragment>
            }
            {
                reservationToView &&
                <FacilityViewReservation reservationToView={reservationToView} close={closeViewReservation}></FacilityViewReservation>
            }
            {
                birthdayPartyToView &&
                <BirthdayPartyViewBooked bookedParty={birthdayPartyToView} close={closeViewBirthdayParty}></BirthdayPartyViewBooked>
            }


        </FacilityCustomerReservationsContainer>
    )

}

export default FacilityViewCustomerReservations