import { TextField, Button, Box, Divider, Stack, Typography } from '@mui/material';
import { BookingResevationSummaryContainer } from './booking-reservation-summary.styles';
import { useContext, useState } from 'react';
import { SearchForReservationContext } from '../../../contexts/search-for-reservation/search-for-reservation.context';
import AlertWithTitle from '../../alert-with-title/alert-with-title..component';
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';


const BookingReservationSummary = ({ bookTheReservations }) => {

    const { totalWithServiceCharge, totalWithoutServiceCharge, totalServiceCharge } = useContext(SearchForReservationContext);
    const [title, setTitle] = useState(null);
    const [cancelMessage, setCancelMessage] = useState(null);

    const navigate = useNavigate();

    const bookReservations = () => {

        if (bookTheReservations) {
            bookTheReservations(title);
        }

    }

    const cancelReservationsBooking = () => {
        setCancelMessage('Are you sure you want to cancel this reservation booking?')
    }

    const handleChange = (event) => {
        const { value } = event.target;
        setTitle(value);
    };

    const goToHomePage = () => {
        navigate('/');
    }

    const clearCancelAlert = () => {
        setCancelMessage(null);
    }

    return (
        <Box display={'flex'} flexDirection={'column'} ml={2} mr={2} >
            {
                cancelMessage &&
                <AlertWithTitle okAction={goToHomePage} okButtonText={'Yes'} cancelButtonText={"No"} cancelAction={clearCancelAlert} title="Cancel Booking?" message={cancelMessage}></AlertWithTitle>
            }
            <Stack spacing={1}>
                <Stack spacing={1} ml={2} mr={2}>
                    <TextField required fullWidth id={`id-${title}`} variant="outlined" label="Reservation Title" type="text" name="reservationtitle" value={title} onChange={handleChange}>
                    </TextField>
                    {
                        !title &&
                        <FormHelperText error id="component-error-text">
                            Reservation Title is required
                        </FormHelperText>
                    }
                </Stack>

                <Divider></Divider>
                <Stack spacing={1}>
                    <Stack>
                        <Typography fontWeight={'bold'}>
                            Reservations(s) Total
                        </Typography>
                        <Typography textAlign={'right'}>${totalWithoutServiceCharge ? totalWithoutServiceCharge.toFixed(2) : '0.00'} </Typography>
                    </Stack>
                    <Stack>
                        <Typography fontWeight={'bold'}>Total Service Charges</Typography>
                        <Typography textAlign={'right'}>${totalServiceCharge ? totalServiceCharge.toFixed(2) : '0.00'} </Typography>
                    </Stack>
                    <Divider></Divider>
                    <Stack>
                        <Typography fontWeight={'bold'}>Total</Typography>
                        <Typography textAlign={'right'}>${totalWithServiceCharge ? totalWithServiceCharge.toFixed(2) : '0.00'} </Typography>
                    </Stack>
                </Stack>
                <Divider></Divider>
                <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'center'}>
                    <Button sx={{ fontSize: { xs: '4.25vw', sm: '3.25vw', md: '0.75vw' } }} variant='standard' onClick={cancelReservationsBooking} >Cancel</Button>
                    <Button sx={{ fontSize: { xs: '4.25vw', sm: '3.25vw', md: '0.75vw' } }} variant='contained' onClick={bookReservations} >Book Reservations</Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default BookingReservationSummary;