import FormOfPayment from '../../form-of-payment/form-of-payment.component';
import { useState, useContext } from 'react';
import { makeFailedReservationPayment } from "../../../utils/facility-api/facility-service";
import { UserContext } from '../../../contexts/user.context';
import MySportSpaceAlert from '../../alert/alert.component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { update } from 'firebase/database';


const FacilityPayReservation = ({isOpen, reservation, closeView, updateReservation, title = 'Make Reservation Payment' }) => {

    const [formOfPaymentType, setFormOfPaymentType] = useState(null);
    const [formOfPayment, setFormOfPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [ alertMessage, setAlertMessage] = useState(null);

    const { userData }  = useContext(UserContext);

    const selectFormOfPayment = (formOfPayment) => {
        setFormOfPayment(formOfPayment);
    }

    const selectFormOfPaymentType = (type) => {
        setFormOfPaymentType(type);
    }

    const closeThisView = () => {
        setFormOfPayment(null);
        setAlertMessage(null);
        closeView();
    }

    const makeThePayment = async () => {
        setIsLoading(true);
        const response = await makeFailedReservationPayment(userData.facilityId, reservation,  formOfPaymentType === 'Credit Card' ? formOfPayment : null, formOfPaymentType === 'Cash/Check' ? true : false);
        if (response.status === 200)  {
            if (response.data.status === 'success') {
                let message = "The reservation has been paid.";
                if (response.data.results){
                    message = "";
                    for (const result of response.data.results){
                        message = `${message} ${result}`
                    }
                    let paymentStatus = 'processing';
                    if (formOfPaymentType === 'Cash/Check') {
                        paymentStatus = 'paid';
                    }
                    if (updateReservation) {
                        updateReservation(paymentStatus);
                    }
                    setAlertMessage(message);
                }
            } else {
                setAlertMessage(response.data.error);
            }

        } else {
            setAlertMessage('The reservation was not paid.  Please try again.');
        }
        
        setIsLoading(false);
    }

    return (

        <Dialog
        open={isOpen}
        aria-labelledby="facility-payment-title"
        aria-describedby="facility-payment-description"
    >
        <MySportSpaceLoadingView isOpen={isLoading && !alertMessage}></MySportSpaceLoadingView>
        <MySportSpaceAlert isOpen={!isLoading && alertMessage} key='pay-failed-res-alert' message={alertMessage} okButtonText={'OK'} okAction={closeThisView}></MySportSpaceAlert>
        <DialogTitle id="facility-payment-alert-title">
             {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please choose a form of payment:
            </DialogContentText>
            <FormOfPayment key={'facility-make-payment-type-key'} facilityCustomer={reservation.customer} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} formOfPayment={formOfPayment} formOfPaymentType={formOfPaymentType} customerId={reservation.customerId} ></FormOfPayment>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeThisView}>
                {"Cancel"}
            </Button>
            <Button variant='contained' onClick={makeThePayment}>
                {"Continue"}
            </Button>
        </DialogActions>
    </Dialog>



        // <FacilityPayReservationContainer>
        //     {isLoading && !alertMessage &&
        //         <LoadingIndicator></LoadingIndicator>
        //     }
        //     {
        //         !isLoading && alertMessage &&
        //         <MySportSpaceAlert key='pay-failed-res-alert' message={alertMessage} okButtonText={'OK'} okAction={closeThisView}></MySportSpaceAlert>
        //     }
        //     {!isLoading && !alertMessage &&
        //         <FacilityPayFormContainer>
        //             <span className="header">Make Reservation Payment </span>
        //             <div className='form-of-payment-row'>
        //                 <FormOfPayment key={'booking-form-of-payment-type-key'} facilityCustomer={reservation.customer} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} formOfPayment={formOfPayment} formOfPaymentType={formOfPaymentType} ></FormOfPayment>
        //             </div>
        //             <div className='bottom-button-bar'>
        //                 <BottomButtonBar okButtonText={"Continue"} okAction={makeThePayment} cancelButtonText={"Cancel"} cancelAction={closeThisView}></BottomButtonBar>
        //             </div>
        //         </FacilityPayFormContainer>
        //     }

        // </FacilityPayReservationContainer>
    )
}

export default FacilityPayReservation