
import { useState, useContext, useEffect, Fragment } from 'react';
import { getFacilityCancelPolicy, cancelFacilityReservation, getFacilityCustomer } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';
import FormOfPaymentAlert from '../../alert-form-of-payment/alert-form-of-payment.component';
import { isReservationAvailable } from '../../../utils/reservation-api/reservation-service';
import FacilityRescheduleReservation from '../facility-reschedule-reservation/facility-reschedule-reservation.component';
import { UserContext } from '../../../contexts/user.context';
import { saveReservationEdits, setReservationPaidStatus } from '../../../utils/firebase/reservation-firebase-utils';
import FacilityPayReservation from '../facility-pay-reservation/facility-pay-reservation.component';
import FacilityReservationUpdateFormOfPayment from '../facility-reservation-update-form-of-payment/facility-reservation-update-form-of-payment.component';
import FacilityVerifyPassword from "../facility-verify-password/facility-verify-password.component";
import FacilityReservationAdjustAmount from '../facility-reservation-adjust-amount/facility-reservation-adjust-amount.component';
import { Box, Stack, TextField, Modal } from '@mui/material';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { styled } from "@mui/material/styles";
import ViewReservationHeader from './view-reservation-header-component';


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '90%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

const getRefundAmount = (reservation) => {
    let refundTotal = 0.0;
    if (reservation.adjustments) {
        const adjustmentIds = Object.keys(reservation.adjustments);
        for (const adjustmentId of adjustmentIds) {
            const adjustment = reservation.adjustments[adjustmentId];
            if (adjustment.refundAmount) {
                refundTotal += adjustment.refundAmount;
            }
        }
    }

    if (reservation.refund_amount) {
        refundTotal += reservation.refund_amount;
    }
    return refundTotal;
}

const getCancelChargeAmount = (reservation) => {
    if (!reservation.canceledOn) {
        return "0.00";
    }

    if (reservation.cancelChargeAmount) {
        return parseFloat(reservation.cancelFeeAmount / 100).toFixed(2);
    }
    const reservationTotal = parseFloat(getReservationTotal(reservation));
    const refundAmount = parseFloat(getRefundAmount(reservation) / 100);
    return (reservationTotal - refundAmount).toFixed(2);
}

const getAdjustmentTotal = (reservation, type) => {
    let total = 0.0;
    if (reservation.adjustments) {
        const adjustmentIds = Object.keys(reservation.adjustments);
        for (const adjustmentId of adjustmentIds) {
            const adjustment = reservation.adjustments[adjustmentId];
            if (adjustment.type === type) {
                total += adjustment.adjustmentAmount;
            }
        }
    }

    return total > 0.0 ? parseFloat(total / 100).toFixed(2) : "0.00";
}

const getFormOfPaymentText = (reservation) => {

    if (reservation.isInternalBooking) {
        return "Internal Booking"
    }

    if (reservation.facilityMembershipId) {
        return "Membership";
    }

    if (reservation.isPayingByInvoice) {
        return "Invoice";
    }

    if (reservation.isPayingWithCredit) {
        let cardBrand = "Brand N/A"
        let last4 = "Last 4 N/A"
        if (reservation.paymentDetails && reservation.paymentDetails.card) {
            cardBrand = reservation.paymentDetails.card.brand.toUpperCase();
            last4 = reservation.paymentDetails.card.last4;
        }
        return `${cardBrand} ${last4}`;
    }

    return "Cash/Check";
}

const getReservationTotal = (reservation) => {

    let reservationTotal = parseFloat(reservation.reservationTotal / 100);

    if (reservation.charges) {

        for (const charge of reservation.charges) {
            if (charge.type === 'serviceCharge') {
                const serviceChargeAmount = parseFloat(charge.amount / 100);
                reservationTotal += serviceChargeAmount;
            }
        }
    }
    return reservationTotal.toFixed(2)
}

const defaultCancelRequirements = {
    overrideAndCancel: false,
    chargeFacility: false,
    needsPassword: false,
    needsPayment: false
}

const defaultStateObject = {
    isLoading: false,
    facilityCustomer: null,
    formOfPayment: null,
    cancelReservationActions: [],
    editReservationActions: [],
    optionsReservationActions: [],
    isEditing: false,
    reservationNotes: null,
    reservationTitle: null,
    reservationSport: null
}

const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#14254C",
        color: "#000"
    }
}));

const ViewReservation = ({ isOpen, reservationToView, close }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [shwoMakeAPayment, setShowMakeAPayment] = useState(false);
    const [updateFormOfPayment, setUpdateFormOfPayment] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [cancelMessage, setCancelMessage] = useState('');
    const [canceledMessage, setCanceledMessage] = useState('');
    const [showPasswordForResadjustment, setShowPasswordForResAdjustment] = useState(false);
    const [showPasswordForCancel, setShowPasswordForCancel] = useState(false);
    const [showAdjustReservationAmount, setShowAdjustReservationAmount] = useState(false);
    const [showRescheduleDialog, setShowRescheduleDialog] = useState(false);
    const [showCancelFormOfPayment, setShowCancelFormOfPayment] = useState(false);
    const [cancelRequirements, setCancelRequirements] = useState(defaultCancelRequirements);
    const { hasReservationPrivileges, isAdmin } = useContext(UserContext);
    const [stateObject, setStateObject] = useState(defaultStateObject);
    const { facilityCustomer, formOfPayment, cancelReservationActions, editReservationActions, optionsReservationActions, isEditing, reservationNotes, reservationTitle, reservationSport } = stateObject;

    const generateCancelActions = (reservation) => {
        let actions = [];
        if (reservation && !reservation.wasCanceled && !reservation.cancelPending) {
            if (reservation.isBlackOutPeriod) {
                actions.push("Remove Blackout");

            } else if (reservation.isInternalBooking) {
                actions.push("Cancel Booking");
            } else {
                actions.push("Apply Facility Cancel Policies");
                actions.push("Refund Rental Amount");
                actions.push("Facility Void");
            }
        }

        return actions;
    }

    useEffect(() => {
        async function setupActions(reservation) {

            if (reservation) {
                setIsLoading(true);
                const cancelReservationActions = generateCancelActions(reservation);

                const { facilityId, customerId, reservationId, dateKey, isPayingByInvoice, isInternalBooking, isBlackOutPeriod, isPayingWithCredit, paidOrUnpaid, wasCanceled, cancelPending, paymentFailed, notes, title, reservedForSport } = { ...reservation };

                const editActions = ['Edit Reservation']
                const response = await isReservationAvailable(facilityId, reservationId, dateKey, true);
                if (response && response.data) {
                    if (response.data.status === 'success') {
                        editActions.push("Reschedule");
                    }
                }
                if (paymentFailed) {
                    editActions.push("Pay Failed");
                }

                if (!isPayingWithCredit && !isInternalBooking && !isPayingByInvoice) {
                    if (!paidOrUnpaid || paidOrUnpaid.toUpperCase() !== 'PAID') {
                        editActions.push("Set As Paid");
                    } else {
                        editActions.push("Set As Unpaid");
                    }
                }

                if (!cancelPending && !wasCanceled && !paymentFailed && paidOrUnpaid.toUpperCase() !== 'PAID') {
                    editActions.push("Update Form Of Payment");
                } else if (!isPayingByInvoice && !isPayingWithCredit && paidOrUnpaid.toUpperCase() !== 'PAID') {
                    editActions.push("Update Form Of Payment");
                }

                if (!wasCanceled && !cancelPending && !isBlackOutPeriod) {
                    editActions.push("Adjust Amount");
                }

                const custoemrResponse = await getFacilityCustomer(facilityId, customerId);
                const { status, customer } = custoemrResponse.data;
                let facilityCustomer = null;
                if (status === 'success') {
                    facilityCustomer = customer;
                }

                const newStateObject = { ...stateObject, "reservationSport": reservedForSport, "reservationTitle": title, "reservationNotes": notes, "facilityCustomer": facilityCustomer, "editReservationActions": editActions, "cancelReservationActions": cancelReservationActions }
                setStateObject(newStateObject);
                setIsLoading(false);
            }
        }
        setupActions(reservationToView);
    }, [reservationToView]);

    const closeView = () => {
        if (close) {
            close();
        }
    }

    const endCancelProcess = () => {
        setCancelRequirements(defaultCancelRequirements);
        setShowPasswordForCancel(false);
    }

    const showAlertMessage = (message) => {
        setAlertMessage(message);
    }

    const showCancelMessage = (text) => {
        setCancelMessage(text);
    }

    const showCanceledMessage = (text) => {
        setCanceledMessage(text);
    }

    const selectFormOfPaymentType = (type) => {

    }

    const selectFormOfPayment = (fop) => {
        const newStateObject = { ...stateObject, 'formOfPayment': fop };
        setStateObject(newStateObject);
        setCancelRequirements({ ...cancelRequirements, 'needsPayment': false });
    }

    const setNotes = (event) => {
        const { value } = event.target;
        const newStateObject = { ...stateObject, 'reservationNotes': value };
        setStateObject(newStateObject);
    }

    const cancelPasswordAccepted = () => {
        cancelTheReservation(cancelRequirements.overrideAndCancel, cancelRequirements.chargeFacility);
    }

    const performReservationCancel = () => {
        setCancelMessage(null);
        cancelTheReservation(cancelRequirements.overrideAndCancel, cancelRequirements.chargeFacility);
    }

    const cancelTheReservation = async (overridePolicyAndCancel, chargeFacilityToCancel) => {

        const { needsPassword = false, needsPayment = false } = cancelRequirements;

        if (needsPassword) {
            setCancelRequirements({ ...cancelRequirements, 'needsPassword': false });
            setShowPasswordForCancel(true);
        } else if (needsPayment) {
            if (facilityCustomer) {
                setShowCancelFormOfPayment(true);
            }
        } else {
            setShowCancelFormOfPayment(false);
            setCancelMessage(null);
            setIsLoading(true)
            const response = await cancelFacilityReservation(reservationToView, overridePolicyAndCancel ? overridePolicyAndCancel : cancelRequirements.overrideAndCancel, chargeFacilityToCancel ? chargeFacilityToCancel : cancelRequirements.chargeFacility, formOfPayment && formOfPayment.id ? formOfPayment.id : formOfPayment ? formOfPayment : null);
            if (response && response.data) {
                const { status, results, error } = response.data;
                if (status === 'success') {
                    if (results) {
                        let cancelText = "";
                        for (const result of results) {
                            cancelText = `${result}`;
                        }
                        setCancelRequirements(defaultCancelRequirements);
                        showCanceledMessage(cancelText);
                    }
                } else {
                    if (error) {
                        showAlertMessage(error);
                    } else {
                        showAlertMessage('The reservation was not canceled.');
                    }
                }
            } else {
                showAlertMessage('The reservation was not canceled.  If the issue persists please contact My Sport Space');
            }
            setIsLoading(false)
        }
    }

    const resetEdits = () => {
        const newStateObject = { ...stateObject, 'isEditing': false, 'reservationNtes': reservationToView.notes, reservationTitle: reservationToView.title };
        setStateObject(newStateObject);
    }

    const updatePaidStatus = async (paidStatus) => {
        setIsLoading(true);
        const wasUpdated = await setReservationPaidStatus(reservationToView, paidStatus === 'Set As Paid' ? "Paid" : "Unpaid");
        if (wasUpdated) {
            reservationToView.paidOrUnpaid = paidStatus === 'Set As Paid' ? "Paid" : "Unpaid";
            showAlertMessage(`The reservation has been updated to ${paidStatus === 'Set As Paid' ? "Paid" : "Unpaid"}`)

            let newEditActions = editReservationActions.filter((action) => {
                return action !== paidStatus;
            });

            newEditActions.splice(newEditActions.length - 1, 0, paidStatus === 'Set As Paid' ? 'Set As Unpaid' : 'Set As Paid')

            const newStateObject = { ...stateObject, 'isEditing': false, 'editReservationActions': newEditActions, 'reservationNtes': reservationToView.notes, 'reservationTitle': reservationToView.title };
            setStateObject(newStateObject);
        }
        setIsLoading(false);

    }

    const handleTitleChange = (event) => {
        const { value } = event.target;
        const newStateObject = { ...stateObject, 'reservationTitle': value };
        setStateObject(newStateObject);
    }

    const handleSportChange = (event) => {
        const { value } = event.target;
        const newStateObject = { ...stateObject, 'reservationSport': value };
        setStateObject(newStateObject);
    }

    const saveEdits = async () => {
        setIsLoading(true);

        if (reservationTitle.length <= 0) {
            setAlertMessage("The reservation title cannot be empty");
            return;
        }

        if (reservationSport.length <= 0) {
            setAlertMessage("The reservation sport cannot be empty");
            return;
        }

        const notesSaved = await saveReservationEdits(reservationToView.facilityId, reservationToView.customerId, reservationToView.dateKey, reservationToView.reservationId, reservationNotes, reservationTitle, reservationSport);
        if (notesSaved) {
            setAlertMessage("The reservation notes have been saved.");
            reservationToView.notes = reservationNotes
        } else {
            setAlertMessage("The reservation notes were NOT saved.");
        }

        setIsLoading(false);
        const newStateObject = { ...stateObject, 'isEditing': false };
        setStateObject(newStateObject);
    }

    const adjustReservationAmount = async () => {
        setShowAdjustReservationAmount(true);
    }
    const closeRescheduleDialog = () => {
        setShowRescheduleDialog(false);
    }

    const actionSelected = async (action) => {

        if (!hasReservationPrivileges) {
            showAlertMessage("You are not authorized.");
            return;
        }

        if (action === 'save') {
            saveEdits();
            return;
        }

        if (action === 'cancel') {
            resetEdits();
            return;
        }

        if (action === 'Reschedule') {
            setShowRescheduleDialog(true);
            return;
        }

        if (action === 'Edit Reservation') {
            const newStateObject = { ...stateObject, 'isEditing': true };
            setStateObject(newStateObject);
            return;
        }

        if (action === 'Pay Failed') {
            setShowMakeAPayment(true);
            return;
        }

        if (action === 'Set As Paid' || action === 'Set As Unpaid') {
            updatePaidStatus(action);
            return;
        }

        if (action === "Adjust Amount") {
            if (!isAdmin) {
                showAlertMessage("You are not authorized.");
                return;
            } else {
                setShowPasswordForResAdjustment(true);
                return;
            }
        }

        if (action === "Update Form Of Payment") {
            setUpdateFormOfPayment(true);
            return;
        }

        if (reservationToView.isInternalBooking) {
            cancelTheReservation(true, true);
        } else {
            setIsLoading(true);
            let overrideAndCancel = false;
            let chargeFacility = false;
            let needsPassword = true;

            switch (action) {
                case '"Apply Facility Cancel Policies"':
                    overrideAndCancel = false;
                    chargeFacility = false;
                    break;
                case 'Refund Rental Amount':
                    overrideAndCancel = true;
                    chargeFacility = false;
                    needsPassword = true;
                    break;
                case 'Facility Void':
                    overrideAndCancel = true;
                    chargeFacility = true;
                    needsPassword = true;
                    break;
                default:
                    overrideAndCancel = false;
                    chargeFacility = false;
                    needsPassword = false;
            }

            if (needsPassword && !isAdmin) {
                showAlertMessage("You are not authorized.");
                setIsLoading(false);
                return;
            }

            const response = await getFacilityCancelPolicy(reservationToView, overrideAndCancel, chargeFacility);
            if (response.data) {
                const { status, refundOrCharge, results, error } = response.data;
                if (status === 'success') {
                    if (results) {
                        let cancelText = "";
                        for (const result of results) {
                            cancelText = `${result}`;
                        }
                        const needsPayment = refundOrCharge && refundOrCharge === 'charge';
                        setCancelRequirements({
                            overrideAndCancel: overrideAndCancel,
                            chargeFacility: chargeFacility,
                            needsPayment: needsPayment,
                            needsPassword: needsPassword
                        });
                        showCancelMessage(cancelText);
                    }
                } else {
                    if (error) {
                        showAlertMessage(error);
                    } else {
                        showAlertMessage('The reservation cannot be canceled at this time.');
                    }
                }
            }

            setIsLoading(false);
        }
    }

    const closeUpdateFormOfPayment = () => {
        setUpdateFormOfPayment(false);
    }

    const closeMakeAPayment = () => {
        setShowMakeAPayment(false);
    }

    const refeshPaidStatus = (status) => {
        reservationToView.paidOrUnpaid = status;
        reservationToView.paymentFailed = false;
    }

    const closeShowPasswordForResadjustment = () => {
        setShowPasswordForResAdjustment(false);
    }

    const closeReservationAdjustmentAmount = () => {
        setShowAdjustReservationAmount(false);
    }

    const closeCancelFormOfPayment = () => {
        setShowCancelFormOfPayment(false);
    }

    const closeAlertMessage = () => {
        setAlertMessage(null);
    }

    const closeCancelMessage = () => {
        setCancelMessage(null);
    }

    const closeCanceledMessage = () => {
        setCanceledMessage(null);
        if (close) {
            close();
        }
    }

    return (
        <Modal open={isOpen}>
            <Box sx={{ ...style, width: '80%' }}>
                {
                    reservationToView &&
                    <Fragment>
                        <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                        {alertMessage &&
                            <MySportSpaceAlert isOpen={true} message={alertMessage} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
                        }
                        {
                            cancelMessage && !isLoading &&
                            <MySportSpaceAlert isOpen={true} message={cancelMessage} okButtonText={'Continue'} okAction={performReservationCancel} cancelButtonText={'DO NOT CANCEL'} cancelAction={closeCancelMessage}></MySportSpaceAlert>
                        }
                        {
                            canceledMessage && !isLoading &&
                            <MySportSpaceAlert isOpen={true} message={canceledMessage} okButtonText={'OK'} okAction={closeCanceledMessage} ></MySportSpaceAlert>
                        }
                        {
                            showPasswordForResadjustment && !isLoading &&
                            <FacilityVerifyPassword isOpen={true} verifySuccessful={adjustReservationAmount} verifyFailed={closeShowPasswordForResadjustment} closeView={closeShowPasswordForResadjustment}></FacilityVerifyPassword>
                        }

                        {
                            showPasswordForCancel &&
                            <FacilityVerifyPassword isOpen={true} verifySuccessful={cancelPasswordAccepted} verifyFailed={endCancelProcess} closeView={endCancelProcess}></FacilityVerifyPassword>
                        }
                        {
                            showAdjustReservationAmount && !isLoading &&
                            <FacilityReservationAdjustAmount isOpen={true} reservation={reservationToView} closeView={closeReservationAdjustmentAmount}></FacilityReservationAdjustAmount>
                        }

                        {
                            !isLoading && showRescheduleDialog &&
                            <FacilityRescheduleReservation isOpen={true} reservationToView={reservationToView} facilityCustomer={facilityCustomer} close={closeRescheduleDialog} closeResView={closeView}></FacilityRescheduleReservation>
                        }

                        {
                            updateFormOfPayment &&
                            <FacilityReservationUpdateFormOfPayment isOpen={true} reservation={reservationToView} closeView={closeUpdateFormOfPayment}></FacilityReservationUpdateFormOfPayment>
                        }

                        {
                            showCancelFormOfPayment && !isLoading &&
                            <FormOfPaymentAlert isOpen={true} key={'cancel-form-of-payment-alert-type-key'} message={"Please choose a form of payment:"} okButtonText={'Continue'} okAction={performReservationCancel} cancelButtonText={'DO NOT CANCEL'} cancelAction={closeCancelFormOfPayment} facilityCustomer={facilityCustomer} formOfPayment={formOfPayment} formOfPaymentType={'Credit Card'} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} allowFOPSelection={false} ></FormOfPaymentAlert>
                        }
                        {
                            shwoMakeAPayment &&
                            <FacilityPayReservation isOpen={true} reservation={reservationToView} closeView={closeMakeAPayment} updateReservation={refeshPaidStatus}></FacilityPayReservation>
                        }
                        <Stack spacing={2}>
                            <ViewReservationHeader isEditing={isEditing} reservation={reservationToView} editReservationActions={!isEditing ? editReservationActions : []} cancelReservationActions={!isEditing ? cancelReservationActions : []} optionsReservationActions={!isEditing ? optionsReservationActions : []} title={reservationToView.isInternalBooking ? "Internal Booking Details" : "Reservation Details"} closeAction={closeView} actionSelected={actionSelected} ></ViewReservationHeader>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mainAreaName" variant="outlined" label="Area Reserved" type="text" name="mainAreaName" value={reservationToView.mainAreaName} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="resStartDate" variant="outlined" label="Date Reserved" type="text" name="resStartDate" value={reservationToView.resStartDate} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="resStartTime" variant="outlined" label="Start/End Time" type="text" name="resStartTime" value={`${reservationToView.resStartTime} - ${reservationToView.resEndTime}`} ></CustomDisableInput>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput helperText={isEditing ? "Set reservation title" : ""} InputLabelProps={{ shrink: true }} size="small" disabled={!isEditing} required={isEditing ? true : false} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="reservationTitle" variant="outlined" label="Title" type="text" name="reservationTitle" value={reservationTitle} onChange={handleTitleChange} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="reservationId" variant="outlined" label="Reservation Id" type="text" name="reservationId" value={reservationToView.reservationId} ></CustomDisableInput>
                                <CustomDisableInput helperText={isEditing ? "Set reservation sport" : ""} InputLabelProps={{ shrink: true }} size="small" disabled={!isEditing} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="reservationSport" variant="outlined" label="Sport" type="text" name="reservationSport" value={reservationSport} onChange={handleSportChange} ></CustomDisableInput>
                            </Stack>
                            {reservationToView.customer &&
                                <Stack direction={'row'} spacing={2}>
                                    <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="customername" variant="outlined" label='Customer Name' type="text" name="customerName" value={`${reservationToView.customer.firstName} ${reservationToView.customer.lastName}`} ></CustomDisableInput>
                                    <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="customeremail" variant="outlined" label="Email" type="text" name="email" value={reservationToView.customer.emailAddress} ></CustomDisableInput>
                                    <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="customerphone" variant="outlined" label="Phone" type="text" name="phone" value={reservationToView.customer.phoneNumber} ></CustomDisableInput>
                                </Stack>
                            }
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="paidstatus" variant="outlined" label="Paid Status" type="text" name="paidstatus" value={reservationToView.paymentFailed ? "FAILED" : reservationToView.isInternalBooking ? "Internal Booking" : reservationToView.paidOrUnpaid} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="formofpayment" variant="outlined" label="Form Of Payment" type="text" name="formofpayment" value={getFormOfPaymentText(reservationToView)} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="price" variant="outlined" label="Price" type="text" name="price" value={`$${parseFloat(reservationToView.price).toFixed(2)}`} ></CustomDisableInput>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="price" variant="outlined" label="Service Charge" type="text" name="serviceCharge" value={reservationToView.applicationFee ? `$${parseFloat(reservationToView.applicationFee / 100).toFixed(2)}` : '$0.00'}  ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="total" variant="outlined" label="Reservation Total" type="text" name="total" value={`$${getReservationTotal(reservationToView)}`} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="adjustments" variant="outlined" label="Adjustments" type="text" name="adjustments" value={`Refunds - $${getAdjustmentTotal(reservationToView, "refund")} Charges - $${getAdjustmentTotal(reservationToView, "charge")}`} ></CustomDisableInput>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="canceledon" variant="outlined" label="Canceled On" type="text" name="total" value={reservationToView.canceledOn ? reservationToView.canceledOn : "Not Canceled"} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="cancelfee" variant="outlined" label="Cancelation Fee" type="text" name="total" value={parseFloat(getCancelChargeAmount(reservationToView)) > 0.0 ? `$${getCancelChargeAmount(reservationToView)}` : 'None'} ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="refundAmount" variant="outlined" label="Cancel Refund Amount" type="text" name="refundamount" value={parseFloat(getRefundAmount(reservationToView)) > 0.0 ? `$${parseFloat(getRefundAmount(reservationToView) / 100).toFixed(2)}` : '$0.00'} ></CustomDisableInput>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="bookedby" variant="outlined" label="Booked By" type="text" name="bookedBy" value={reservationToView.bookedBy ? reservationToView.bookedBy : "Not Available"}  ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="bookingdatetime" variant="outlined" label="Booking Date/Time" type="text" name="bookingdatetime" value={reservationToView.timeOfBooking ? moment(reservationToView.timeOfBooking, 'YYYY-MM-DD HH:mm').format('dddd, MMM D, YYYY h:mm A') : "Not Available"}  ></CustomDisableInput>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="resquotetime" variant="outlined" label="Reservation Quote Time" type="text" name="reservationquotetime" value={reservationToView.quoteDateTime ? moment(reservationToView.quoteDateTime, 'YYYY-MM-DD h:mm a').format('dddd, MMM D, YYYY h:mm A') : "Not Available"}  ></CustomDisableInput>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput InputLabelProps={{ shrink: true }} size="small" disabled={true} sx={{ mt: '1.0vh', mb: '1.0vh' }} id="facilityMembershipName" variant="outlined" label="Membership Name" type="text" name="facilityMembershipName" value={reservationToView.facilityMembershipName ? reservationToView.facilityMembershipName : "None"} ></CustomDisableInput>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <CustomDisableInput multiline helperText={isEditing ? "Set reservation notes" : ""} InputLabelProps={{ shrink: true }} size="small" disabled={!isEditing} required={isEditing ? true : false} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="reservationNotes" variant="outlined" label="Reservation Notes" type="text" name="notes" value={reservationNotes} onChange={setNotes} ></CustomDisableInput>
                            </Stack>
                        </Stack>
                    </Fragment>
                }
            </Box>
        </Modal>
    )
}

export default ViewReservation