import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions, Box, Stack } from '@mui/material';
import iconImg from '../../../assets/logo_picture.png'

const FacilityCard = ({ facility, filterType }) => {

    const { reset } = useContext(SearchForReservationContext);
    const { facilityName, facilitySearchTerm, facilityPhotoUrl, facilityLogoUrl, facilityId, sportsAvailable, address = {}, } = facility;
    const { city, state } = address;

    const navigate = useNavigate();

    const handleClick = () => {
        reset();
        if (filterType === 'birthday-parties') {
            navigate(`birthday-parties/${facilitySearchTerm ? facilitySearchTerm : facilityId}`);
        } else {
            navigate(`/facilityinfo/${facilityId}`);
        }

    }

    return (
        <Card variant="none" onClick={handleClick} sx={{display: "flex", flexDirection: 'column', alignItems: 'stretch', cursor: "pointer", ":hover": { boxShadow: "0 3px 10px #14254C" } }}>
            <CardMedia sx={{
                "&.MuiCardMedia-img": {
                    width: {xs: "40vw", sm: '40vw', md: "16vw"},
                    height:  {xs: "40vw", sm: '40vw', md: "16vw"},
                    borderRadius: '5px',
                    aspectRatio: '16/9'
                }
            }}
                component="img"
                image={facilityPhotoUrl ? facilityPhotoUrl : facilityLogoUrl ? facilityLogoUrl : iconImg}
                alt={iconImg}
            />
            <CardContent sx={{ display: "flex", flexDirection: 'row', flexGrow: '1', alignItems: 'stretch' , maxWidth: {xs: "40vw", sm: '40vw', md: "16vw"}}}>
                 <Stack width={'100%'} height={'100%'}>
                    <Box  display={'flex'} alignContent={'center'} alignItems={'flex-start'} >
                    <Typography sx={{fontSize: { xs: '2.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'600'} color={'#14254C'} component="div">
                        {facilityName}
                    </Typography>
                    </Box>
                    <Typography sx={{  fontSize: { xs: '2.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} component="div" mt={0}>
                        {city}, {state}
                    </Typography>
                </Stack>
            </CardContent>
        </Card >
    )
}

export default FacilityCard;