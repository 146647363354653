import ClickOutside from "../../click-outside/click-outside.component";
import { TextField, Box} from "@mui/material";

const SearchIconAndTextField = ({ icon, title, text, children, listcomponent, showPopUp, closePopup, color = '#14254C' }) => {

    const handleClick = () => {
        if (showPopUp) {
            showPopUp();
        }
    }

    const clickedOutside = () => {
        if (closePopup) {
            closePopup();
        }
    }

    return (
        <ClickOutside onClickOutside={clickedOutside} onClickInside={handleClick}>
            <Box sx={{width: {xs: '100%', sm: '100%', md: '19vw'}}} display={'flex'}>
                <div>
                    {listcomponent}
                    {children}
                </div>
                <TextField sx={{
                    "& .MuiInputBase-input": {
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }
                }} onClick={handleClick} InputProps={{
                    readOnly: true,
                }} fullWidth id={`id-${title}`} variant={"outlined"} label={title} type="text" name="filter" value={text ? text : "Search"} ></TextField>
            </Box>
        </ClickOutside>
    )
}

export default SearchIconAndTextField