import { useContext, useEffect, useState } from "react";
import { GetBirthdayParties, CopyBirthdayParties, UpdateBirthdayPartiesStatus, DeleteBirthdayParties, GroupBirthdayParties } from "../../../utils/birthday-api/birthday-service";
import { UserContext } from "../../../contexts/user.context";
import { Modal, Paper, Stack, Typography, Box, Button, TextField, Divider, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import BirthdayPartySetup from "./birthday-party-setup.component";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import MySportSpaceSnackbarAlert from "../../alert-snackbar/alert-snackbar.component";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BirthdayPartyBookings from "./birthday-party-bookings.component";
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import MySportSpaceAlert from "../../alert/alert.component";
import SelectBirthdayPartyGroup from "./select-birthday-party-group.component";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import { getDatabase, ref, onValue } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';


const PAGE_SIZE = 10;

function getRowId(row) {
    return row.id;
}

const hasWhitespace = (str) => {
    return /\s/.test(str);
}

const hasSpecialChars = (str) => {
    const regex = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/;
    return regex.test(str);
}

const BirthdayUngroupedPartiesTable = ({ close }) => {

    const { userData, isAdmin } = useContext(UserContext);
    const [alertMessage, setAlertMessage] = useState(null);
    const [birthdayParties, setBirthdayParties] = useState([]);
    const [birthdayPartyGroups, setBirthdayPartyGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPartyId, setSelectedPartyId] = useState(null);
    const [viewSignupsId, setViewSignupsId] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedParties, setSelectedParties] = useState([]);
    const [openAlert, setOpenAlert] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showGroupingDialog, setShowGroupingDialog] = useState(false);
    const [groupName, setGroupName] = useState(null);
    const [searchTerm, setGroupSearchTerm] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [editLockName] = useState(`EditLock-ManageSingleParties`);
    const [showAddNewParty, setShowAddNewParty] = useState(false);

    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    const viewButtonClick = (e, row) => {
        e.stopPropagation();
        setSelectedPartyId(row.id);
    };

    const viewSignupsButtonClick = (e, row) => {
        e.stopPropagation();
        setViewSignupsId(row.id);
    };

    const bookPartyClick = (e, row) => {
        if (userData) {
            navigate(`/birthday-parties/${userData.facilityId}/${row.id}?redirectUrl=/facility/birthday-parties/manage-all`);
        }
    }

    const columns = [
        {
            field: 'name',
            renderHeader: () => (
                <strong>
                    {'Name '}
                </strong>
            ),
            flex: 1.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'status',
            flex: 0.5,
            minWidth: 25,
            renderHeader: () => (
                <strong>
                    {'Status '}
                </strong>
            ),
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography color={params.value && params.value === 'active' ? 'green' : 'red'} sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'price',
            renderHeader: () => (
                <strong>
                    {'Price'}
                </strong>
            ),
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'initialDeposit',
            renderHeader: () => (
                <strong>
                    {'Initial Deposit'}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'viewButton',
            headerName: '',
            description: '',
            sortable: false,
            flex: 1.0,
            renderCell: (params) => {
                return (
                    <Stack spacing={1} mt={1} direction={'row'}>
                        <Tooltip title="View/Edit">
                            <IconButton onClick={(e) => viewButtonClick(e, params.row)}>
                                <VisibilityOutlinedIcon></VisibilityOutlinedIcon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Signups">
                            <IconButton onClick={(e) => viewSignupsButtonClick(e, params.row)}>
                                <EditNoteIcon></EditNoteIcon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Book Party">
                            <IconButton onClick={(e) => bookPartyClick(e, params.row)}>
                                <BookmarkAddOutlinedIcon></BookmarkAddOutlinedIcon>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    async function getBirthdayParties(nextPage) {
        setIsLoading(true);
        let parties = [];
        let groups = [];
        if (userData && userData.facilityId) {

            let startAfterId = null;
            let startAt = null;
            let endAt = null;
            if (nextPage) {
                startAfterId = birthdayParties.length > 0 ? birthdayParties[birthdayParties.length - 1].name : null;
            } else if (birthdayParties.length > 0) {
                startAt = birthdayParties[0].name;
            }

            const response = await GetBirthdayParties(userData.facilityId, startAfterId, startAt, endAt, false);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    parties = response.data.birthdayParties;
                    groups = response.data.groups;
                }
            }

            setBirthdayParties(parties);
            setBirthdayPartyGroups(groups);
            setIsLoading(false);
        }

    }

    useEffect(() => {

        return () => {
            if (userData) {
                removeEditLock(editLockName, userData);
            }
        }

    }, [userData, paginationModel.page]);


    useEffect(() => {

        if (userData) {
            const db = getDatabase();
            const ungroupRef = ref(db, `facility-birthday-parties/${userData.facilityId}`);
            const unsubscribe = onValue(ungroupRef, (snapshot) => {
                getBirthdayParties(false);
            })
            return unsubscribe;
        }

    }, [userData]);

    const selectParty = (id) => {
        setSelectedPartyId(id);
    }

    const closeSelectedParty = () => {
        setSelectedPartyId(null);
        getBirthdayParties();

        if (close) {
            close(null, "2");
        }
    }

    const buildGroupNames = () => {
        const groups = [];
        for (const group of birthdayPartyGroups) {
            groups.push(group.groupName);
        }
        return groups;
    }

    const add = () => {
        if (!isAdmin) {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to add birthday parties.'
            });
        } else {
            //  navigate('/facility/birthday-parties/setup');
            setShowAddNewParty(true);
        }
    }

    const closeAddNewParty = () => {
        setShowAddNewParty(false);
    }

    const edit = async () => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage({
                    title: 'Locked',
                    message: 'Another user is currently editing the reservation settings.'
                });
            }
        } else {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to edit birthday parties.'
            });
        }
    }

    const cancelEdit = () => {
        setSelectedParties([]);
        setIsEditMode(false);
        if (userData) {
            removeEditLock(editLockName, userData);
        }
    }

    const inActivate = () => {
        updateStatus('inactive');
    }

    const activate = () => {
        updateStatus('active');
    }

    const deleteParty = () => {
        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'needs_selection', message: "Please select at least 1 party to delete.", buttonText: "" });
            return;
        }

        setShowDeleteDialog(true);
    }

    const groupTheBirthdayParties = async () => {

        if ((!groupName || groupName.length <= 0) && !selectedGroup) {
            setOpenAlert({ type: 'needs_group_name', message: "You must provide a group name or existing group to group parties.", buttonText: "OK" });
            return;
        }

        if (!selectedGroup) {
            if (!searchTerm || searchTerm.length < 4) {
                setAlertMessage({
                    title: 'Invalid Group URL',
                    message: "You must provide a group url that is 1 word (no spaces).  The text must have more than 4 characters."
                });
                return;
            }
            if (hasWhitespace(searchTerm)) {
                setAlertMessage({
                    title: 'Invalid Group URL',
                    message: "The Group Url Text cannot contain spaces. "
                });
                return;
            }

            if (hasSpecialChars(searchTerm)) {
                setAlertMessage({
                    title: 'Invalid Group URL',
                    message: "The Group Url Text cannot contain special characters. "
                });
                return;
            }
        }

        setShowGroupingDialog(false);

        setIsLoading(true);

        const response = await GroupBirthdayParties(userData.facilityId, selectedParties, groupName, selectedGroup ? selectedGroup.id : null, searchTerm);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'grouping_successful', message: "Package Birthday Party Successful", buttonText: "OK" });
            getBirthdayParties(false);
            setSelectedParties([]);
        } else {
            setIsLoading(false);
            setOpenAlert({ type: 'grouping_error', message: "Package Birthday Party Error", buttonText: "" });
        }
    }

    const updateGroupName = (event) => {
        const { value } = event.target;
        setGroupName(value);
        setSelectedGroup(null);
    }

    const updateGroupSearchTerm = (event) => {
        const { value } = event.target;
        setGroupSearchTerm(value);
    }

    const deleteTheBirthdayParties = async () => {
        if (selectedParties.length > 10) {
            setOpenAlert({ type: 'max_reached', message: "Only 10 Deletes Are Allowed At A Time", buttonText: "Clear" });
        } else {
            setIsLoading(true);
            const response = await DeleteBirthdayParties(userData.facilityId, selectedParties);
            if (response && response.status === 200 && response.data.status === 'success') {
                getBirthdayParties();
                setShowDeleteDialog(false);
                setSelectedParties([]);
                setOpenAlert({ type: 'delete_successful', message: "Birthday Parties Deleted", buttonText: "" });
            } else {
                setOpenAlert({ type: 'delete_error', message: "Delete Error", buttonText: "" });
                setIsLoading(false);
                setShowDeleteDialog(false);
            }
        }
    }

    const closeDeleteDialog = () => {
        setShowDeleteDialog(false);
    }
    const closeGroupingDialog = () => {
        setShowGroupingDialog(false);
    }

    const updateStatus = async (status) => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'needs_selection', message: "Please select at least 1 party to update.", buttonText: "" });
            return;
        }

        setIsLoading(true);
        const response = await UpdateBirthdayPartiesStatus(userData.facilityId, selectedParties, status);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'status_update_successful', message: "Status Updated", buttonText: "" });
            getBirthdayParties(false);
        } else {
            setOpenAlert({ type: 'status_update_error', message: "Status Update Error", buttonText: "" });
            setIsLoading(false);
        }
    }

    const group = async () => {
        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'invalid_group_number', message: "Please select at least 1 parties to group together.", buttonText: "" });
            return;
        }
        setShowGroupingDialog(true);
    }

    const copy = async () => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'min_copies_reached', message: "Please select at least 1 party to copy.", buttonText: "" });
            return;
        }

        if (selectedParties.length > 5) {
            setOpenAlert({ type: 'max_reached', message: "Only 5 Copies Are Allowed At A Time", buttonText: "Clear" });
        } else {
            setIsLoading(true);
            const response = await CopyBirthdayParties(userData.facilityId, selectedParties);
            if (response && response.status === 200 && response.data.status === 'success') {

                const newBirthdayPartyArray = birthdayParties.concat(response.data.newBirthdayParties);
                const sortedParties = newBirthdayPartyArray.sort((party1, party2) => {
                    if (party1.name < party2.name) {
                        return -1;
                    }

                    if (party1.name > party2.name) {
                        return 1;
                    }

                    return 0;
                });
                setBirthdayParties(sortedParties);
                setSelectedParties([]);
                setOpenAlert({ type: 'copy_successful', message: "Birthday Parties Copied", buttonText: "" });
            } else {
                setOpenAlert({ type: 'copy_error', message: "Copy Error", buttonText: "" });
            }
            setIsLoading(false);
        }
    }

    const closeAlertMessage = () => {
        setOpenAlert(null);
        setAlertMessage(null);
    }

    const clearSelectedRows = () => {
        setSelectedParties([])
    }

    const closeViewBookings = (refresh) => {
        setViewSignupsId(null);
        if (refresh) {
            getBirthdayParties();
        }
    }

    const selectExistingGroup = (groupNameSelected) => {
        setGroupName("");
        setGroupSearchTerm("");

        const foundGroup = birthdayPartyGroups.filter(g => g.groupName === groupNameSelected);
        if (foundGroup.length > 0) {
            setSelectedGroup(foundGroup[0]);
        }
    }

    return (
        <Box sx={{ position: 'absolute', height: '60%', width: '94%' }} display={'flex'}>
            <Stack sx={{
                height: '100%',
                width: '95%'
            }}>
                {
                    alertMessage &&
                    <MySportSpaceAlert isOpen={true} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
                }
                {
                    showAddNewParty &&
                    <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '1%', bottom: '1%', left: '1%', right: '1%' }} open={true}>
                        <Paper sx={{ overflow: 'scroll' }}>
                            <BirthdayPartySetup key={'birthday-party-create-setup-modal'} close={closeAddNewParty} isModal={true}></BirthdayPartySetup>
                        </Paper>
                    </Modal>
                }
                <Box mt={1} mb={2} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                        {
                            isEditMode &&
                            <Stack spacing={1} direction={'row'}>
                                <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} size="small" startIcon={<GroupWorkOutlinedIcon />} id={'group-button'} onClick={group} variant="contained" >Group</Button>
                                <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} size="small" startIcon={<ContentCopyOutlinedIcon />} id={'copy-button'} onClick={copy} variant="contained" >Copy</Button>
                                <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} size="small" startIcon={<ToggleOffOutlinedIcon />} id={'inactive-button'} onClick={inActivate} variant="contained" >Inactive</Button>
                                <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} size="small" startIcon={<ToggleOnOutlinedIcon />} id={'activate-button'} onClick={activate} variant="contained" >Activate</Button>
                                <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} size="small" startIcon={<DeleteOutlineOutlinedIcon />} id={'delete-button'} onClick={deleteParty} variant="contained" >Delete</Button>
                                <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancelEdit} variant="contained" >Cancel</Button>
                            </Stack>
                        }
                        {
                            !isEditMode &&
                            <Stack spacing={1} direction={'row'}>
                                <Tooltip title="Add a new birthday party">
                                    <IconButton onClick={(e) => add(e)}>
                                        <AddOutlinedIcon></AddOutlinedIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Show actions">
                                    <IconButton onClick={(e) => edit(e)}>
                                        <SettingsOutlinedIcon></SettingsOutlinedIcon>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }

                    </Box>
                </Box>
                <DataGrid sx={{
                    font: 'Helvetica', color: '#14254C',
                    fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                }} getRowId={getRowId} loading={isLoading} rowCount={1} initialState={{ pagination: paginationModel }} pageSizeOptions={[100]} onPaginationModelChange={setPaginationModel} paginationMode="server"
                    rows={birthdayParties} getRowHeight={() => 'auto'} columns={columns} checkboxSelection={isEditMode} disableRowSelectionOnClick={true} rowSelectionModel={selectedParties} onRowSelectionModelChange={(ids) => {
                        if (!isEditMode) {
                            selectParty(ids);
                        } else {
                            setSelectedParties(ids)
                        }
                    }}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
            </Stack>
            <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '10%', bottom: '1%', left: '5%', right: '5%' }} open={selectedPartyId ? true : false}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <BirthdayPartySetup key={'birthday-party-setup-modal'} birthdayPartyId={selectedPartyId} close={closeSelectedParty} isModal={true} ></BirthdayPartySetup>
                </Paper>
            </Modal>
            <Modal sx={{ position: 'absolute', top: '10%', bottom: '-10%', left: '10%', right: '10%' }} open={viewSignupsId ? true : false}>
                <BirthdayPartyBookings close={closeViewBookings} birthdayPartyId={viewSignupsId} ></BirthdayPartyBookings>
            </Modal>
            <MySportSpaceSnackbarAlert isOpen={openAlert ? true : false} close={closeAlertMessage} message={openAlert ? openAlert.message : ""} buttonText={openAlert ? openAlert.buttonText : ''} action={openAlert && openAlert.type === 'max_reached' ? clearSelectedRows : closeAlertMessage} />
            <Dialog
                open={showDeleteDialog}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete?</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        Deleted birthday parties cannot be recovered.  Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog}>No</Button>
                    <Button onClick={deleteTheBirthdayParties} variant="contained">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showGroupingDialog}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Group Parties</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        Grouped Parties will be displayed under one card on the intial customer web view.  The customer will then be able to drill down to see the individual parties.
                    </DialogContentText>
                    <Divider sx={{ color: '#14254C' }}>NEW GROUP</Divider>
                    <Box sx={{ marginBottom: '2.0vh', marginLeft: '1.0vw', marginRight: '1.0vw' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="groupName"
                            name="groupName"
                            label="New Group Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={updateGroupName}
                            value={groupName}
                        />
                        <Box>
                            <TextField helperText={searchTerm ? `Customer link: www.mysportspace.com/birthday-parties/packages/${searchTerm}` : "Provide a custom url for this birthday group"}
                                autoFocus
                                margin="dense"
                                id="searchTerm"
                                name="searchTerm"
                                label="Group Url"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={updateGroupSearchTerm}
                                value={searchTerm}
                            />
                        </Box>
                    </Box>
                    {
                        birthdayPartyGroups.length > 0 &&
                        <Box>
                            <Divider sx={{ color: '#14254C' }}>OR ADD TO EXISTING</Divider>
                            <Box sx={{ marginTop: '2.0vh', marginBottom: '2.0vh', marginLeft: '1.0vw', marginRight: '1.0vw' }}>
                                <SelectBirthdayPartyGroup groups={buildGroupNames()} groupSelected={selectedGroup ? selectedGroup.groupName : ''} selectGroup={selectExistingGroup}></SelectBirthdayPartyGroup>
                            </Box>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeGroupingDialog}>Cancel</Button>
                    <Button onClick={groupTheBirthdayParties} variant="contained">Group</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}

export default BirthdayUngroupedPartiesTable