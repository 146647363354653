import { useContext, useEffect, useState } from "react";
import { GroupBirthdayParties } from "../../../utils/birthday-api/birthday-service";
import { UserContext } from "../../../contexts/user.context";
import { Stack, Typography, Box, Button, Popover, FormLabel, Divider } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MySportSpaceSnackbarAlert from "../../alert-snackbar/alert-snackbar.component";
import MySportSpaceAlert from "../../alert/alert.component";
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { SketchPicker } from 'react-color';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import { uploadFileToStorageLocation } from "../../../utils/firebase/upload-facility-image";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";

const validFileTypes = ["image/png", "image/jpeg", "image/webp"];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const BirthdayPartyGroupSetup = ({ selectedGroup, close }) => {

    const { userData, isAdmin } = useContext(UserContext);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [openAlert, setOpenAlert] = useState(null);
    const [birthdayPartyGroup, setBirthdayPartyGroup] = useState(selectedGroup);
    const [originalBirthdayPartyGroup, setOriginalBirthdayPartyGroup] = useState(Object.assign({}, selectedGroup));
    const [showMainColorPicker, setShowMainColorPicker] = useState(false);
    const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);
    const [newImageFile, setNewImageFile] = useState(null);
    const { id, groupName, searchTerm, description, mainTextColor = "#14254C", secondaryTextColor = '#A9A9A9', imageLocationURL, imageStorageLocation } = birthdayPartyGroup;
    const [editLockName] = useState(`EditLock-BdayGroup-${id}`);

    useEffect(() => {
        return () => {
            if (userData) {
                removeEditLock(editLockName, userData);
            }
        }
    }, [userData]);

    const closeView = (e) => {
        if (close) {
            close();
        }
    }

    const save = async () => {

        if (!groupName || groupName.length <= 0) {
            setOpenAlert({ type: 'needs_group_name', message: "You must provide a package name to group parties.", buttonText: "OK" });
            return;
        }

        if (!description || description.length <= 0) {
            setOpenAlert({ type: 'needs_description', message: "Please provide a short description of this group.", buttonText: "OK" });
            return;
        }

        setIsLoading(true);

        let imageLocationData = null;
        if (newImageFile) {
            try {
                const uploadResponse = await uploadFileToStorageLocation(newImageFile, `birthdayPartyGroupImages/${userData.facilityId}/${birthdayPartyGroup.id}`);
                imageLocationData = {
                    imageLocationURL: uploadResponse.downloadURL,
                    imageStorageLocation: uploadResponse.storageLocation
                };
            } catch (error) {
                console.log(error);
                setAlertMessage({ title: "Image Upload Error", message: `Unable to upload the new area image.  Please try again.`, type: 'ok' });
                return;
            }
        } else {
            imageLocationData = {
                imageLocationURL: imageLocationURL,
                imageStorageLocation: imageStorageLocation
            };
        }

        const response = await GroupBirthdayParties(userData.facilityId, null, groupName, id, searchTerm, description, mainTextColor, secondaryTextColor, imageLocationData);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'grouping_successful', message: "Birthday Party Group Saved", buttonText: "OK" });
            const newBirthdayPartyGroup = { ...birthdayPartyGroup, ['imageLocationURL']: imageLocationData.imageLocationURL, ['imageStorageLocation']: imageLocationData.imageStorageLocation };
            setBirthdayPartyGroup(newBirthdayPartyGroup);
            setOriginalBirthdayPartyGroup(Object.assign({}, newBirthdayPartyGroup));
            setIsEditMode(false);
        } else {
            setOpenAlert({ type: 'grouping_error', message: "Birthday Party Group Save Error", buttonText: "" });
        }

        setIsLoading(false);
    }

    const edit = async () => {
        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage(`Another user is currently editing the reservation settings.`);
            }
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const cancelEdit = () => {
        setIsEditMode(false);
        setBirthdayPartyGroup(originalBirthdayPartyGroup);
        setNewImageFile(null);
        removeEditLock(editLockName, userData);
    }

    const closeAlertMessage = () => {
        setOpenAlert(null);
        setAlertMessage(null);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBirthdayPartyGroup({ ...birthdayPartyGroup, [name]: value });
    }

    const closePopover = () => {
        setShowSecondaryColorPicker(false);
        setShowMainColorPicker(false);
        setPopOverAnchorEl(null);
    }

    const handleMainColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your main color.")
            return;
        }

        setBirthdayPartyGroup({ ...birthdayPartyGroup, ["mainTextColor"]: color.hex });
    };

    const handleSecondaryColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your secondary color.")
            return;
        }

        setBirthdayPartyGroup({ ...birthdayPartyGroup, ["secondaryTextColor"]: color.hex });
    };

    const openMainColorPicker = (event) => {
        if (isEditMode) {
            setPopOverAnchorEl(event.currentTarget);
            setShowSecondaryColorPicker(false);
            setShowMainColorPicker(!showMainColorPicker);
        } else {
            setAlertMessage({
                title: 'Pick Group Color',
                message: "Must be in edit mode to update the primary text color."
            });
        }
    }

    const openSecondaryColorPicker = (event) => {
        if (isEditMode) {
            setPopOverAnchorEl(event.currentTarget);
            setShowMainColorPicker(false);
            setShowSecondaryColorPicker(!showSecondaryColorPicker);
        } else {
            setAlertMessage({
                title: 'Pick Group Color',
                message: "Must be in edit mode to update the secondary text color."
            });
        }
    }

    const newImageFileUploaded = (event) => {

        const files = Array.from(event.target.files);

        if (files.length > 1) {
            setAlertMessage({
                title: 'Too Many Files',
                message: "Only one file is allowed."
            });
            return;
        }

        if (files && files.length) {
            for (const file of files) {
                if (!validFileTypes.includes(file.type)) {
                    setAlertMessage({
                        title: 'Invalid Image Format',
                        message: "Image must be in .png, .jpeg or .webp format."
                    });
                    return;
                }

                const size = (file.size / 1024 / 1024).toFixed(2);
                if (size > 4) {
                    setAlertMessage({
                        title: 'Image Too Large',
                        message: "File size cannot be greater than 4MB."
                    });
                    return;
                }
            }
        }

        setNewImageFile(files[0]);
    }

    const handleDeleteImage = () => {
        setNewImageFile(null);
        setBirthdayPartyGroup({ ...birthdayPartyGroup, ['imageLocationURL']: null });
    }

    return (
        //   <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'50px'} left={'0%'} right={'0%'} display={'flex'}>
        <Stack mt={3} ml={3} mb={3} width={'96%'} >
            <MySportSpaceSnackbarAlert isOpen={openAlert ? true : false} close={closeAlertMessage} message={openAlert ? openAlert.message : ""} buttonText={openAlert ? openAlert.buttonText : ''} action={closeAlertMessage} />
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            <Box mt={1} mb={2} display="flex" justifyContent="flex-start" alignItems="center">
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Button onClick={closeView} startIcon={<CloseOutlinedIcon />}>
                    </Button>
                </Box>
                <Typography sx={{ width: '50%' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={1}>
                    Birthday Party Group
                </Typography>
                {
                    isEditMode &&
                    <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                        <Stack spacing={1} direction={'row'}>
                            <Button size="small" startIcon={<SaveOutlinedIcon />} id={'save-button'} onClick={save} variant="contained" >Save</Button>
                            <Button size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancelEdit} variant="contained" >Cancel</Button>
                        </Stack>
                    </Box>
                }
                {
                    !isEditMode &&
                    <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button size="small" startIcon={<EditOutlinedIcon />} id={'edit-button'} onClick={edit} variant="contained" >Edit</Button>
                    </Box>
                }
            </Box>
            <Stack sx={{ width: '100%' }} direction={'row'}>
                <Stack mt={1} ml={1} mr={1} display={'flex'} justifyContent={'center'}>
                    <Stack width={'100%'}>
                        <CustomDisableInput disabled={!isEditMode} size="small" required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="groupName" variant="outlined" label="Group Name" type="text" name="groupName" value={groupName ? groupName : ""} onChange={handleChange} ></CustomDisableInput>
                        <CustomDisableInput disabled={!isEditMode} helperText={`Customer link: www.mysportspace.com/birthday-parties/${userData.searchTerm ? userData.searchTerm : userData.facilityId}/package/${searchTerm ? searchTerm : id}`} size="small" fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="searchTerm" variant="outlined" label="Group Web Url" type="text" name="searchTerm" value={searchTerm ? searchTerm : ""} onChange={handleChange} ></CustomDisableInput>
                        <CustomDisableInput disabled={!isEditMode} size="small" required fullWidth sx={{ mt: '1.0vh', mb: '2.0vh' }} id="description" variant="outlined" label="Description" type="text" name="description" value={description ? description : ""} onChange={handleChange} ></CustomDisableInput>
                    </Stack>
                    <Divider></Divider>
                    <Stack mt={2} mb={2} spacing={4} direction={'row'} display={'flex'} alignContent={'center'}>
                        <Stack mt={5} ml={2} mr={2} spacing={1}>
                            <Stack spacing={1} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                <FormLabel sx={{ fontWeight: 'bold', color: "#14254C" }}>Group Party Image</FormLabel>
                                {
                                    isEditMode &&
                                    <Button
                                        component="label"
                                        role={undefined}
                                        tabIndex={-1}
                                        startIcon={<EditOutlinedIcon />}
                                    >
                                        <VisuallyHiddenInput onChange={newImageFileUploaded} type="file" />
                                    </Button>
                                }
                                {
                                    isEditMode && imageLocationURL &&
                                    <Button startIcon={<DeleteIcon />} onClick={handleDeleteImage}></Button>
                                }
                            </Stack>
                            <Typography variant="subtitle2" color={"darkgray"}>Use an image in 16X9 format for best resolution.  This image will be displayed to the customer when viewing party group</Typography>
                            <Divider></Divider>
                            <Box sx={{ height: '25vh', width: '20vh', backgroundColor: 'lightgray' }}>
                                <img
                                    srcSet={`${newImageFile ? URL.createObjectURL(newImageFile) : imageLocationURL && imageLocationURL.includes('blob') ? imageLocationURL : imageLocationURL + '&w=164&h=164&fit=crop&auto=format&dpr=2 2x'}`}
                                    src={`${newImageFile ? URL.createObjectURL(newImageFile) : imageLocationURL && imageLocationURL.includes('blob') ? imageLocationURL : imageLocationURL + '&w=164&h=164&fit=crop&auto=format&dpr=2 2x'}`}
                                    alt={isEditMode ? 'No Image' : "Edit to Add Image"}
                                    loading='lazy'
                                    width={'100%'}
                                    height={'100%'}
                                    style={{ backgroundColor: `${imageLocationURL || newImageFile ? 'white' : 'gray'}`, objectFit: 'contain' }}
                                />
                            </Box>
                        </Stack>
                        <Stack mt={1} mb={2} spacing={1}>
                            <FormLabel sx={{ fontWeight: 'bold', color: "#14254C" }}>Group Party Colors</FormLabel>
                            <Typography variant="subtitle2" color={"darkgray"}>Colors used when displying Group Card to customer</Typography>
                            <Divider></Divider>
                            <Stack spacing={4} direction={'row'} display={'flex'} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                                <Typography sx={{ fontSize: '2.0vh', width: '75%' }} variant="subtitle1" color={"#14254C"}>Main Text Color</Typography>
                                <Box display={'flex'} justifyContent={'center'} sx={{
                                    height: '25px',
                                    width: '25px',
                                    backgroundColor: `${mainTextColor}`,
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    cursor: 'pointer'
                                }} onClick={openMainColorPicker}>
                                </Box>

                                <Popover
                                    id={'main-text-color-popover'}
                                    open={showMainColorPicker}
                                    anchorEl={popoverAnchorEl}
                                    onClose={closePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <SketchPicker color={mainTextColor} onChangeComplete={handleMainColorSelected} ></SketchPicker>
                                </Popover>
                            </Stack>
                            <Stack spacing={4} direction={'row'} display={'flex'} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                                <Typography sx={{ fontSize: '2.0vh', width: '75%' }} variant="subtitle1" color={"#14254C"}>Secondary Text Color</Typography>
                                <Box display={'flex'} justifyContent={'center'} sx={{
                                    height: '25px',
                                    width: '25px',
                                    backgroundColor: `${secondaryTextColor}`,
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    cursor: 'pointer'
                                }} onClick={openSecondaryColorPicker} >
                                </Box>
                                <Popover
                                    id={'main-text-color-popover'}
                                    open={showSecondaryColorPicker}
                                    anchorEl={popoverAnchorEl}
                                    onClose={closePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <SketchPicker color={secondaryTextColor} onChangeComplete={handleSecondaryColorSelected}></SketchPicker>
                                </Popover>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>

        //  </Box>
    )
}

export default BirthdayPartyGroupSetup;