import { FacilityCustomerReservationSearchContainer } from './facility-customer-reservation-search.styles';
import Calendar from 'react-calendar';
import { useState, useEffect, useContext, Fragment } from 'react';
import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import { getReservationListForCustomer, getCustomerReservationsCatalog } from '../../../utils/facility-api/facility-service';
import { UserContext } from '../../../contexts/user.context';
import { useLocation } from 'react-router-dom';
import FacilityCustomerReservationSearchRow from './facility-customer-reservation-search-row.component';
import emptycheckboxImg from '../../../assets/empty-checkbox.svg';
import checkedBoxImg from "../../../assets/checked-checkbox.svg";
import MySportSpaceAlert from '../../alert/alert.component';
import PaginationBar from '../../../components/pagination-bar/pagination-bar.component';
import FacilityViewReservation from '../facility-view-reservation/facility-view-reservation.component';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const FacilityCustomerReservationSearch = () => {

    const { userData } = useContext(UserContext);
    const [ reservationToView, setReservationToView ] = useState(null);
    const location = useLocation();
    const [facilityCustomer, setFacilityCustomer] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [reservationsByDate, setReservationsByDate] = useState({});
    const [showingCanceled, setShowingCanceled] = useState(false);
    const [showingPaymentFailed, setShowingPaymentFailed] = useState(false);
    const [ filterCanceled, setFilterCanceled ] = useState(false);
    const [ paidStatus, setPaidStatus ] = useState(null);
    const [useStartDate, setUseStartDate] = useState(false);
    const [useEndDate, setUseEndDate] = useState(false);
    const [searchStartDate, setSearchStartDate] = useState(null);
    const [searchEndDate, setSearchEndDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const [reservationsCatalog, setReservationsCatalog] = useState({});
    const [showMore, setShowMore] = useState(true);

    const performNewSearch = async (facilityCustomer, facilityId, startDate, endDate, failedOnly, canceledOnly, filterCanceled, paidStatus) => {
        setIsLoading(true);
        setShowMore(true);

        let userId = facilityCustomer.userId;
        const sortType = startDate && !endDate ? 'desc' : startDate && endDate ? 'desc' : 'asc';
        let startDateStr = null;
        if (startDate) {
            startDateStr = moment(startDate).format("YYYY-MM-DD");
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = moment(endDate).format("YYYY-MM-DD");
        }

        const reservationsCatalog = await getReservationsCatalog(userId, facilityId, startDateStr, endDateStr, sortType, failedOnly, canceledOnly, filterCanceled, paidStatus);
        setReservationsCatalog(reservationsCatalog);
        if (reservationsCatalog && reservationsCatalog.length > 0) {
            const page1Entry = getPageFromCatalog(1, reservationsCatalog);
            if (page1Entry) {
                setPageNumbers(getPageNumbersFromCatalog(reservationsCatalog));
                await getReservationsForPage(userId, userData.facilityId, page1Entry, sortType, failedOnly, canceledOnly, filterCanceled, paidStatus);
            } else {
                setIsLoading(false);
            }
        } else {
            setReservationsByDate({});
            setIsLoading(false);
        }

    }

    const getReservationsForPage = async (customerId, facilityId, pageEntry, sortType, failedOnly, canceledOnly, filterCanceled, paidStatus) => {

        if (!isLoading) {
            setIsLoading(true);
        }

        const response = await getReservationListForCustomer(customerId, facilityId, pageEntry.startDate, pageEntry.endDate, sortType, failedOnly, canceledOnly, filterCanceled, paidStatus);
        const { status, data } = { ...response };
        if (status === 200 && data) {
            const { reservationsToDate } = { ...data };
            setReservationsByDate(reservationsToDate);
        } else {
            setAlertMessage("An error occurred.")
            setReservationsByDate({});
        }
        setIsLoading(false);
    }

    const getPageFromCatalog = (pageNum, reservationsCatalog) => {
        if (reservationsCatalog) {
            for (const entry of reservationsCatalog) {
                if (entry.pageNumber === pageNum) {
                    return entry;
                }
            }
        }
        return null;
    }

    const getPageNumbersFromCatalog = (reservationsCatalog) => {
        const pageNums = [];
        for (const entry of reservationsCatalog) {
            pageNums.push(entry.pageNumber);
        }
        return pageNums;
    }

    const getReservationsCatalog = async (customerId, facilityId, startDate, endDate, sortType, failedOnly, canceledOnly, pa) => {

        const response = await getCustomerReservationsCatalog(facilityId, customerId, startDate, endDate, sortType, failedOnly, canceledOnly, filterCanceled);
        const { status, data } = { ...response };
        if (status === 200 && data) {
            const { oldesReservationDate, reservationCatalog } = { ...data };
            return reservationCatalog;
        } else {
            return [];
        }
    }

    const setupFacilityCustomer = (facilityCustomer) => {
        setFacilityCustomer(facilityCustomer);
    }

    useEffect(() => {
        if (userData){
            setupFacilityCustomer(location.state.facilityCustomer);
            performNewSearch(location.state.facilityCustomer, userData.facilityId, searchStartDate, searchEndDate, false, false);
        }
     
    }, [userData]);

    const startDateSelected = (date) => {

        if (useEndDate && searchEndDate) {
            if (moment(date).isAfter(moment(searchEndDate))) {
                setAlertMessage("Start Date cannot be greater than End Date.")
                return;
            }
        }

        setSearchStartDate(date)
        setCurrentPage(1);
        performNewSearch(facilityCustomer, userData.facilityId, date, searchEndDate, showingPaymentFailed, showingCanceled, filterCanceled, paidStatus);

    }

    const endDateSelected = (date) => {
        if (useStartDate && searchStartDate) {
            if (moment(date).isBefore(moment(searchStartDate))) {
                setAlertMessage("End Date cannot be less than Start Date.")
                return;
            }
        }

        setSearchEndDate(date);
        setCurrentPage(1);
        performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, date, showingPaymentFailed, showingCanceled, filterCanceled, paidStatus);
    }

    const toggleCancledOnly = () => {
        performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, searchEndDate, showingPaymentFailed, !showingCanceled, false, paidStatus);
        setShowingCanceled(!showingCanceled);
        setFilterCanceled(false);
    }

    const toggleFilterCanceled = () => {
        performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, searchEndDate, showingPaymentFailed, false, !filterCanceled, paidStatus);
        setShowingCanceled(false);
        setFilterCanceled(!filterCanceled);
    }

    const togglePaymentFailedOnly = () => {
        performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, searchEndDate, !showingPaymentFailed, showingCanceled, filterCanceled, null);
        setShowingPaymentFailed(!showingPaymentFailed);
        setPaidStatus(null);
    }

    const toggleUseStartDate = () => {

        if (useStartDate) {
            setSearchStartDate(null);
            performNewSearch(facilityCustomer, userData.facilityId, null, searchEndDate, showingPaymentFailed, showingCanceled, filterCanceled, paidStatus);
        }

        setUseStartDate(!useStartDate)
    }

    const toggleUseEndDate = () => {
        if (useEndDate) {
            setSearchEndDate(null);
            performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, null, showingPaymentFailed, showingCanceled, filterCanceled, paidStatus);
        }

        setUseEndDate(!useEndDate)
    }

    const togglePaidStatus = () => {
        performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, null, showingPaymentFailed, showingCanceled, filterCanceled, paidStatus ? null : 'paid');
        setPaidStatus(paidStatus === 'paid' ? null : 'paid');
    }

    const toggleUnpaidStatus = () => {
        performNewSearch(facilityCustomer, userData.facilityId, searchStartDate, null, showingPaymentFailed, showingCanceled, filterCanceled, paidStatus ? null : 'unpaid');
        setPaidStatus(paidStatus === 'unpaid' ? null : 'unpaid');
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const GoToPage = (page) => {
        setCurrentPage(page);
        const pageEntry = getPageFromCatalog(page, reservationsCatalog);
        getReservationsForPage(facilityCustomer.userId, userData.facilityId, pageEntry, searchStartDate && !searchEndDate ? 'desc' : searchStartDate && searchEndDate ? 'desc' : 'asc', showingPaymentFailed, showingCanceled);
    }

    const loadMoreCatalog = async () => {
        const lastCatalogEntry = reservationsCatalog[reservationsCatalog.length - 1];
        const { userId } = { ...facilityCustomer };
        setIsLoading(true);
        setShowMore(true);
        const nextStartDate = moment(lastCatalogEntry.endDate, "YYYY-MM-DD").add(1, 'day').format('YYYY-MM-DD');
        const resCatalog = await getReservationsCatalog(userId, userData.facilityId, nextStartDate, null, searchStartDate && !searchEndDate ? 'desc' : searchStartDate && searchEndDate ? 'desc' : 'asc', showingPaymentFailed, showingCanceled);
        if (resCatalog.length > 0) {
            const combinedCatalog = reservationsCatalog.concat(resCatalog);
            setReservationsCatalog(combinedCatalog);
            const page1Entry = getPageFromCatalog(1, resCatalog);
            setPageNumbers(getPageNumbersFromCatalog(combinedCatalog));
            GoToPage(page1Entry);
        } else {
            setAlertMessage('No more reservations found.');
            setShowMore(false);
            setIsLoading(false);
        }
    }

    const closeViewReservation = () => {
        setReservationToView(null);
    }

    const viewTheReservation = (reservation) => {
        setReservationToView(reservation);
    }

    return (
        <FacilityCustomerReservationSearchContainer>

            {
                alertMessage &&
                <MySportSpaceAlert message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            }

            {
                !isLoading && reservationToView &&
                <FacilityViewReservation reservationToView={reservationToView} close={closeViewReservation}></FacilityViewReservation>
            }

            <div className='search-bar'>
                <div className='date-container'>
                    <div className='datelbl-and-checkbox'>
                        <span className='title'>Start Date</span>
                        <img src={useStartDate ? checkedBoxImg : emptycheckboxImg} className={`date-btn`} onClick={toggleUseStartDate}></img>
                    </div>
                    {
                        useStartDate &&
                        <Calendar calendarType='gregory' value={searchStartDate} onChange={startDateSelected} />
                    }
                </div>
                <div className='date-container'>
                    <div className='datelbl-and-checkbox'>
                        <span className='title'>End Date</span>
                        <img src={useEndDate ? checkedBoxImg : emptycheckboxImg} className={`date-btn`} onClick={toggleUseEndDate}></img>
                    </div>
                    {
                        useEndDate &&
                        <Calendar calendarType='gregory' value={searchEndDate} onChange={endDateSelected} />
                    }

                </div>
                <div className='filters'>
                    <span className='header'> Filters </span>
                    <div className='check-box-and-title'>
                        <span className='filter-label-text'>Remove Canceled</span>
                        <img src={filterCanceled ? checkedBoxImg : emptycheckboxImg} className={`filter-btn`} onClick={toggleFilterCanceled}></img>
                    </div>
                    <div className='check-box-and-title'>
                        <span className='filter-label-text'>Canceled Only</span>
                        <img src={showingCanceled ? checkedBoxImg : emptycheckboxImg} className={`filter-btn`} onClick={toggleCancledOnly}></img>
                    </div>
                    <div className='check-box-and-title'>
                        <span className='filter-label-text'>Payment Failed</span>
                        <img src={showingPaymentFailed ? checkedBoxImg : emptycheckboxImg} className={`filter-btn`} onClick={togglePaymentFailedOnly}></img>
                    </div>
                    <div className='check-box-and-title'>
                        <span className='filter-label-text'>Paid Only</span>
                        <img src={paidStatus && paidStatus === 'paid' ? checkedBoxImg : emptycheckboxImg} className={`filter-btn`} onClick={togglePaidStatus}></img>
                    </div>
                    <div className='check-box-and-title'>
                        <span className='filter-label-text'>Unpaid Only</span>
                        <img src={paidStatus && paidStatus === 'unpaid' ? checkedBoxImg : emptycheckboxImg} className={`filter-btn`} onClick={toggleUnpaidStatus}></img>
                    </div>
                </div>
            </div>
            <div className='search-results'>
                <div className='search-container'>
                    <div className='title-and-pages'>
                        <span className='title'>{facilityCustomer.userFirstName} {facilityCustomer.userLastName}</span>
                        {!isLoading &&
                            <PaginationBar showMore={showMore} setShowMore={loadMoreCatalog} numberOfPages={pageNumbers.length} currentPage={currentPage} setCurrentPage={GoToPage}></PaginationBar>
                        }
                    </div>
                    {
                        isLoading &&
                        <LoadingIndicator></LoadingIndicator>
                    }
                    {!isLoading && Object.keys(reservationsByDate).length > 0 &&
                        Object.keys(reservationsByDate).map((datekey) => {
                            return <div key={`${datekey}-list-container`} className='reservations-list-container'>
                                <span key={`${datekey}-date-header`} className='date-header'>{moment(datekey, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')}</span>
                                <div>
                                    <Fragment>
                                        {
                                            reservationsByDate[datekey].map((reservation) => {
                                                return <FacilityCustomerReservationSearchRow key={reservation.reservationId} reservation={reservation} view={viewTheReservation}></FacilityCustomerReservationSearchRow>
                                            }
                                            )}
                                    </Fragment>
                                </div>
                            </div>
                        }
                        )
                    }
                    {!isLoading && Object.keys(reservationsByDate).length === 0 &&
                        <span className='no-reservations-found'>No reservations found</span>
                    }
                </div>
            </div>

        </FacilityCustomerReservationSearchContainer >
    )
}

export default FacilityCustomerReservationSearch