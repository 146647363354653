import { Box, Button, Typography, Stack, Divider, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import dayjs from "dayjs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fragment, useContext, useState, useEffect } from "react";
import mssLogo from "../../../assets/logo-01.png";
import { PhoneFormat } from "../../../utils/text-field-utils/text-field-utils";
import CustomerFormOfPayments from "../customer-form-of-payments/customer-form-of-payments.component";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceAlert from "../../alert/alert.component";
import { SignupForEvent } from "../../../utils/facility-events/facility-events-service";
import { useNavigate } from "react-router-dom";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import appDownload from '../../../assets/apple-download-on-app-store.webp';
import androidDownload from '../../../assets/android-download-on-app-store.webp';


const defaultFormFields = {
    paymentMethodId: null,
    playerOrTeamName: '',
    coachFirstName: '',
    coachLastName: '',
    coachPhoneNumber: '',
    coachEmailAddress: ''
}

const mainImageStyle = {
    height: '30vh',
    width: '30vw',
    objectFit: 'contain',
    marginTop: '2.0vh'
};

const FacilityEventSignup = ({ facilityEvent = {}, close }) => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const [additionalInfoAnswers, setAddtitionalInfoAnswers] = useState({});
    const [showSignupConfirmation, setShowupConfirmation] = useState(false);
    const [signUpReponse, setSignupResponse] = useState(false);
    const [signInAlertMessage, setSignInAlertMessage] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const { userData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [formOfPaymentsAreLoading, setFormOfPaymentsAreLoaded] = useState(false);

    const navigate = useNavigate();

    const loadingFormOfPayments = () => {
        setIsLoading(true);
    }

    // useEffect(() => {
    //     loadingFormOfPayments();
    // }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // if (userData){
        //     setIsLoading(true);
        // }

        setShowupConfirmation(true);
    }

    const cancel = (event) => {
        event.preventDefault();

        if (close) {
            close();
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };

    const handleAdditionalInfo = (event) => {
        const { name, value } = event.target;
        let newAdditionalInfo = Object.assign({}, additionalInfoAnswers);
        newAdditionalInfo[name] = value;
        setAddtitionalInfoAnswers(newAdditionalInfo);
    }

    const closeConfirmation = () => {
        setShowupConfirmation(false);
    }

    const signUpForEvent = async () => {
        if (!userData) {
            setSignInAlertMessage({ message: `You must be signed in to sign up for a ${eventType}.` });
            return;
        } else {

            if (!formFields.paymentMethodId) {
                setAlertMessage({
                    title: 'Form Of Payment Required',
                    message: 'You must select a form of payment to sign up.'
                });
            } else {
                setIsLoading(true);
                const response = await SignupForEvent(facilityEvent, userData.userId, formFields, additionalInfoAnswers);
                if (response && response.data && response.data.status === 'success') {
                    setShowupConfirmation(false);
                    setSignupResponse(response.data);
                } else {
                    setAlertMessage({
                        title: 'Sign Up Error',
                        message: response.data.message ? response.data.message : 'Your sign up was not successful.  Please try again.'
                    });
                }
                setIsLoading(false);
            }
        }
    }

    const closeSignInAlert = () => {
        setSignInAlertMessage(null);
    }

    const formOfPaymentSelected = (formOfPaymentIn) => {
        if (formOfPaymentIn) {
            let formOfPaymentId = formOfPaymentIn.id ? formOfPaymentIn.id : formOfPaymentIn;
            setFormFields({ ...formFields, ['paymentMethodId']: formOfPaymentId });
            // }
        } else {
            setFormFields({ ...formFields, ['paymentMethodId']: null });
        }
    }

    const signIn = () => {

        const eventTypeValue = facilityEvent.eventType.toUpperCase();
        let eventTypePath = '';
        switch (eventTypeValue) {
            case 'LEAGUE':
                eventTypePath = 'leagues';
                break;
            case 'TOURNAMENT':
                eventTypePath = 'tournaments';
                break;
            case 'CAMP':
                eventTypePath = 'camps';
                break;
            default:
                eventTypePath = 'events';
        }

        navigate(`/auth?redirectUrl=/${eventTypePath}/${facilityEvent.id}`);
    }

    const closeAlertMessage = () => {
        setAlertMessage(null);
    }

    const exit = () => {
        setSignupResponse(null);
        setShowupConfirmation(false);
        if (close) {
            close();
        }
    }

    const formOfPaymnentsStartedLoading = () => {
        setFormOfPaymentsAreLoaded(true);
    }

    const formOfPaymentsFinishedLoading = () => {
        setFormOfPaymentsAreLoaded(false);
    }

    const { playerOrTeamName, coachFirstName, coachLastName, coachEmailAddress, coachPhoneNumber } = formFields;

    const { name = "Event", eventType, masterEventId, eventStartsDate, eventEndsDate, signupAsType, price, additionalInfo, imageUrl, applicationFee, totalAmount, discountedPrice } = facilityEvent ? facilityEvent : {};
    const eventStartDate = eventStartsDate ? dayjs(eventStartsDate, 'YYYY-MM-DD').format('dddd, MMM YYYY').toUpperCase() : null;
    const eventEndDate = eventEndsDate ? dayjs(eventEndsDate, 'YYYY-MM-DD').format('dddd, MMM YYYY').toUpperCase() : null;
    const eventDate = eventStartDate && eventEndDate ? `${eventStartDate} - ${eventEndDate}` : eventStartDate ? eventStartDate : eventEndDate ? eventEndDate : '';
    return (
        <Box height={'88vh'} display={'flex'} justifyContent={'center'}>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert key={'facility-event-sign-in-alert'} isOpen={signInAlertMessage ? true : false} message={signInAlertMessage ? signInAlertMessage.message : ""} okButtonText={'Sign In'} okAction={signIn} cancelAction={closeSignInAlert} cancelButtonText={"Cancel"} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            <Dialog
                open={showSignupConfirmation && !isLoading}
                onClose={closeConfirmation}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        signUpForEvent();
                    },
                }}
            >
                <DialogTitle fontWeight={'bold'}>Confirm And Pay</DialogTitle>
                <DialogContent>
                    <Divider sx={{ marginBottom: '1.0vh' }}></Divider>
                    <DialogContentText>
                        <Stack spacing={2} display={'flex'} justifyContent={'flex-start'}>
                            <Stack spacing={1}>
                                <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div" >
                                    {name}
                                </Typography>
                                {
                                    masterEventId &&
                                    <Typography width={'80vw'} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                        {masterEventId}
                                    </Typography>
                                }
                                <Typography width={'80vw'} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                    {eventDate}
                                </Typography>
                            </Stack>
                            <Divider></Divider>
                            <Stack ml={2} spacing={2} display={'flex-end'} width={'90%'}>
                                <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                    <Typography ml={15} width={'80vw'} textAlign={'left'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        {eventType} Registration
                                    </Typography>
                                    {
                                        !discountedPrice &&
                                        <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                            ${parseFloat(price).toFixed(2)}
                                        </Typography>
                                    }
                                    {
                                        discountedPrice &&
                                        <Stack spacing={1} direction={'row'}>
                                            <Typography sx={{ textDecoration: 'line-through' }} textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                ${parseFloat(price).toFixed(2)}
                                            </Typography>
                                            <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                ${parseFloat(discountedPrice).toFixed(2)}
                                            </Typography>
                                        </Stack>
                                    }

                                </Stack>
                                <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                    <Typography ml={15} width={'80vw'} textAlign={'left'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        Service Fee
                                    </Typography>
                                    <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        ${applicationFee}
                                    </Typography>
                                </Stack>
                                <Box display={'flex'} justifyContent={'flex-end'}>
                                    <Divider sx={{ width: '50%' }}></Divider>
                                </Box>

                                <Stack spacing={2} direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                    <Typography ml={15} width={'80vw'} textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        Total
                                    </Typography>
                                    <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        ${totalAmount}
                                    </Typography>
                                </Stack>
                            </Stack>

                        </Stack>
                    </DialogContentText>
                    {
                        userData &&
                        <Box sx={{ maxHeight: '25vh', marginTop: '1.0vh' }}>
                            <Divider></Divider>
                            <Box sx={{ marginLeft: '1.0vw', marginRight: '1.0vw', }}>
                                <CustomerFormOfPayments selectFormOfPayment={formOfPaymentSelected} showLoadingIndicator={true}></CustomerFormOfPayments>
                            </Box>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmation}>Cancel</Button>
                    <Button variant="contained" type="submit">Confirm</Button>
                </DialogActions>
            </Dialog>
            {
                signUpReponse && !isLoading &&
                <Dialog
                    open={true}
                    onClose={exit}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            exit();
                        },
                    }}
                >
                    <DialogTitle fontWeight={'bold'}>{`${eventType} Confirmation`}</DialogTitle>
                    <DialogContent>
                        <Divider sx={{ marginBottom: '1.0vh' }}></Divider>
                        <DialogContentText>
                            <Stack spacing={2} display={'flex'} justifyContent={'flex-start'} width={'90%'}>
                                <Stack spacing={1}>
                                    <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div" >
                                        {signUpReponse.signupData.name}
                                    </Typography>
                                    {
                                        signUpReponse.signupData.masterEventId &&
                                        <Typography width={'80vw'} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                            {signUpReponse.signupData.masterEventId}
                                        </Typography>
                                    }
                                    <Typography width={'80vw'} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                        {signUpReponse.signupData.eventDate}
                                    </Typography>
                                </Stack>
                                <Divider></Divider>
                                <Stack ml={2} spacing={2} display={'flex-end'}>
                                    {
                                        signUpReponse.paymentDetails && signUpReponse.paymentDetails.card &&
                                        <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                            <Typography ml={15} textAlign={'left'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                Form of Payment
                                            </Typography>
                                            <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                {`${signUpReponse.paymentDetails.card.brand.toUpperCase()} *${signUpReponse.paymentDetails.card.last4.toUpperCase()}`}
                                            </Typography>
                                        </Stack>
                                    }
                                    <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                        <Typography ml={15} width={'80vw'} textAlign={'left'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                            {eventType} Registration
                                        </Typography>
                                        {
                                            !signUpReponse.signupData.discountedPrice &&
                                            <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                ${parseFloat(price).toFixed(2)}
                                            </Typography>
                                        }
                                        {
                                            signUpReponse.signupData.discountedPrice &&
                                            <Stack spacing={1} direction={'row'}>
                                                <Typography sx={{ textDecoration: 'line-through' }} textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                    ${parseFloat(signUpReponse.signupData.price).toFixed(2)}
                                                </Typography>
                                                <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                                    ${parseFloat(signUpReponse.signupData.discountedPrice).toFixed(2)}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                    <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                        <Typography ml={15} width={'80vw'} textAlign={'left'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                            Service Fee
                                        </Typography>
                                        <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                            ${signUpReponse.signupData.applicationFee}
                                        </Typography>
                                    </Stack>
                                    <Box display={'flex'} justifyContent={'flex-end'}>
                                        <Divider sx={{ width: '50%' }}></Divider>
                                    </Box>

                                    <Stack spacing={2} direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                        <Typography ml={15} width={'80vw'} textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                            Total
                                        </Typography>
                                        <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                            ${signUpReponse.signupData.totalAmount}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </DialogContentText>
                        {
                            signUpReponse.signupData.specialInstructions &&
                            <Box sx={{ marginTop: '1.0vh', marginBottom: '1.0vh', marginLeft: '1.0vw', marginRight: '1.0vw' }}>
                                <Divider sx={{ width: '95%' }}></Divider>
                                <Typography mt={2} width={'90%'} textAlign={'left'} color={"#14254C"} component="div" variant="subtitle1">
                                    {signUpReponse.signupData.specialInstructions}
                                </Typography>
                            </Box>
                        }
                        <Box sx={{ marginTop: '1.0vh', marginLeft: '1.0vw', marginRight: '1.0vw' }}>
                            <Divider sx={{ width: '95%' }}></Divider>
                            <Typography mt={2} width={'90%'} textAlign={'center'} color={"#14254C"} component="div" variant="subtitle1">
                                Thanks for using My Sport Space to sign up for your event.  To manage your registration please download the My Sport Space App.
                            </Typography>
                        </Box>
                        <Box mt={1} display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                            <Stack>
                                <Box display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                                    <Typography gutterBottom variant="subtitle1" color={'#14254C'} fontWeight={'bold'} fontFamily={"Helvetica"}>Available on iOS and Android</Typography>
                                </Box>
                                <Stack spacing={2} direction={'row'}>
                                    <img style={{ height: '4vh' }} src={appDownload} alt="mysportspace-logo" className={`logo-image`}></img>
                                    <img style={{ height: '4vh' }} src={androidDownload} alt="mysportspace-logo" className={`logo-image`}></img>
                                </Stack>
                            </Stack>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={exit}>Close</Button>
                    </DialogActions>

                </Dialog>
            }
            {
                facilityEvent &&
                <Stack width={'95%'} spacing={3} mt={2} mb={2} ml={3}>
                    <Stack ml={1} mr={1} mt={2} width={'98%'} spacing={2}>
                        <Box display={'flex'} justifyContent={'flex-start'} alignContent={'flex-start'} alignItems={'flex-start'}>
                            <Button onClick={cancel} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        </Box>
                        <Typography sx={{ marginLeft: '5.0vw' }} noWrap textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div">
                            Confirm {eventType} Sign Up
                        </Typography>
                        <Divider></Divider>
                    </Stack>

                    <Stack display={'flex'} justifyContent={'center'} direction={'row'}>
                        <Stack ml={5} spacing={1} display={'flex'} justifyContent={'flex-start'} width={'50%'}>
                            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" >
                                {name}
                            </Typography>
                            {
                                masterEventId &&
                                <Typography textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                    {masterEventId}
                                </Typography>
                            }
                            <Typography textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                {eventDate}
                            </Typography>
                            {
                                !discountedPrice &&
                                <Typography textAlign={'left'} fontWeight={'bold'} color={"gray"} component="div" variant="subtitle1">
                                    ${price}
                                </Typography>
                            }
                            {
                                discountedPrice &&
                                <Stack spacing={1} direction={'row'}>
                                    <Typography sx={{ textDecoration: 'line-through' }} textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        ${price}
                                    </Typography>
                                    <Typography textAlign={'right'} fontWeight={'bold'} color={"#14254C"} component="div" variant="subtitle1">
                                        ${discountedPrice}
                                    </Typography>
                                </Stack>
                            }
                            {
                                imageUrl &&
                                <Box width={'80%'} display={'flex'} justifyContent={'center'}>
                                    <img style={mainImageStyle} className="facility-logo-image" src={imageUrl ? imageUrl : mssLogo} alt="Logo" loading="lazy" />
                                </Box>
                            }
                        </Stack>
                        <Stack display={'flex'} justifyContent={'center'} width={'50%'} mb={5}>
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={2}>
                                    <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="participant-name" variant="outlined" label={signupAsType === 'Individual' ? "Participant Name" : 'Team Name'} type="text" name="playerOrTeamName" value={playerOrTeamName} onChange={handleChange} ></TextField>
                                    {
                                        signupAsType === 'Team' &&
                                        <Fragment>
                                            <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="participant-name" variant="outlined" label="Coach First Name" type="text" name="coachFirstName" value={coachFirstName} onChange={handleChange} ></TextField>
                                            <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="coach-first-name" variant="outlined" label="Coach Last Name" type="text" name="coachLastName" value={coachLastName} onChange={handleChange} ></TextField>
                                            <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="coach-email" variant="outlined" label="Coach Email Address" type="email" name="coachEmailAddress" value={coachEmailAddress} onChange={handleChange} ></TextField>
                                            <TextField InputProps={{
                                                inputComponent: PhoneFormat
                                            }} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="coach-phone-number" variant="outlined" label="Coach Phone Number" type="text" name="coachPhoneNumber" value={coachPhoneNumber} onChange={handleChange} ></TextField>
                                        </Fragment>
                                    }
                                    {
                                        additionalInfo && additionalInfo.length > 0 &&
                                        additionalInfo.map((info) => {
                                            return <Stack key={info}>
                                                <Typography width={'80vw'} textAlign={'left'} color={"#14254C"} component="div" variant="subtitle1">
                                                    {info}*
                                                </Typography>
                                                <TextField required multiline id={`${info}`} type="text" onChange={handleAdditionalInfo} name={info} />
                                            </Stack>
                                        })
                                    }
                                    <Stack spacing={2} direction={'row'} display={'flex'} justifyContent={'center'}>
                                        <Button onClick={cancel}>Cancel</Button>
                                        <Button type="submit" variant="contained">Sign Up</Button>
                                    </Stack>
                                </Stack>
                            </form>
                        </Stack>
                    </Stack>
                </Stack>
            }
        </Box>
    )
}

export default FacilityEventSignup;