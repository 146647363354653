import baseApiService from "../base-api/base-api-service";
import { getReservationDatesList } from "../../utils/date-utilities/date-utilities.js";
import { STATE_FULL_NAME_TO_STATE_ABB } from "../constants/constants.js";
import dayjs from "dayjs";


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const GetFacilitiesByName = async (startAfter, filterType, searchFilter) => {
   try {
      let url = `facility/facilities-by-name`;

      if (startAfter) {
         url = `${url}?startAfter=${startAfter}`;
      }
      if (filterType) {
         url = `${url}${url.includes('?') ? '&' : '?'}filterType=${filterType}`;
      }

      if (searchFilter) {
         const { searchLocation, selectedOfferings, sportsSelected } = searchFilter;
         if (searchLocation) {
            url = `${url}${url.includes('?') ? '&' : '?'}searchLocation=${searchLocation.label.replace(/\s+/g, '')}`;
         }
         if (selectedOfferings) {
            const searchFacilityEvents = [];
            let memberships = null;
            let birthdayParties = null;
            for (const offering of selectedOfferings) {
               switch (offering) {
                  case 'Leagues':
                     searchFacilityEvents.push("League");
                     break;
                  case 'Tournaments':
                     searchFacilityEvents.push("Tournament");
                     break;
                  case 'Camps':
                     searchFacilityEvents.push("Camp");
                     break;
                  case 'Memberships':
                     memberships = "true";
                     break;
                  case 'Birthday Parties':
                     birthdayParties = "true";
                     break;
                  default:
                     console.log("Bad Offering");
               }
            }
            if (searchFacilityEvents.length > 0) {
               url = `${url}${url.includes('?') ? '&' : '?'}searchFacilityEvents=${searchFacilityEvents.join(',')}`;
            }
            if (sportsSelected.length > 0) {
               url = `${url}${url.includes('?') ? '&' : '?'}searchSports=${sportsSelected.join(',')}`;
            }
            if (memberships){
               url = `${url}${url.includes('?') ? '&' : '?'}membershipsOnly=true`;
            }
            if (birthdayParties){
               url = `${url}${url.includes('?') ? '&' : '?'}birthdayParties=true`;
            }
         }
      }
      //console.log(url);
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetFacilityTourInfo = async (facilityId) => {
   try {
      let url = `facility/facility-tour-info?facilityId=${facilityId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetFacilityAreas = async (facilityId) => {
   try {
      let url = `facility/facility-areas?facilityId=${facilityId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetCalendarSettings = async (facilityId) => {
   try {
      let url = `facility/calendar-setttings?facilityId=${facilityId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetReservationSettings = async (facilityId) => {
   try {
      let url = `facility/facility-reservation-settings?facilityId=${facilityId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetFacilityAreaReservations = async (facilityId, mainAreaId, reservationDate) => {
   try {
      let url = `facility/reservations-for-facility-area?facilityId=${facilityId}&mainAreaId=${mainAreaId}&reservationDate=${moment(reservationDate).format("YYYY-MM-DD")}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const findFacilityBySearchTerm = async (searchTerm) => {
   try {
      let url = `facility/facility-for-search-term?searchTerm=${searchTerm}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const getInfoForCalendar = async (facilityId, calendarDate) => {
   try {
      let url = `facility/info-for-calendar?facilityId=${facilityId}&calendarDate=${dayjs(calendarDate).format("YYYY-MM-DD")}`;
      const response = await baseApiService.get(url);
      return response;
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const getFacilityCustomersByLastName = async (facilityId, startsAt, endsAt) => {
   try {
      let url = `facility/customers-by-last-name?facilityId=${facilityId}`;
      if (startsAt) {
         url = `${url}&startsAt=${startsAt}`;
      }

      if (endsAt) {
         url = `${url}&endsAt=${endsAt}`;
      }

      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
};

export const getFacilityCustomers = async (facilityId, prevLastName, endAtLastName) => {
   try {
      let url = `facility/get-facility-customers`;
      const GetCustomersRequest = {
         facilityId: facilityId,
         prevLastName: prevLastName,
         endAtLastName: endAtLastName
      };
      return await baseApiService.post(url, GetCustomersRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const UpdateUserEmailAddress = async (userId, emailAddress) => {
   try {
      let url = `user/update-firebase-user-email`;
      const UpdateUserRequest = {
         userId: userId,
         emailAddress: emailAddress
      };
      return await baseApiService.post(url, UpdateUserRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const UpdateAllFacilityStripeCustomers = async (facilityId, userId, userEmailAddress, userPhoneNumber, userFirstName, userLastName, contactInfo) => {
   try {
      let url = `facility/update-all-facility-customers`;
      const UpdateAllFacilityUsersRequest = {
         userId: userId,
         userEmailAddress: userEmailAddress,
         userPhoneNumber: userPhoneNumber,
         userFirstName: userFirstName,
         userLastName: userLastName,
         contactInfo: contactInfo,
         exlcudedFacilityId: facilityId
      };

      return await baseApiService.post(url, UpdateAllFacilityUsersRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
};


export const UpdateFacilityStripeCustomer = async (facilityId, userId, email, phone, customerName, contactInfo) => {
   try {
      let url = `stripe/update-facility-customer`;
      const UpdateUserRequest = {
         userId: userId,
         facilityId: facilityId,
         email: email,
         phone: phone,
         customerName: customerName,
         contactInfo: contactInfo
      };
      return await baseApiService.post(url, UpdateUserRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const getCustomerReservationsCatalog = async (facilityId, customerId, startDate, endDate, sortType = 'asc', failedOnly, canceledOnly, filterCanceled, paidStatus) => {
   try {
      let url = `reservations/customer-reservations-catalog?customerId=${customerId}&facilityId=${facilityId}`;

      if (startDate) {
         url = `${url}&startDate=${startDate}`;
      }

      if (endDate) {
         url = `${url}&endDate=${endDate}`;
      }
      if (sortType) {
         url = `${url}&sortType=${sortType}`;
      } else {
         url = `${url}&sortType=asc`;
      }

      if (failedOnly) {
         url = `${url}&failedOnly=${failedOnly}`;
      }

      if (canceledOnly) {
         url = `${url}&canceledOnly=${canceledOnly}`;
      }

      if (filterCanceled) {
         url = `${url}&filterCanceled=${filterCanceled}`;
      }

      if (paidStatus) {
         url = `${url}&paidStatus=${paidStatus}`;
      }

      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const getReservationListForCustomer = async (customerId, facilityId, startDate, endDate, sortyType, failedOnly, canceledOnly, filterCanceled, paidStatus) => {
   try {
      let url = `reservations/customer-reservation-list?customerId=${customerId}&facilityId=${facilityId}`;

      if (startDate) {
         url = `${url}&startDate=${startDate}`;
      }

      if (endDate) {
         url = `${url}&endDate=${endDate}`;
      }
      if (sortyType) {
         url = `${url}&sortType=${sortyType}`;
      }

      if (failedOnly) {
         url = `${url}&failedOnly=${failedOnly}`;
      }

      if (canceledOnly) {
         url = `${url}&canceledOnly=${canceledOnly}`;
      }

      if (filterCanceled) {
         url = `${url}&filterCanceled=${filterCanceled}`;
      }

      if (paidStatus) {
         url = `${url}&paidStatus=${paidStatus}`;
      }

      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const getFacilityInvoiceCustomers = async (facilityId) => {
   try {
      let url = `facility/get-facility-customers`;
      const GetCustomersRequest = {
         facilityId: facilityId,
         invoiceCustomersOnly: true
      };
      return await baseApiService.post(url, GetCustomersRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const makeFailedReservationPayment = async (facilityId, reservation, paymentMethodId, customerPaidFacility) => {
   try {
      let url = `reservations/make-failed-payment`;
      const FailedPaymentRequest = {
         reservationId: reservation.reservationId,
         facilityId: facilityId,
         reservationDate: reservation.dateKey,
         customerId: reservation.customerId,
         paymentMethodId: paymentMethodId,
         customerPaidFacility: customerPaidFacility
      };
      return await baseApiService.post(url, FailedPaymentRequest);

   } catch (err) {
      console.log(err);
      return err;
   }
}

export const adjustReservationAmount = async (reservation, adjustmentAmt, adjustmentType, paymentMethodId) => {

   const amountInCents = parseFloat(adjustmentAmt) * 100.00;

   try {
      let url = `bookings/reservation-adjustment`;
      const ResAdjustmentRequest = {
         reservationId: reservation.reservationId,
         facilityId: reservation.facilityId,
         reservationStartDate: reservation.dateKey,
         customerId: reservation.customerId,
         paymentMethodId: paymentMethodId,
         refundAmount: adjustmentType === 'refund' ? amountInCents : null,
         chargeAmount: adjustmentType === 'charge' ? amountInCents : null,
      };
      return await baseApiService.post(url, ResAdjustmentRequest);

   } catch (err) {
      console.log(err);
      return err;
   }
}

export const updateReservationFormOfPayment = async (reservation, updatedFormOfPaymentId, updateFutureReses) => {
   try {
      let url = `reservations/update-form-of-payment`;
      const UpdateFormOfPaymentRequest = {
         reservationId: reservation.reservationId,
         facilityId: reservation.facilityId,
         reservationDate: reservation.dateKey,
         customerId: reservation.customerId,
         updatedFormOfPaymentId: updatedFormOfPaymentId ? updatedFormOfPaymentId : 'Cash/Check',
         updateFutureReses: updateFutureReses
      };
      return await baseApiService.post(url, UpdateFormOfPaymentRequest);

   } catch (err) {
      console.log(err);
      return err;
   }
}

export const getFacilityCustomerPaymentMethods = async (facilityStripeId, stripeCustomerId, customerId) => {
   try {
      let url = `stripe/get-customer-payment-methods`;
      const PaymentMethodsRequest = {
         facilityStripeId: facilityStripeId,
         stripeCustomerId: stripeCustomerId,
         customerId: customerId
      };
      console.log(PaymentMethodsRequest)
      return await baseApiService.post(url, PaymentMethodsRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const updateCustomerInvoiceStatus = async (facilityId, customerId, allowInvoicing, billingCustomerId, isWeekly) => {
   try {
      let url = `user/update-invoicing-customer-status`;
      const CustomerInvoiceUpdateRequest = {
         customerId: customerId,
         facilityId: facilityId,
         allowInvoicing: allowInvoicing ? allowInvoicing : false,
         isWeekly: isWeekly ? true : false,
         billingCustomerId: billingCustomerId
      };
      return await baseApiService.post(url, CustomerInvoiceUpdateRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const sendCustomerInvoiceInvoiceEmail = async (emailAddress, facilityId, firstName, lastName) => {
   try {
      let url = `facility/send-new-invoice-customer-email`;
      const CustomerInvoiceEmailRequest = {
         emailAddress: emailAddress,
         facilityId: facilityId,
         firstName: firstName,
         lastName: lastName
      };
      return await baseApiService.post(url, CustomerInvoiceEmailRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const getFacilityMemberships = async (facilityId) => {
   try {
      const url = `memberships/facility-memberships?facilityId=${facilityId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const getFacilityCustomer = async (facilityId, customerId) => {
   try {
      const url = `facility/facility-customer?facilityId=${facilityId}&customerId=${customerId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const searchFacilityCustomers = async (facilityId, searchText, invoiceCustomersOnly) => {
   try {
      let url = `facility/search-facility-customers`;
      const SearchCustomersRequest = {
         facilityId: facilityId,
         searchText: searchText,
         invoiceCustomersOnly: invoiceCustomersOnly
      };
      return await baseApiService.post(url, SearchCustomersRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const bookFacilityReservation = async (userId, facilityCustomer, reservationsToBook, reservationBookingInput, isInternalBooking, internalBookingid, onlyBookIfAllPass = true) => {

   const bookTheseReservationIds = [];
   for (const resToBook of reservationsToBook) {
      bookTheseReservationIds.push(resToBook.reservationId);
   }

   const ReservationBookingRequest = {
      reservationIds: bookTheseReservationIds,
      isPayingWithCredit: reservationBookingInput.formOfPaymentType === 'Credit Card' ? true : false,
      paymentMethodId: reservationBookingInput.formOfPayment && reservationBookingInput.formOfPayment.id ? reservationBookingInput.formOfPayment.id : reservationBookingInput.formOfPayment ? reservationBookingInput.formOfPayment : null,
      isPayingByInvoice: reservationBookingInput.formOfPaymentType === 'Invoice' ? true : false,
      customerId: isInternalBooking && internalBookingid ? internalBookingid : facilityCustomer.userId,
      onlyBookIfAllPass: onlyBookIfAllPass,
      title: reservationBookingInput.title,
      notes: reservationBookingInput.notes,
      isInternalBooking: isInternalBooking,
      isIntegrationTest: false,
      bookedById: userId
   }

   try {
      let url = `bookings/reservation-book`;
      return await baseApiService.post(url, ReservationBookingRequest);
   } catch (err) {
      console.log(err);
      return err;
   }

}

export const getFacilityCancelPolicy = async (reservation, overridePolicyAndCancel, chargeFacilityToCancel) => {
   const CancelPolicyRequest = {
      facilityId: reservation.facilityId,
      reservationDate: reservation.dateKey,
      reservationId: reservation.reservationId,
      customerId: reservation.customerId,
      policyInfoOnly: true,
      isFacilityCancel: true,
      overridePolicyAndCancel: overridePolicyAndCancel,
      chargeFacilityToCancel: chargeFacilityToCancel
   }

   try {
      let url = `reservations/reservations-cancel`;
      return await baseApiService.post(url, CancelPolicyRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const removeBlackout = async (reservation) => {
   const RemoveBlackoutRequest = {
      facilityId: reservation.facilityId,
      blackoutDate: reservation.dateKey,
      blackoutId: reservation.reservationId,
   }

   try {
      let url = `reservations/blackout`;
      return await baseApiService.delete(url, { data: RemoveBlackoutRequest });
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const cancelFacilityReservation = async (reservation, overridePolicyAndCancel, chargeFacilityToCancel, paymentMethodId) => {
   const CancelRequest = {
      facilityId: reservation.facilityId,
      reservationDate: reservation.dateKey,
      reservationId: reservation.reservationId,
      customerId: reservation.customerId,
      policyInfoOnly: false,
      isFacilityCancel: true,
      overridePolicyAndCancel: overridePolicyAndCancel,
      chargeFacilityToCancel: chargeFacilityToCancel
   }

   if (paymentMethodId) {
      CancelRequest.paymentMethodId = paymentMethodId;
   }

   try {
      let url = `reservations/reservations-cancel`;
      return await baseApiService.post(url, CancelRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const retrieveCustomerReservations = async (facilityId, customerId, startDate, endDate, failedOnly, filterCanceled = false, canceledOnly = false) => {
   let ReservationsRequest = {
      facilityId: facilityId,
      customerId: customerId,
      returnFailedOnly: failedOnly,
      returnBasicInfo: true,
      filterCanceled: filterCanceled,
      canceledOnly: canceledOnly
   }

   if (startDate) {
      ReservationsRequest.startDate = startDate;
   }

   if (endDate) {
      ReservationsRequest.endDate = endDate;
   }

   try {
      let url = `reservations/reservations_v2`;
      return await baseApiService.post(url, ReservationsRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const buildReservationSearchRequest = (reservationSearchData, sportToSubAreas, facilityId, internalBookingId, isFacilitySearch = false, latitude = 0.0, longitude = 0.0, isExactSearch = false) => {

   const { customer, selectedSport, selectedSubType, facilityArea, selectedAreas, searchAreaTypeOverride, searchAreaSubTypeOverride, ignoreTheseAreas } = reservationSearchData;
   const isInternalBooking = customer && customer.userFirstName === 'Internal' && customer.userLastName === 'Booking' ? true : false

   let searchAreaType = 'all';
   let searchAreaSubType = 5;
   if (selectedSubType && selectedSubType !== '') {
      let sportToAreaSubType = sportToSubAreas[selectedSport];
      if (!sportToAreaSubType) {
         switch (selectedSubType) {
            case '':
               searchAreaType = 'all';
               break;
            case 'Half Area':
               searchAreaType = 'sub';
               break;
            case 'Sub Area':
               searchAreaType = 'sub';
               break;
            case 'Full Area':
               searchAreaType = 'main';
               break;
            default:
               searchAreaType = 'all';
         }
      }
      else if (sportToAreaSubType && !sportToAreaSubType.subTypes) {
         searchAreaType = sportToAreaSubType[selectedSubType];
      } else if (sportToAreaSubType && sportToAreaSubType.subTypes) {
         searchAreaSubType = sportToAreaSubType.subTypes[selectedSubType];
      }
   }

   let specificAreaIds = null;
   if (reservationSearchData.specificAreaIdsOverride) {
      specificAreaIds = reservationSearchData.specificAreaIdsOverride;
   } else if (facilityArea) {
      const { id } = facilityArea;
      specificAreaIds = [id];
   } else if (selectedAreas && selectedAreas.length > 0) {
      const areas = [];
      for (const sArea of selectedAreas) {
         areas.push(sArea.id)
      }
      specificAreaIds = areas;
   }

   let reservationDates = getReservationDatesList(reservationSearchData.searchDate, reservationSearchData.repeatsUntilDate, reservationSearchData.daysOfWeek, reservationSearchData.exceptionDates, false, 'YYYY-MM-DD');
   let reservationLength = reservationSearchData.reservationLength;
   let searchResLengthInHours = 0;
   let searchResLengthInMinutes = 0;
   let decimalIndex = reservationLength.toString().indexOf(":");
   if (decimalIndex < 0) {
      decimalIndex = reservationLength.toString().indexOf(".");
   }
   if (decimalIndex >= 0) {
      searchResLengthInHours = parseInt(reservationLength.toString().substring(0, decimalIndex));
      searchResLengthInMinutes = parseInt(reservationLength.toString().substring(decimalIndex + 1));

      if (searchResLengthInMinutes === 25) {
         searchResLengthInMinutes = 15;
      } else if (searchResLengthInMinutes === 5) {
         searchResLengthInMinutes = 30;
      } else if (searchResLengthInMinutes === 75) {
         searchResLengthInMinutes = 45;
      }

   } else {
      searchResLengthInHours = parseInt(reservationLength);
   }

   const ReservationSearchRequest = {
      searchByCityState: !facilityId && reservationSearchData.selectedCity && reservationSearchData.selectedState ? true : false,
      searchStateAbb: !facilityId && reservationSearchData.selectedState ? STATE_FULL_NAME_TO_STATE_ABB[reservationSearchData.selectedState] : '',
      searchStateFull: !facilityId && reservationSearchData.selectedState ? reservationSearchData.selectedState : '',
      searchCity: !facilityId && reservationSearchData.selectedCity ? reservationSearchData.selectedCity : '',
      searchByDistance: !facilityId && reservationSearchData.selectedMiles && latitude && longitude ? true : false,
      searchLatitude: latitude ? latitude : 0.0,
      searchLongitude: longitude ? longitude : 0.0,
      searchRadiusInMiles: reservationSearchData.selectedMiles,
      preferredFacilityIds: facilityId ? [facilityId] : null,
      customerId: isInternalBooking ? internalBookingId : customer ? customer.userId : ' ',
      searchAreaType: searchAreaTypeOverride ? searchAreaTypeOverride : searchAreaType,
      searchAreaSubType: searchAreaSubTypeOverride ? searchAreaSubTypeOverride : searchAreaSubType,
      searchSport: selectedSport,
      searchDates: reservationDates,
      searchStartTime: reservationSearchData.startTime.format("h:mm A"),
      searchEndTime: isExactSearch ? reservationSearchData.startTime.format("h:mm A") : reservationSearchData.endTime.format("h:mm A"),
      searchResLengthInHours: searchResLengthInHours,
      searchResLengthInMinutes: searchResLengthInMinutes,
      specificAreaIds: specificAreaIds,
      isFacilitySearch: isFacilitySearch,
      isClickToBook: reservationSearchData.isClickToBook ? reservationSearchData.isClickToBook : false,
      reschedulingResId: reservationSearchData.reschedulingResId ? reservationSearchData.reschedulingResId : null,
      overrideHoursOfOperation: false,
      numberOfAreas: parseInt(reservationSearchData.numberOfSpaces),
      isInternalBooking: isInternalBooking,
      reservedForOverride: false,
      isGuestSearch: isInternalBooking ? false : customer ? false : true,
      timeSlotId: reservationSearchData.timeSlotId,
      showAllResults: selectedAreas && selectedAreas.length > 0,
      ignoreTheseAreas: ignoreTheseAreas ? ignoreTheseAreas : null
   }

   return ReservationSearchRequest;
}

export const facilityReservationSearch = async (reservationSearchData, sportToSubAreas, facilityId, internalBookingId, isFacilitySearch = false, latitude = 0.0, longitude = 0.0, isExactSearch = false) => {

   const reservationSearchRequest = buildReservationSearchRequest(reservationSearchData, sportToSubAreas, facilityId, internalBookingId, isFacilitySearch, latitude, longitude, isExactSearch);
   try {
      let url = `reservations/reservation-search`;
      return await baseApiService.post(url, reservationSearchRequest);
   } catch (err) {
      console.log(err);
      return err;
   }

}

export const copyAreaPricing = async (facilityId, areasForPricingCopy, areaToCopyPricing) => {

   const CopyAreaPricingRequest = {
      facilityId: facilityId,
      areasForPricingCopy: areasForPricingCopy,
      areaToCopyPricing: areaToCopyPricing
   }
   console.log(CopyAreaPricingRequest);
   try {
      let url = `facility/copy-area-pricing`;
      return await baseApiService.post(url, CopyAreaPricingRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
}
