import { Divider, Typography, Box, Stack, Button, Grid } from "@mui/material";
import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import BirthdayPartyInfoCard from "./birthday-info-card.component";
import { GetBirthdayPartiesForGroup } from "../../../utils/birthday-api/birthday-service";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from "../../../contexts/user.context";

const logoImageStyle = {
    height: '4.0vh',
    width: '4.0vw',
    objectFit: 'contain'
};

const facilityImageStyle = {
    height: '25.0vw',
    width: '25.0vw',
    objectFit: 'contain'
};


const BirthdayPartyGroupList = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { facilityName, groupSearchTerm } = useParams();
    const [birthdayParties, setBirthdayParties] = useState([]);
    const [groupInfo, setGroupInfo] = useState(null);
    const [facilityLogoUrl, setFacilityLogoUrl] = useState("");
    const { userData } = useContext(UserContext);

    const navigate = useNavigate();

    const { mainTextColor = '#14254C', secondaryTextColor = '#A9A9A9' } = groupInfo && (!userData || (userData && !userData.isFacilityUserAccount)) ? groupInfo : {};

    const fetchPartyGroups = async () => {
        setIsLoading(true);
        const groupsResponse = await GetBirthdayPartiesForGroup(facilityName, groupSearchTerm, userData && userData.isFacilityUserAccount);
        if (groupsResponse && groupsResponse.status == 200 && groupsResponse.data && groupsResponse.data.status === 'success') {
            setBirthdayParties(groupsResponse.data.birthdayParties);
            setGroupInfo(groupsResponse.data.groupInfo);
            if (groupsResponse.data.facilityLogoUrl) {
                setFacilityLogoUrl(groupsResponse.data.facilityLogoUrl)
            }
        } else {
            setBirthdayParties([]);
        }
        setIsLoading(false);
    }

    const closeDetails = () => {
        navigate(`/birthday-parties/${facilityName}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPartyGroups();
    }, []);

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'10.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'} justifyContent={'center'}>
            <Stack sx={{
                height: '100%',
                width: '99%',
            }}>
                <Box>
                    <Stack>
                        <Box display="flex" justifyContent="flex-start" alignItems="center">
                            <Button onClick={closeDetails} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        </Box>
                        <Stack marginLeft={'5.0vw'} spacing={5} direction={'row'} display={'flex'} justifyContent={'strech'} mb={2}>
                            <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                {
                                    groupInfo &&
                                    <img style={facilityImageStyle} className="birthday-party-group-logo-image" src={groupInfo.imageLocationURL ? groupInfo.imageLocationURL : "/birthday-party-table-image.webp"} alt="Logo" />
                                }
                                {
                                    !groupInfo &&
                                    <img style={facilityImageStyle} className="birthday-party-group-logo-image" src={"/birthday-party-table-image.webp"} alt="Logo" />
                                }
                            </Box>
                            <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                <Stack>
                                    {!groupInfo && !isLoading &&
                                        <Typography mt={2} fontSize={{ xs: '2.5vh', sm: '2.5vh', md: '3.5vh', lg: '5.0vh', xl: '5.0vh' }} textAlign={'center'} gutterBottom color={mainTextColor ? mainTextColor : "#14254C"} component="div" variant={birthdayParties.length > 0 ? "h2" : "body1"}>
                                            We were unable to find the party package you are looking for
                                        </Typography>
                                    }
                                    {groupInfo && groupInfo.groupName && !isLoading &&
                                        <Typography mt={2} fontSize={{ xs: '2.5vh', sm: '2.5vh', md: '3.5vh', lg: '5.0vh', xl: '5.0vh' }} textAlign={'center'} gutterBottom color={mainTextColor ? mainTextColor : "#14254C"} component="div" variant={birthdayParties.length > 0 ? "h2" : "body1"}>
                                            {groupInfo.groupName}
                                        </Typography>
                                    }
                                    {groupInfo && groupInfo.groupName && !isLoading &&
                                        <Stack mt={5} spacing={1} direction={'row'}>
                                            {
                                                birthdayParties.map((birthdayParty) => {
                                                    return <Stack spacing={2}>
                                                        <Button variant="outlined" sx={{color: `${mainTextColor ? mainTextColor : "#14254C"}`}}>{birthdayParty.name}</Button>
                                                    </Stack>
                                                })
                                            }
                                        </Stack>
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                    <Divider color={`${secondaryTextColor}`} sx={{ marginBottom: '5.0vh', color: `${secondaryTextColor}` }}></Divider>
                    <Stack marginLeft={'5.0vw'} marginRight={'2.0vw'} marginBottom={'2.0vh'} sx={{ marginBottom: '2.0vh' }} spacing={2} justifyContent={'center'} alignItems={'stretch'}>
                        {
                            birthdayParties.map((birthdayParty) => {
                                return <Stack spacing={2}>
                                    <BirthdayPartyInfoCard key={birthdayParty.id} birthdayParty={birthdayParty} facilityName={facilityName}></BirthdayPartyInfoCard>
                                    <Divider></Divider>
                                </Stack>
                            })
                        }
                    </Stack>
                </Box>

            </Stack>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
        </Box >
    )
}

export default BirthdayPartyGroupList;