import { Box, Accordion, AccordionSummary, AccordionDetails, ListItem, ListItemText, ListItemIcon, Checkbox, Stack, Typography, Divider, Button } from "@mui/material";
import { useContext, useState, Fragment } from "react";
import { ApplicationDataContext } from "../../../contexts/application-data-context";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import LocationSearchTextField from "../../location-search-textfield/location-search-textfield.component";
import { APIProvider } from "@vis.gl/react-google-maps";

const offerings = ["Birthday Parties", "Camps", "Leagues", "Memberships", "Tournaments"];

const defaultFilter = {
    sportsSelected: [],
    selectedOfferings: [],
    searchLocation: null
}

const HomePageFacilityFilter = ({ filterType, applySearchFilter, filter }) => {

    const { sportsSupported } = useContext(ApplicationDataContext);
    const [showScrollForMore, setShowScrollForMore] = useState(true);
    const [sportsAreShowing, setSportsAreShowing] = useState(false);
   // const [ filter, setFilter ] = useState(defaultFilter);

    const clearButtonPressed = (e) => {

      //  setFilter(defaultFilter);
        if (applySearchFilter) {
            applySearchFilter(defaultFilter)
        }
    }

    const selectSport = (e) => {
        const sport = e.currentTarget.dataset.id;
        const sportsSelected = filter.sportsSelected;

        let newSports = [];
        if (sportsSelected.includes(sport)) {
            newSports = sportsSelected.filter(item => item !== sport);
        } else {
            newSports = [...sportsSelected, sport];
        }

        const newFilter = { ...filter, ['sportsSelected']: newSports };
       // setFilter(newFilter);

        if (applySearchFilter){
            applySearchFilter(newFilter)
        }
    }

    const selectOffering = (e) => {
        const offering = e.currentTarget.dataset.id;
        const selectedOfferings = filter.selectedOfferings;
        let newOfferings = [];
        if (selectedOfferings.includes(offering)) {
            newOfferings = selectedOfferings.filter(item => item !== offering);
        } else {
            newOfferings = [...selectedOfferings, offering];
        }

        const newFilter = {...filter, ['selectedOfferings']: newOfferings};
      //  setFilter(newFilter);

        if (applySearchFilter){
            applySearchFilter(newFilter)
        }

    }

    const handleScroll = (e) => {
        const top = e.target.scrollTop === 0;
        if (top) {
            setShowScrollForMore(true);
        } else {
            setShowScrollForMore(false);
        }
    }

    const sportsExpanded = (e, expanded) => {
        setSportsAreShowing(expanded)
    }

    const searchLocationSelected = (searchLocation) => {
        const newFilter = {...filter, ['searchLocation']: searchLocation};
      //  setFilter(newFilter);

        if (applySearchFilter){
            applySearchFilter(newFilter)
        }
    }

    const sportFilter = (
        <Stack display={'flex'}>
            <Accordion onChange={sportsExpanded} sx={{ marginTop: '1.0vh', marginBottom: '1.0vh', marginLeft: '1.0vw' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}
                    aria-controls="facility-filter-sports-content"
                    id="facility-filter-sports-header">
                    <Stack spacing={1}>
                        <Typography>Sport</Typography>
                        {
                            sportsAreShowing && showScrollForMore &&
                            <Typography fontSize={{ xs: '2.0vw', sm: '2.0vw', md: '1.0vw', lg: '0.75vw', xl: '0.75vw' }} color={'gray'}>Scroll To See All</Typography>
                        }
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBottom: '1.0vh', maxHeight: "50vh", overflowY: "scroll" }} onScroll={handleScroll}>
                    {
                        sportsSupported.map((sport) => {
                            return <ListItem key={sport} divider={true} onClick={selectSport.bind(this)} data-id={sport} >
                                <ListItemText primary={sport}></ListItemText>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="end"
                                        checked={filter.sportsSelected.includes(sport)}
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{ marginLeft: '1.5vw' }}
                                    />
                                </ListItemIcon>
                            </ListItem>
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </Stack>
    )

    const offeringsFilter = (
        <Stack>
            <Accordion onChange={sportsExpanded} sx={{ marginTop: '1.0vh', marginBottom: '1.0vh', marginLeft: '1.0vw' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}
                    aria-controls="facility-filter-sports-content"
                    id="facility-filter-sports-header">
                    <Typography>Facility Events</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBottom: '1.0vh', maxHeight: "50vh", overflowY: "scroll" }} onScroll={handleScroll}>
                    {
                        offerings.map((offering) => {
                            return <ListItem key={offering} divider={true} onClick={selectOffering.bind(this)} data-id={offering} >
                                <ListItemText primary={offering}></ListItemText>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="end"
                                        checked={filter.selectedOfferings.includes(offering)}
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{ marginLeft: '1.5vw' }}
                                    />
                                </ListItemIcon>
                            </ListItem>
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </Stack>
    )

    return (
        <Stack>
            <Stack ml={1} mb={2} direction={'row'} alignContent={'stretch'} justifyContent={'space-around'} alignItems={'center'}>
                <Stack width={'50%'} spacing={1} direction={'row'} display={'flex'} alignItems={'center'}>
                    <TuneOutlinedIcon></TuneOutlinedIcon>
                    <Typography fontSize={{ xs: '3.0vw', sm: '3.0vw', md: '1.0vw', lg: '1.10vw', xl: '1.10vw' }} >Filter</Typography>
                </Stack>
                <Button onClick={clearButtonPressed} variant="outlined" size="small">Clear</Button>
            </Stack>
            <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} variant="middle"></Divider>
            <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
                <Box sx={{ marginTop: '1.0vh', marginBottom: '1.0vh', marginLeft: '1.0vw', minWidth:{xs: '35vw', sm: '30vw', md: '20vw' }}}>
                    <LocationSearchTextField selectSearchLocation={searchLocationSelected} searchValue={filter.searchLocation ? filter.searchLocation.label : ""}></LocationSearchTextField>
                </Box>
            </APIProvider>
            {
                filterType !== 'birthday-parties' &&
                <Fragment>
                    <Divider sx={{ marginBottom: '1.0vh' }} variant="middle"></Divider>
                    {offeringsFilter}
                    <Divider sx={{ marginBottom: '1.0vh' }} variant="middle"></Divider>
                    {sportFilter}
                </Fragment>
            }
        </Stack>
    )
}

export default HomePageFacilityFilter;