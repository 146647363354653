import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Typography, Button, Divider, Box, Tooltip, IconButton } from '@mui/material';
import BirthdayGroupPartiesTable from './birthday-group-parties-table.component';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../../contexts/user.context";
import { getDatabase, ref, onValue } from "firebase/database";

const BirthdayPartyGroup = ({ group, refresh }) => {

    const { userData } = useContext(UserContext);
    const [groupName, setGroupName] = useState("");
    const [ status, setStatus ] = useState("");

    useEffect(() => {

        const db = getDatabase();
        const groupRef = ref(db, `facility-birthday-party-groups/${userData.facilityId}/${group.id}/groupName`);

        const unsubscribe = onValue(groupRef, (snapshot) => {
            setGroupName(snapshot.val());
        })
        return unsubscribe;

    }, [userData]);

    useEffect(() => {

        const db = getDatabase();
        const groupRef = ref(db, `facility-birthday-party-groups/${userData.facilityId}/${group.id}/status`);

        const unsubscribe = onValue(groupRef, (snapshot) => {
            const newStatus  = snapshot.val();
            setStatus(newStatus ? newStatus : "inactive");
        })
        return unsubscribe;

    }, [userData]);

    return (
        <Accordion sx={{width: '100%'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Stack width={'100%'} mr={5} spacing={5} direction={'row'} justifyContent={'space-between'} >
                    <Stack>
                        <Typography fontSize={'1.5vw'} fontWeight={'bold'}> {groupName}</Typography>
                        <Typography ml={1} color={status && status === 'active' ? 'green' : 'red'} fontSize={'0.75vw'} fontWeight={'bold'}> {status.toUpperCase()}</Typography>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <Divider variant='middle'></Divider>
            <AccordionDetails>
                <BirthdayGroupPartiesTable group={group} groupStatus={status}></BirthdayGroupPartiesTable>
            </AccordionDetails>
        </Accordion>
    )
}

export default BirthdayPartyGroup;