import BottomButtonBar from "../bottom-button-bar/bottom-button-bar.component";
import { Box, Divider, Modal, Stack, Typography } from "@mui/material";

const AlertAcceptBookingTerms = ({ showChargeInfo, okAction, cancelAction, isOpen = true }) => {

    const close = () => {
        if (cancelAction) {
            cancelAction();
        }
    }

    return (
        <Modal open={isOpen} onClose={close}>
            <Box sx={{
                backgroundColor: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '5px',
                p: 4,
                width: { xs: '99vw', sm: '99vw', md: '50vw' }
            }}>
                <Stack spacing={1}>
                    <Typography fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>Book Reservation(s)?</Typography>
                    <Divider></Divider>
                    {showChargeInfo &&
                        <Stack spacing={1}>
                            <Typography>* Your credit card will be billed 48 hours before each reservation.</Typography>
                            <Typography>* For rentals booked within 48 hours of reservation start time, your card will be billed immediately.</Typography>
                            <Typography>* For invoice customers, your invoice will be updated.</Typography>
                        </Stack>
                    }
                    <Divider></Divider>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        <Typography mt={2} textAlign={'center'} fontWeight={'bold'} fontSize={{xs: '2.25vw', sm: '2.25', md:'1.0vw'}}>By booking this reservation, you are agreeing to comply with the Facility's Policies and pay any applicable cancellation and/or service fees.</Typography>
                    </Box>
                </Stack>
                <BottomButtonBar okButtonText={'Book Reservation(s)'} okAction={okAction} cancelButtonText={"Cancel"} cancelAction={cancelAction}></BottomButtonBar>
            </Box>
        </Modal>
    )
}

export default AlertAcceptBookingTerms;