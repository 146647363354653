import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import { Stack, Typography, Box, IconButton } from "@mui/material";
import FacilityViewCustomerBirthdayPartyRow from "./facility-view-customer-birthday-party-row.component";
import { GetBirthdayPartyBookings } from "../../../utils/birthday-api/birthday-service";
import BirthdayPartyViewBooked from "../birthday-party/birthday-party-view-booked.component";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';


const FacilityViewCustomerBirthdayParties = ({ title, customerId, startDate }) => {

    const { userData } = useContext(UserContext);
    const [bookedParties, setBirthdayParties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [birthdayPartyToView, setBirthdayPartyToView] = useState(null);

    async function getBookedParties(customerId, facilityId, startDate) {
        setIsLoading(true);
        const response = await GetBirthdayPartyBookings(facilityId, null, null, startDate, customerId, "asc");
        if (response && response.status === 200) {
            if (!response.data.status || response.data.status === 'success') {
                const { birthdayParties } = response.data;
                setBirthdayParties(birthdayParties)
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (userData) {
            getBookedParties(customerId, userData.facilityId, startDate);
        }

    }, [userData, customerId]);

    const viewBirthdayParty = (birthdayParty) => {
        setBirthdayPartyToView(birthdayParty);
    }

    const closeViewBirthdayParty = (refresh) => {
        setBirthdayPartyToView(null);

        if (refresh) {
            getBookedParties(customerId, userData.facilityId, startDate);
        }
    }

    const refreshList = () => {
        getBookedParties(customerId, userData.facilityId, startDate);
    }

    return (
        <Box key={`cust-reses-${startDate}`}>

            {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!isLoading &&
                <Stack m={1} spacing={1}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <IconButton onClick={refreshList} aria-label="refresh" color="primary">
                            <RefreshOutlinedIcon />
                        </IconButton>
                    </Box>
                    {bookedParties && bookedParties.length > 0 &&
                        bookedParties.map(bdayParty => (
                            <FacilityViewCustomerBirthdayPartyRow key={bdayParty.id} birthdayParty={bdayParty} viewBirthdayParty={viewBirthdayParty}></FacilityViewCustomerBirthdayPartyRow>
                        ))
                    }
                    {
                        bookedParties.length === 0 &&
                        <Typography ml={3} fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                            None
                        </Typography>
                    }
                </Stack>
            }
            {
                birthdayPartyToView &&
                <BirthdayPartyViewBooked bookedParty={birthdayPartyToView} close={closeViewBirthdayParty}></BirthdayPartyViewBooked>
            }

        </Box>
    )

}

export default FacilityViewCustomerBirthdayParties