import { createContext, useReducer } from "react";
import { createAction } from "../../utils/reducer/reducer.utils";
import dayjs from "dayjs";
const Decimal = require('decimal.js');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const defaultFormFields = {
    ancillaries: [],
    customFields: [],
}

export const BirthdayPartyContext = createContext({
    selectedBirthdayParty: {},
    setSelectedBirthdayParty: () => { },
    facilityData: {},
    setFacilityData: () => { },
    searchResponse: {},
    setSearchResponse: () => { },
    formOfPayment: {},
    setFormOfPayment: () => { },
    formFields: Object.assign({}, defaultFormFields),
    setFormFields: () => { },
    totalAncillary: new Decimal(0),
    preparedSummary: {},
    setPreparedSummary: () => { },
    partyInput: {},
    setPartyInput: () => { },
    confirmation: {},
    setConfirmation: () => {},
    isLoading: false,
    setIsLoading: () => {},
    alertMessage: {},
    setAlertMessage: () => {}
});

export const BIRTHDAY_PARTY_ACTION_TYPES = {
    SET_OBJECT: 'SET_OBJECT',
    RESET: 'RESET',
}

const INITIAL_STATE = {
    selectedBirthdayParty: null,
    facilityData: {},
    searchResponse: null,
    formOfPayment: null,
    formFields: Object.assign({}, defaultFormFields),
    totalAncillary: new Decimal(0),
    preparedSummary: null,
    partyInput: { date: dayjs().startOf('day'), startTime: dayjs().startOf('hour') },
    confirmation: null,
    isLoading: false,
    alertMessage: null
}

const birthdayPartyReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT:
            return {
                ...state,
                ...payload
            }
        case BIRTHDAY_PARTY_ACTION_TYPES.RESET:
            return payload
        default:
            throw new Error(`Unhandled type ${type}`);
    }
}

const findById = (list, idToFind) => {
    const existing = list.find(item => item.id === idToFind);
    if (existing) {
        return existing;
    }
    return null;
}

export const BirthdayPartyProvider = ({ children }) => {

    const [{ isLoading, alertMessage, selectedBirthdayParty, facilityData, searchResponse, formOfPayment, formFields, totalAncillary, preparedSummary, partyInput, confirmation }, dispatch] = useReducer(birthdayPartyReducer, INITIAL_STATE);

    const updateAncillary = (id, value) => {
        let ancillary = findById(formFields.ancillaries, id);
        const currentValue = ancillary.quantity && ancillary.quantity > 0 ? ancillary.quantity : 0;
        let newValue = currentValue + value;
        if (newValue < 0) {
            newValue = 0;
        }
        let filteredList = formFields.ancillaries.filter(anc => anc.id !== id);
        let sortedList = [...filteredList, { ...ancillary, 'quantity' : newValue }].sort((a1, a2) => {
            if (a1.sortOrder < a2.sortOrder) {
                return -1;
            }

            if (a1.sortOrder > a2.sortOrder) {
                return 1;
            }

            return 0;
        });

        let ancillaryTotal = new Decimal(0);
        for (const ancillary of sortedList) {
            if (ancillary.quantity) {
                ancillaryTotal = ancillaryTotal.plus(new Decimal(new Decimal(ancillary.price).times(new Decimal(ancillary.quantity))));
            }

        }

        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { formFields: { ...formFields, 'ancillaries' : sortedList }, totalAncillary: ancillaryTotal }));
    }

    const updateCustomField = (name, value, id) => {
        const customField = findById(formFields.customFields, id);
        if (customField) {
            let filteredList = formFields.customFields.filter(cf => cf.id !== id);
            let sortedList = [...filteredList, { ...customField, [name]: value }].sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            })
            setFormFields({ ...formFields, 'customFields' : sortedList });
        }
    }

    const setSelectedBirthdayParty = (birthdayParty) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { selectedBirthdayParty: birthdayParty }));
    }

    const setConfirmation = (confirm) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { confirmation: confirm }));
    }

    const setFacilityData = (data) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { facilityData: data }));
    }

    const setSearchResponse = (data) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { searchResponse: data }));
    }

    const setFormOfPayment = (fop) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { formOfPayment: fop }));
    }

    const setFormFields = (fields) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { formFields: fields }));
    }

    const setPreparedSummary = (summary) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { preparedSummary: summary }));
    }

    const setPartyInput = (input) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { partyInput: input }));
    }

    const reset = () => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.RESET, INITIAL_STATE));
    }

    const resetFormFields = () => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { formFields: defaultFormFields }));
    }

    const setIsLoading = (loading) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { isLoading: loading }));
    }

    const setAlertMessage = (message) => {
        dispatch(createAction(BIRTHDAY_PARTY_ACTION_TYPES.SET_OBJECT, { alertMessage: message }));
    }

    const value = { isLoading, setIsLoading, alertMessage, setAlertMessage, selectedBirthdayParty, setSelectedBirthdayParty, facilityData, setFacilityData, searchResponse, setSearchResponse, formOfPayment, setFormOfPayment, formFields, setFormFields, updateAncillary, updateCustomField, totalAncillary, preparedSummary, setPreparedSummary, partyInput, setPartyInput, reset, resetFormFields,  confirmation, setConfirmation }

    return <BirthdayPartyContext.Provider value={value}>{children}</BirthdayPartyContext.Provider>
}