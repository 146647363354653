import { SearchRepeatIntervalListContainer } from "./search-repeat-interval-list.styles";
import { useEffect, useState } from "react";
import { REPEATING_INTERVAL_TYPES } from '../../../utils/constants/constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import { TextField, Box } from "@mui/material";


const SearchRepeatsIntervalList = ({ selectedInterval, intervalSelected }) => {

    const [filter, setFilter] = useState('');

    useEffect(() => {
        setFilter(selectedInterval ? selectedInterval : '');
    }, [selectedInterval]);

    const clear = () => {
        if (filter === '') {
            intervalSelected("CloseView");
        } else {
            setFilter('');
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        const state = e.currentTarget.dataset.id;

        if (intervalSelected) {
            intervalSelected(state);
        }
    }

    const onTextChange = (event) => {
        setFilter(event.target.value);
    }

    return (
        <Box width={'100%'} position={'absolute'} zIndex={10} display={'flex'} flexDirection={'column'} sx={{backgroundColor: 'white', borderRadius: '5px', gap: '1.0vh', padding: '1.5vh', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'}}>
            <TextField id="repeats-interval-list" variant="outlined" label="Repeats" type="text" onChange={onTextChange} name="filter" value={filter} ></TextField>
            <List>
                {
                    REPEATING_INTERVAL_TYPES.filter(s => s.toUpperCase().includes(filter.toUpperCase()) || filter === '').map(interval => {
                        return <ListItem divider={true} key={interval} onClick={handleClick.bind(this)} data-id={interval}>
                            <ListItemText sx={{color: '#14254C'}} primary={interval}></ListItemText>
                        </ListItem>
                    })
                }
            </List>
        </Box>
    )
}

export default SearchRepeatsIntervalList;