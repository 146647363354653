import { Card, Typography, Stack, Button, Box } from "@mui/material";
import dayjs from "dayjs";

const BirthdayPartyAvailableOn = ({ dayOfWeek, timeSlots, mainColor = "#14254C", secondaryColor = 'gray' }) => {

    return (
        <Card variant='none' sx={{
            display: "flex", flexDirection: 'row', alignItems: 'center', border: `1px solid ${mainColor}`, minWidth: '15vw', 
        }}>
            <Stack width={'100%'} height={'100%'} margin={'1.0vw'} display={'flex'} justifyContent={'stretch'} alignItems={'center'}>
                <Stack height={'100%'} display={'flex'} alignItems={'stretch'} justifyContent={'flex-start'}>
                    <Typography mt={1} mb={1} sx={{ fontSize: { xs: '2.075w', sm: '2.75vw', md: '1.0vw', lg: '1.25vw', xl: '1.25vw' }, color: `${mainColor}` }} textAlign={'center'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                        {dayOfWeek}
                    </Typography>
                    <Stack display={'flex'} justifyContent={'center'}>
                        {timeSlots && timeSlots.length > 0 &&
                            timeSlots.map((timeSlot) => {
                                return <Typography key={timeSlot.startTime} sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' }, color: `${secondaryColor}` }} textAlign={'center'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                                    {dayjs(timeSlot.startTime).format("h:mm A")} - {dayjs(timeSlot.endTime).format("h:mm A")}
                                </Typography>
                            })
                        }
                        {!timeSlots &&
                            <Typography sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' }, color: `${secondaryColor}` }} textAlign={'center'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                                Not Available
                            </Typography>
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default BirthdayPartyAvailableOn