import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext, Fragment } from "react";
import { FacilityEventSearchContext } from '../../../contexts/facility-events-search/facility-event-search.context';
import { Box, Stack, Typography, Divider, CircularProgress } from '@mui/material';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { GetUpcomingEvents, SearchForFacilityEvents } from '../../../utils/facility-events/facility-events-service';
import dayjs from 'dayjs';
import FacilityEventsMap from './facility-events-map.component';
import FacilityEventsListEventsWithSearch from './facility-events-list-events-with-search.component';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import FacilityInfoMarker from './facility-info-marker.component';
import FacilityMasterEventCard from './facility-master-event-card.component';
import logo from '../../../assets/logo-01.png';


const logoImageStyle = {
    height: '25.0vh',
    width: '25.0vw',
    objectFit: 'contain'
};

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    let matchWithOne = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matchWithOne) {
        return matchWithOne[1] + '(' + matchWithOne[2] + ') ' + matchWithOne[3] + '-' + matchWithOne[4]
    };

    return str
};

const getFilterTypeFromPath = (pathname) => {
    let filterType = null;

    if (pathname.includes("camps")) {
        filterType = "Camp";
    } else if (pathname.includes("tournaments")) {
        filterType = "Tournament";
    } else if (pathname.includes("leagues")) {
        filterType = "League";
    }
    return filterType;
}

const ViewFacilityEvents = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [upcomingEventsAreLoading, setUpcomingEventsAreLoading] = useState(false);
    const { facilityId } = useParams();
    const [eventType, setEventType] = useState(null);
    const { setSearchResults } = useContext(FacilityEventSearchContext);
    const [facilityInfo, setFacilityInfo] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState([]);

    const fetchInitialSearcResults = async (pathname) => {
        let filterType = getFilterTypeFromPath(pathname);
        setEventType(filterType);
        if (filterType) {
            setIsLoading(true);
            const response = await SearchForFacilityEvents({ eventType: filterType, startDate: dayjs().format("YYYY-MM-DD"), facilityId: facilityId });
            if (response && response.data && response.data.searchResults) {
                setSearchResults(response.data.searchResults);
                if (response.data.facilityInfo && response.data.facilityInfo.name) {
                    setFacilityInfo(response.data.facilityInfo);
                } else {
                    setFacilityInfo(null);
                }
            } else {
                setSearchResults([]);
                setFacilityInfo(null);
            }
            setIsLoading(false);
        } else {
            setSearchResults([]);
            setFacilityInfo(null);
        }
    }

    const fetchUpcomingEvents = async (pathname) => {
        let filterType = getFilterTypeFromPath(pathname);
        setUpcomingEventsAreLoading(true)
        const response = await GetUpcomingEvents(filterType, facilityId);
        if (response && response.data && response.data.upcomingEvents) {
            setUpcomingEvents(response.data.upcomingEvents);
        } else {
            setUpcomingEvents([]);
        }
        setUpcomingEventsAreLoading(false);
    }

    useEffect(() => {
        const path = window.location.pathname;
        fetchInitialSearcResults(path);

        return () => {
            setSearchResults([]);
            setFacilityInfo(null);
        }

    }, []);

    useEffect(() => {
        const path = window.location.pathname;
        fetchUpcomingEvents(path);

        return () => {
            setUpcomingEvents([]);
        }

    }, []);

    const getEventRedirect = (eventDetails) => {
        const eventTypeValue = eventDetails.eventType.toUpperCase();
        let eventTypePath = '';
        switch (eventTypeValue) {
            case 'LEAGUE':
                eventTypePath = 'leagues';
                break;
            case 'TOURNAMENT':
                eventTypePath = 'tournaments';
                break;
            case 'CAMP':
                eventTypePath = 'camps';
                break;
            default:
                eventTypePath = 'events';
        }

        return `${eventTypePath}/${eventDetails.facilityId}`;
    }

    const pathname = window.location.pathname;

    return (
        <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
            <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'9.0vh'} left={'0%'} right={'0%'} bottom={'5%'} display={'flex'}>
                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                {
                    facilityInfo &&
                    <Fragment>
                        {
                            !facilityId && !isLoading &&
                            <Box>
                                <Typography ml={2} mb={2} gutterBottom textAlign={'center'} fontWeight={'bold'} variant="h5">Discover {eventType ? eventType : "Event"}s</Typography>
                                <FacilityEventsMap filterType={eventType} backToUrl={pathname}></FacilityEventsMap>
                            </Box>
                        }
                        {
                            !isLoading && facilityId && facilityInfo &&
                            <Stack mt={4} ml={4} mr={4} spacing={5} direction={{xs: 'column', sm: 'column', md: 'row'}} display={'flex'} >
                                <Stack mt={1} spacing={3}>
                                    <Typography fontSize={{xs: '4.5vw', sm: '4.5vw', md: '2.25vw'}} fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'center'} gutterBottom color={"#14254C"} component="div">
                                        {facilityInfo.name}
                                    </Typography>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <img style={logoImageStyle} className="facility-logo-image" src={facilityInfo.facilityLogoUrl ? facilityInfo.facilityLogoUrl : logo} alt="Logo" />
                                    </Box>
                                    <Divider></Divider>
                                    <Stack spacing={1}>
                                        <Typography fontWeight={'bold'} mb={2} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.5vw', sm: '3.5vw', md: '1.25vw'}} >
                                            Upcoming {eventType}s
                                        </Typography>
                                        {
                                            upcomingEventsAreLoading &&
                                            <Box display={'flex'} justifyContent={'center'}>
                                                <CircularProgress sx={{ color: '#14254C' }} />
                                            </Box>
                                        }
                                        {
                                            !upcomingEventsAreLoading && upcomingEvents.length <= 0 &&
                                            <Typography ml={2} mb={1} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.25vw', sm: '3.25vw', md: '1.0vw'}} >
                                                There are no upcoming {eventType}s for this facility
                                            </Typography>
                                        }
                                        {
                                            !upcomingEventsAreLoading && upcomingEvents.length > 0 &&
                                            <Stack sx={{ flexWrap: 'wrap', rowGap: '2.5vh' }} spacing={1} width={'98%'} display={'flex'} direction={'row'} justifyContent={'flex-start'}>
                                                {
                                                    upcomingEvents.map((event) => {
                                                        return <FacilityMasterEventCard key={event.id} eventDetails={event}></FacilityMasterEventCard>
                                                    })
                                                }
                                            </Stack>
                                        }
                                    </Stack>
                                    <Divider></Divider>
                                    <Stack>
                                        <Typography fontWeight={'bold'} mb={1} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.5vw', sm: '3.5vw', md: '1.25vw'}} >
                                            Contact
                                        </Typography>
                                        <Typography ml={1} mb={1} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.25vw', sm: '3.25vw', md: '1.0vw'}} >
                                            {facilityInfo && facilityInfo.contactInfo ? facilityInfo.contactInfo.emailAddress : ''}
                                        </Typography>
                                        <Typography ml={1} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.25vw', sm: '3.25vw', md: '1.0vw'}} >
                                            {facilityInfo && facilityInfo.contactInfo && facilityInfo.contactInfo.phoneNumber ? formatPhoneNumber(facilityInfo.contactInfo.phoneNumber) : ''}
                                        </Typography>
                                    </Stack>
                                    <Divider></Divider>
                                    {facilityInfo.location &&
                                        <Stack>
                                            <Typography fontWeight={'bold'} mb={1} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.5vw', sm: '3.5vw', md: '1.25vw'}} >
                                                Location
                                            </Typography>
                                            <Stack>
                                                <Typography ml={1} mb={1} textAlign={'left'} color={"#14254C"} component="div" fontSize={{xs: '3.25vw', sm: '3.25vw', md: '1.0vw'}} >
                                                    {facilityInfo.location.address1}, {facilityInfo.location.city} {facilityInfo.location.state} {facilityInfo.location.zipCode}
                                                </Typography>
                                                <Box mt={1} display={'flex'} justifyContent='center'>
                                                    <Box sx={{ height: '30vh', width: '77vw', backgroundColor: 'gray' }} >
                                                        <Map mapId={'bf51a910020fa25a'} defaultCenter={{ lat: facilityInfo.latitude, lng: facilityInfo.longitude }} zoom={15} disableDefaultUI>
                                                            <FacilityInfoMarker showInfo={false} key={`lcoation-map-${facilityId}`} position={{ lat: facilityInfo.latitude, lng: facilityInfo.longitude }} facilityName={facilityInfo.name} facilityLogoUrl={facilityInfo.facilityLogoUrl} mainFacilityColor={'#14254C'} secondaryFacilityColor={'#DEDEDE'}></FacilityInfoMarker>
                                                        </Map>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    }
                                </Stack>
                                <Box display={'flex'} flexDirection={'column'} overflow={'visible'}>
                                    <FacilityEventsListEventsWithSearch backToUrl={pathname} filterType={eventType} facilityId={facilityId}></FacilityEventsListEventsWithSearch>
                                </Box>
                            </Stack>
                        }
                    </Fragment>
                }
            </Box>
        </APIProvider>
    )

}

export default ViewFacilityEvents;