import { Fragment, useContext, useEffect, useState } from "react";
import { GetBirthdayPartyBookings } from '../../../utils/birthday-api/birthday-service';
import { UserContext } from "../../../contexts/user.context";
import { Paper, Stack, Typography, Box, Button, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import MySportSpaceAlert from "../../alert/alert.component";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BirthdayPartyViewBooked from "./birthday-party-view-booked.component";
import { GetBookedBirthdayParty } from "../../../utils/birthday-api/birthday-service";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


function getRowId(row) {
    return row.id;
}

const defaultBirthdayPartyInfo = {
    birthdayParties: [],
    name: ""
}

const BirthdayPartyBookings = ({ birthdayPartyId, customerId, close, refreshAndClose }) => {

    const { userData } = useContext(UserContext);
    const [birthdaypartyInfo, setBirthdayPartyInfo] = useState(defaultBirthdayPartyInfo);
    const [alertMessage, setAlertMessage] = useState(null);
    const [selectedParties, setSelectedParties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedParty, setSelectedParty] = useState(null);

    const { birthdayParties, name } = birthdaypartyInfo;

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    const closeAlertMessage = () => {

        if (alertMessage && alertMessage.refresh) {
            getBirthdayParties();
        }

        setAlertMessage(null);

    }

    const closeSelectedParty = () => {
        setSelectedParty(null);
        getBirthdayParties();
    }

    const closeView = () => {
        if (close) {
            close();
        }
    }

    const columns = [
        {
            field: 'fullName',
            renderHeader: () => (
                <strong>
                    {'Name '}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75,
            valueGetter: (value, row) => {
                return `${row.customer.userFirstName || ''} ${row.customer.userLastName || ''}`;
            },
        },
        {
            field: 'partyDate',
            flex: 0.5,
            minWidth: 75,
            renderHeader: () => (
                <strong>
                    {'Date '}
                </strong>
            ),
        },
        {
            field: 'startTime',
            renderHeader: () => (
                <strong>
                    {'Start Time'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75
        },
        {
            field: 'endTime',
            renderHeader: () => (
                <strong>
                    {'End Time'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75
        },
        {
            field: 'price',
            renderHeader: () => (
                <strong>
                    {'Price'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75,
            valueGetter: (value) => {
                if (!value) {
                    return ""
                }

                return `$${parseFloat(value).toFixed(2)}`
            },
        },
        {
            field: 'paidStatus',
            renderHeader: () => (
                <strong>
                    {'Status'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75,
            renderCell: (params) => {
                const row = params.row;
                return (
                    <Stack height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                        <Typography color={row.reservationCharge.paymentFailed ? 'red' : "#14254C"}>{row.cancelData ? 'Canceled' : row.reservationCharge ? row.reservationCharge.paymentFailed ? "FAILED PAYMENT" : row.reservationCharge.paidStatus.toUpperCase() : "UNPAID"}</Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'viewButton',
            headerName: '',
            description: '',
            sortable: false,
            flex: 1.0,
            renderCell: (params) => {
                return (
                    <Stack spacing={1} mt={1} direction={'row'}>
                        <Tooltip title="View/Edit">
                            <IconButton onClick={(e) => viewButtonClick(e, params.row)}>
                                <VisibilityOutlinedIcon></VisibilityOutlinedIcon>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    async function getBirthdayParties(nextPage) {
        setIsLoading(true);
        let partyInfo = defaultBirthdayPartyInfo;
        if (userData && userData.facilityId) {
            const response = await GetBirthdayPartyBookings(userData.facilityId, birthdayPartyId, null, null, customerId);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    partyInfo = response.data;
                }
            }

            setBirthdayPartyInfo(partyInfo);
            setIsLoading(false);
        }

    }

    useEffect(() => {

        getBirthdayParties(true);

    }, [userData, paginationModel.page]);

    const selectParty = async (id) => {
        setIsLoading(true);
        const response = await GetBookedBirthdayParty(id);
        const { status, data = {} } = response;

        if (status === 200 && data.status === 'success') {
            setSelectedParty(data.bookedBirthdayParty);
        } else {
            setSelectedParty(null);
        }
        setIsLoading(false);
    }

    const viewButtonClick = (e, row) => {
        e.stopPropagation();
        selectParty(row.id);
    };

    return (
        <Fragment>
            <Paper>
                <Stack sx={{
                    height: '85vh',
                    width: '98%',
                    marginLeft: '1.0vw',
                    marginRight: '1.0vw'
                }}>
                    <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button onClick={closeView} startIcon={<CloseOutlinedIcon />}>
                        </Button>
                    </Box>
                    <Box mt={1} mb={2} display="flex" justifyContent="center" alignItems="center">
                        <Typography textAlign={'center'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={1}>
                            {name ? name : 'Booked Birthday Parties'}
                        </Typography>
                    </Box>
                    <DataGrid sx={{
                        font: 'Helvetica', color: '#14254C',
                        fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                    }} getRowId={getRowId} loading={isLoading} rowCount={1} initialState={{ pagination: paginationModel }} pageSizeOptions={[100]} onPaginationModelChange={setPaginationModel} paginationMode="server"
                        rows={birthdayParties} columns={columns} checkboxSelection={false} disableRowSelectionOnClick={false} rowSelectionModel={selectedParties} onRowSelectionModelChange={(ids) => {
                                selectParty(ids);
                        }}
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
                </Stack>
            </Paper>
            <BirthdayPartyViewBooked bookedParty={selectedParty} close={closeSelectedParty}></BirthdayPartyViewBooked>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
        </Fragment>
    )

}

export default BirthdayPartyBookings