import { useParams } from 'react-router-dom';
import { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { GetFacilityTourInfo } from '../../utils/facility-api/facility-service';
import mssLogo from "../../assets/logo-01.png";
import SearchForReservation from '../search-for-reservation/search-for-reservation.component';
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import FacilityNotFound from '../facility/facility-not-found/facility-not-found.component';
import MySportSpaceLoadingView from '../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { Box, Typography, Stack, ImageList, ImageListItem, ImageListItemBar, Divider, IconButton, Button } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import FacilityInfoMarker from '../customer/facility-events/facility-info-marker.component';
import { useNavigate } from "react-router-dom";
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const mainImageStyle = {
    height: '35vh',
    width: '35vw',
    objectFit: 'contain',
    marginTop: '1.0vh'
};

const mainImageSmallStyle = {
    height: '30vh',
    width: '70vw',
    objectFit: 'contain',
    marginTop: '1.0vh'
};

const FacilityInfoView = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const locationMapRef = useRef(null);
    const contactRef = useRef(null);
    const resSearchRef = useRef(null);
    const eventsRef = useRef(null);
    const { facilityId } = useParams();
    const { isSearchingForReservations } = useContext(SearchForReservationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [facilityTourInfo, setFacilityTourInfo] = useState({});

    const navigate = useNavigate();

    useEffect(() => {

        const fetchFacilityTourInfo = async (facilityId) => {
            setIsLoading(true);

            const response = await GetFacilityTourInfo(facilityId);
            if (response && response.status == 200 && response.data.status === 'success') {
                setFacilityTourInfo(response.data);
            } else {
                setFacilityTourInfo({});
            }

            setIsLoading(false);
        }

        window.scrollTo(0, 0);
        fetchFacilityTourInfo(facilityId);
    }, [facilityId]);

    // const allAreaImages = [];
    const areaImagesForDisplay = [];
    const { facilityName, allowWebCalendar, searchTerm, facilityLogoUrl, allowOnlineReservations, facilityPhotoUrl, facilityAreas, promotionalText, hoursOfOperation, address = {}, cancelPolicies = [], facilityAmenities = [], membershipsOnly = false, advanceDaysNoticeForReservations, advanceHoursNoticeForReservations, daysOutForReservations, hasMemberships = false, sportsOffered, maxReservationDate, latitude, longitude, mainFacilityColor = "#14254C", secondaryFacilityColor = '#DEDEDE', phoneNumber, emailAddress } = facilityTourInfo;
    const { streetAddress, state, city, zipCode } = address;
    if (facilityTourInfo && facilityAreas) {
        for (const area of facilityAreas) {
            if (area.imageLocationURL) {
                // allAreaImages.push({
                //     imageUrl: area.imageLocationURL,
                //     id: area.areaId,
                //     name: area.name,
                //     sortOrder: area.sortOrder
                // });

                areaImagesForDisplay.push({
                    imageUrl: area.imageLocationURL,
                    id: area.areaId,
                    name: area.name,
                    sortOrder: area.sortOrder
                });

                if (area.subAreas) {
                    for (const sArea of area.subAreas) {
                        if (sArea.imageLocationURL) {
                            areaImagesForDisplay.push({
                                imageUrl: sArea.imageLocationURL,
                                id: sArea.areaId,
                                name: sArea.name,
                                sortOrder: sArea.sortOrder
                            });
                        }
                    }
                }
            }
        }

        areaImagesForDisplay.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });
    }

    const goToWebCalendar = () => {
        var calendarId = facilityId;
        if (searchTerm) {
            calendarId = searchTerm;
        }

        navigate(`/reservations/${calendarId}`);
    }

    const goToTournaments = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/tournaments/${path}`);
    }

    const goToCamps = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/camps/${path}`);
    }

    const goToLeagues = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/leagues/${path}`);
    }

    const goToBirthdayParties = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/birthday-parties/${path}`);
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mr={{ xs: 10, sm: 10, md: 5 }} ml={{ xs: 10, sm: 10, md: 5 }} mt={11} mb={11}  >
            <MySportSpaceLoadingView isOpen={isSearchingForReservations || isLoading}></MySportSpaceLoadingView>
            {
                !isLoading && !facilityName &&
                <FacilityNotFound></FacilityNotFound>
            }
            {
                !isLoading && facilityName &&
                <Stack display={'flex'} alignItems={'stretch'} spacing={3}>
                    <Stack ml={4} mr={4} direction={{ xs: 'column', sm: 'column', md: 'row' }} display={'flex'} alignItems={'stretch'} justifyContent={'space-between'}>
                        <Typography fontSize={{ xs: '5.25vw', sm: '5.25vw', md: '2.25vw' }} fontWeight={'bold'} mt={2} mb={2} textAlign={{ xs: 'center', sm: 'center', md: 'left' }} gutterBottom color={"#14254C"}>
                            {facilityName}
                        </Typography>
                        <Stack direction={'row'} display={'flex'} justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}>
                            <Stack>
                                <IconButton sx={{
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }} aria-label="contact" onClick={() => contactRef.current?.scrollIntoView()}>
                                    <EmailOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                </IconButton>
                                <Button onClick={() => contactRef.current?.scrollIntoView()} sx={{
                                    fontSize: { xs: '2.25vw', sm: '4225vw', md: '1.0vw' },
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }}>Contact</Button>
                            </Stack>
                            <Stack>
                                <IconButton sx={{
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }} aria-label="map-icon" onClick={() => locationMapRef.current?.scrollIntoView()}>
                                    <MapOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                </IconButton>
                                <Button onClick={() => locationMapRef.current?.scrollIntoView()} sx={{
                                    fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }}>Location</Button>
                            </Stack>
                            {
                                allowOnlineReservations &&
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="res-search-icon" onClick={() => eventsRef.current?.scrollIntoView()}>
                                        <EmojiEventsOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                    </IconButton>
                                    <Button onClick={() => eventsRef.current?.scrollIntoView()} sx={{
                                        fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Events</Button>
                                </Stack>
                            }
                            {
                                allowOnlineReservations &&
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="res-search-icon" onClick={() => resSearchRef.current?.scrollIntoView()}>
                                        <SearchOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                    </IconButton>
                                    <Button onClick={() => resSearchRef.current?.scrollIntoView()} sx={{
                                        fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Search</Button>
                                </Stack>
                            }
                            {
                                allowWebCalendar &&
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="calendar" onClick={goToWebCalendar}>
                                        <CalendarMonthOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                    </IconButton>
                                    <Button onClick={goToWebCalendar} sx={{
                                        fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Calendar</Button>
                                </Stack>
                            }

                        </Stack>
                    </Stack>
                    <Divider />
                    <Box flexDirection={'column'} display={'flex'} justifyContent={'stretch'} alignItems={'stretch'} >
                        <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} mb={2} spacing={{ xs: 1, sm: 1, md: 3 }} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                            {
                                !isSmallScreen &&
                                <img style={mainImageStyle} className="facility-logo-image" src={facilityPhotoUrl ? facilityPhotoUrl : facilityLogoUrl ? facilityLogoUrl : mssLogo} alt="Logo" loading="lazy" />
                            }
                            {
                                isSmallScreen &&
                                <img style={mainImageSmallStyle} className="facility-logo-image" src={facilityPhotoUrl ? facilityPhotoUrl : facilityLogoUrl ? facilityLogoUrl : mssLogo} alt="Logo" loading="lazy" />
                            }
                            {!isSmallScreen &&
                                <ImageList sx={{ height: { md: '35vh' } }} cols={3} >
                                    {
                                        areaImagesForDisplay.map((image) => {
                                            return <ImageListItem key={image.id}>
                                                <img
                                                    srcSet={`${image.imageUrl}&w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${image.imageUrl}&w=164&h=164&fit=crop&auto=format`}
                                                    alt={image.name}
                                                    loading='lazy'
                                                />
                                                <ImageListItemBar
                                                    title={image.name} />
                                            </ImageListItem>
                                        })
                                    }
                                </ImageList>
                            }
                        </Stack>
                    </Box>
                    <Divider />
                    <Stack ml={{xs: 5, sm: 5,  md: 1 }} mr={{ md: 5 }} direction={'column'}>
                        {
                            promotionalText &&
                            <Fragment>
                                <Typography sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.0vw' } }} fontWeight={'bold'} mr={{xs: 5, sm: 5, md: 5 }} ml={{xs: 5, sm: 5, md: 5 }} mt={2} textAlign={{ xs: 'center', sm: 'center', md: 'left' }} color={"#14254C"} component="div">
                                    {promotionalText}
                                </Typography>
                                <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            </Fragment>
                        }

                        {
                            allowOnlineReservations &&
                            <section ref={resSearchRef} id="res-search-section">
                                <Stack sx={{ maxHeight: '50vh' }} mt={1} mb={1} display={'flex'} justifyContent={{ xs: 'stretch', md: 'flex-start' }} alignItems={'stretch'}>
                                    <Typography fontWeight={'bold'} ml={{ xs: 5, sm: 5, md: 10 }} mt={1} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Search For Reservation
                                    </Typography>
                                    <Box mb={{ xs: 5, sm: 5, md: 0 }} mr={{ xs: 1, sm: 1, md: 0 }} ml={{ xs: 5, sm: 5, md: 3 }} display={'flex'} flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent={{ md: 'flex-start' }}>
                                        <SearchForReservation key={'search-for-reservation-component'} facilityId={facilityTourInfo.facilityId} searchFormDirection='row' sportsOverride={sportsOffered} backUrl={`/facilityinfo/${facilityId}`} showTitle={false}></SearchForReservation>
                                    </Box>
                                </Stack>
                                <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            </section>
                        }
                        <Stack>
                            <section ref={eventsRef} id="res-search-section">
                                <Stack mt={2} spacing={2} ml={{ xs: 1, sm: 1, md: 7 }} mr={{ xs: 1, sm: 1, md: 0 }} direction={'row'} display={'flex'} justifyContent={'flex-start'}>
                                    <Stack sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                        <IconButton size='large' sx={{
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }} aria-label="tournaments" onClick={goToBirthdayParties} >
                                            <CelebrationOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                        </IconButton>
                                        <Button onClick={goToBirthdayParties} size='large' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }}>Birthday Parties</Button>
                                    </Stack>
                                    <Stack sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                        <IconButton size='large' sx={{
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }} aria-label="tournaments" onClick={goToTournaments} >
                                            <FormatListNumberedOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                        </IconButton>
                                        <Button onClick={goToTournaments} size='large' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }}>Tournaments</Button>
                                    </Stack>
                                    <Stack mt={1} sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                        <IconButton size='large' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }} aria-label="camps" onClick={goToCamps} >
                                            <TimerOutlinedIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                        </IconButton>
                                        <Button onClick={goToCamps} size='large' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }}>Camps</Button>
                                    </Stack>
                                    <Stack mt={1} sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                        <IconButton size='large' sx={{
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }} aria-label="leagues" onClick={goToLeagues} >
                                            <FeaturedPlayListIcon sx={{ fontSize: { xs: '4.25vw', sm: '4.25vw', md: '2.25vw' } }} />
                                        </IconButton>
                                        <Button onClick={goToLeagues} size='large' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.0vw' },
                                            ':hover': {
                                                bgcolor: 'white'
                                            }
                                        }}>Leagues</Button>
                                    </Stack>
                                </Stack>
                                <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            </section>
                        </Stack>

                        {hoursOfOperation &&
                            <Fragment>
                                <Typography fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                    Hours Of Operation
                                </Typography>
                                {
                                    hoursOfOperation.map((hop) => {
                                        return <Stack ml={{ xs: 8, sm: 8, md: 8 }} display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'} spacing={2} direction={'row'} key={hop.dayText} >
                                            <Typography minWidth={{ xs: '25vw', sm: '25vw', md: '10vw' }} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                {hop.dayText}
                                            </Typography>
                                            <Typography mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                {hop.startTime}
                                            </Typography>
                                            <Typography mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                {hop.endTime}
                                            </Typography>
                                        </Stack>
                                    })
                                }
                                <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            </Fragment>
                        }
                        <Stack spacing={1} mr={8} direction={{xs: 'column', sm: 'column', md: 'row'}}>
                            <Stack width={{xs: '80%', sm: '80%', md: '48%'}}>
                                <Typography fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                    Facility Details
                                </Typography>
                                <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                    {membershipsOnly ? "Members Only Facility" : "Retail Facility"}
                                </Typography>

                                {
                                    hasMemberships &&
                                    <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        Memberships are available
                                    </Typography>
                                }
                                <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                    {advanceDaysNoticeForReservations && advanceDaysNoticeForReservations > 0 ? `Reservations require ${advanceDaysNoticeForReservations} days advance notice` : advanceHoursNoticeForReservations && advanceHoursNoticeForReservations > 0 ? `Reservations require ${advanceHoursNoticeForReservations} hours notice` : "No advance notice required"}
                                </Typography>

                                {
                                    daysOutForReservations && !maxReservationDate &&
                                    <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        {
                                            `Reservations bookings are allowed up to ${moment().add(daysOutForReservations, 'days').format("ddd, MMMM D, YYYY")}`
                                        }
                                    </Typography>
                                }

                                {
                                    maxReservationDate &&
                                    <Typography noWrap={false} ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        {
                                            `Reservations bookings are allowed up to ${moment(maxReservationDate, 'YYYY-MM-DD').format("ddd, MMMM D, YYYY")}`
                                        }
                                    </Typography>
                                }
                            </Stack>
                            <Stack width={{xs: '80%', sm: '80%', md: '48%'}}>
                                {
                                    facilityAmenities &&
                                    <Fragment>
                                        <Typography fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                            Facility Includes
                                        </Typography>
                                    </Fragment>
                                }
                                {
                                    facilityAmenities && facilityAmenities.map((amenenty) => {
                                        return <Typography key={amenenty} ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">{amenenty}</Typography>
                                    })
                                }
                            </Stack>
                        </Stack>
                        {
                            cancelPolicies &&
                            <Fragment>
                                <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>

                                <Typography fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                    Cancellation Policies
                                </Typography>
                            </Fragment>
                        }
                        {
                            cancelPolicies && cancelPolicies.map((policy) => {
                                return <Typography key={policy} ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">{policy}</Typography>
                            })
                        }
                    </Stack>

                    <Stack display={'flex'} justifyContent={'flex-start'}>
                        <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                        <section ref={contactRef} id="location-map-section">
                            <Typography fontWeight={'bold'} ml={5} mt={2} mb={1} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                Contact {facilityName}
                            </Typography>
                        </section>
                        <Typography ml={7} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {emailAddress}
                        </Typography>
                        <Typography ml={7} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {phoneNumber}
                        </Typography>
                    </Stack>

                    <Stack display={'flex'} justifyContent={'flex-start'}>
                        <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                        <Typography fontWeight={'bold'} ml={5} mt={2} mb={1} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                            Facility Location
                        </Typography>
                        <Typography ml={7} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {streetAddress}
                        </Typography>
                        <Typography ml={7} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {city}, {state} {zipCode}
                        </Typography>
                        <section ref={locationMapRef} id="location-map-section">
                            <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
                                <Box mt={1} display={'flex'} justifyContent='center'>
                                    <Box sx={{ height: '50vh', width: '77vw', backgroundColor: 'gray' }} >
                                        <Map mapId={'bf51a910020fa25a'} defaultCenter={{ lat: latitude, lng: longitude }} zoom={15} disableDefaultUI>
                                            <FacilityInfoMarker showInfo={false} key={facilityId} position={{ lat: latitude, lng: longitude }} facilityName={facilityName} facilityLogoUrl={facilityLogoUrl} mainFacilityColor={mainFacilityColor} secondaryFacilityColor={secondaryFacilityColor}></FacilityInfoMarker>
                                        </Map>
                                    </Box>
                                </Box>
                            </APIProvider>
                        </section>
                    </Stack>
                </Stack>

            }
        </Box >
    )

}

export default FacilityInfoView;