import { StatesListContainer } from "./states-list.styles";
import { useEffect, useState } from "react";
import closeImg from '../../assets/close-circle.svg';
import { LIST_OF_STATES } from '../../utils/constants/constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import { TextField, Stack } from "@mui/material";

const StatesList = ({ selectedState, stateSelected }) => {

    const [filter, setFilter] = useState('');

    useEffect(() => {
        setFilter(selectedState ? selectedState : '');
    }, [selectedState]);

    const clear = () => {
        if (filter === '') {
            stateSelected("CloseView");
        } else {
            setFilter('');
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        const state = e.currentTarget.dataset.id;

        if (stateSelected) {
            stateSelected(state);
        }
    }

    const onTextChange = (event) => {
        setFilter(event.target.value);
    }

    return (
        <Stack display={'flex'} position={'absolute'} zIndex={10} sx={{ top: 0, backgroundColor: 'white', borderRadius: '5px', overflow: 'scroll' }} height={'100%'} width={{ xs: '75%', sm: '75%', md: '100%' }}>
            <Stack spacing={2} ml={2} mr={2} mt={2} mb={2}>
                <TextField id="repeats-interval-list" variant="outlined" label="Enter State" type="text" autoFocus onChange={onTextChange} name="filter" value={filter} ></TextField>
                {/* <div className="input-and-clear">
                <input autoFocus placeholder="Enter State" className="text-input" type="text" onChange={onTextChange} name="filter" value={filter} />
                <img src={closeImg} className='clear-btn' alt='close' onClick={clear}></img>
            </div> */}
                <List sx={{overflow: 'scroll'}}>
                    {
                        LIST_OF_STATES.filter(s => s.toUpperCase().includes(filter.toUpperCase()) || filter === '').map(state => {
                            return <ListItem divider={true} key={state} onClick={handleClick.bind(this)} data-id={state}>
                                <ListItemText primary={state}></ListItemText>
                            </ListItem>
                        })
                    }
                </List>
            </Stack>
        </Stack>
    )
}

export default StatesList;