import { SearchDateExceptionsListContainer } from './search-date-exceptions-list.styles';
import { useEffect, useState } from "react";
import TwoButtonBar from "../../two-button-bar/two-button-bar.component";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import { Box, Divider, Typography } from '@mui/material';

const DATE_FORMAT = 'ddd, MMM D, YYYY';

const SearchDateExceptionsList = ({ searchDates = [], selectedExceptionDates = [], exceptionDatesSelected = [] }) => {

    const [exceptionsDates, setExceptionDates] = useState([]);

    useEffect(() => {
        setExceptionDates(selectedExceptionDates ? selectedExceptionDates : []);
    }, [selectedExceptionDates]);

    const handleClick = (e) => {
        e.preventDefault();
        const date = e.currentTarget.dataset.id;

        let newArray = [];
        const existingDow = exceptionsDates.find((d) => d === date);
        if (existingDow) {
            newArray = exceptionsDates.filter(d => d !== date);
        } else {
            newArray = [...exceptionsDates, date];
        }

        setExceptionDates(newArray);

        if (exceptionDatesSelected) {
            exceptionDatesSelected(newArray, false);
        }
    }

    const doneButtonClicked = () => {
        if (exceptionDatesSelected) {
            exceptionDatesSelected(exceptionsDates);
        }
    }

    const clearButtonClicked = () => {
        setExceptionDates([]);
        if (exceptionDatesSelected) {
            exceptionDatesSelected([], false);
        }
    }

    return (
        <Box width={'100%'} position={'absolute'} zIndex={10} display={'flex'} flexDirection={'column'} sx={{ backgroundColor: 'white', borderRadius: '5px', gap: '1.0vh', padding: '1.5vh', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
            <Typography>Select Exception Dates</Typography>
            <Divider></Divider>
            <List>
                {
                    searchDates.map(date => {
                        return <ListItem key={date} divider={true} onClick={handleClick.bind(this)} data-id={date.format(DATE_FORMAT)}>
                            <ListItemText primary={date.format(DATE_FORMAT)}></ListItemText>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedExceptionDates.includes(date.format(DATE_FORMAT))}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                        </ListItem>
                    })
                }
            </List>
            <TwoButtonBar button1Text={'Clear'} button1Action={clearButtonClicked} button2Text={'Done'} button2Action={doneButtonClicked}></TwoButtonBar>
        </Box>
    )
}

export default SearchDateExceptionsList;