import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const FormOfPaymentsList = ({ formOfPayments, setFormOfPayment, selectedFormOfPayment, closeList }) => {

    const currentDateTime = dayjs.tz(dayjs(), dayjs.tz.guess());
    const currentMonth = currentDateTime.month();
    const currentYear = currentDateTime.year();

    const getFormOfPaymentName = (fop) => {
        if (fop) {
            if (fop.card) {
                const card = fop.card;
                let exp_month = `${card.exp_month}`;
                if (card.exp_month < 10) {
                    exp_month = `0${exp_month}`
                }
                const cardDescription = `${card.brand.toUpperCase()} *${card.last4} EXP ${exp_month}/${card.exp_year}`;
                return cardDescription;
            } else if (fop.id === "Add Form Of Payment") {
                return "Add Form Of Payment"
            }
        }

        return '';
    }

    const isFopExpired = (fop) => {
        if (fop.card) {
            const card = fop.card;
            const cardExpireMonth = card.exp_month;
            const cardExpiredYear = card.exp_year;

            if (cardExpiredYear < currentYear) {
                return true;
            }

            if (cardExpiredYear === currentYear) {
                if (cardExpireMonth > currentMonth) {
                    return true;
                }
            }

        }
        return false;
    }

    const selectCard = (event) => {
        const {value} = event.target;
        setFormOfPayment(value);
    }

    return (
        <TextField
            id="outlined-select-form-of-payment"
            select
            label="Select Card"
            value={selectedFormOfPayment ? selectedFormOfPayment : ''}
            onChange={selectCard}
        >
            {formOfPayments.map((fop) => (
                 <MenuItem disabled={isFopExpired(fop)} key={fop.id} id={fop.id} value={fop.id}>
                    {getFormOfPaymentName(fop)}
                </MenuItem>
            ))}
        </TextField>
    )

}

export default FormOfPaymentsList;