import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo-01.png'
import { Box, Stack, AppBar, Link, Button, IconButton } from "@mui/material";
import CustomerAccountMenu from '../customer/account-menu/account-menu.component';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';

const logoImageStyle = {
    maxWidth: '10.0vh',
    objectFit: 'contain'
};

const NavigationCustomer = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const getTheApp = () => {
        navigate('/mobile-app');
    }
    return (
        <AppBar component={'nav'} sx={{ backgroundColor: 'white', maxHeight: '12.0vh', overflow: 'hidden', zIndex: (theme) => theme.zIndex.drawer + 1 }} position='fixed' >
            <Stack height={'8.0vh'} display={'flex'} direction={'row'} justifyContent={'space-between'} ml={{xs: 1, sm: 1, md: 4}} mr={{xs: 1, sm: 1, md: 4}}>
                {/* <Box width={'33%'}> */}
                    <Link className="logo-container" href='/'>
                        <Box sx={{ height: '95%' }} display={'flex'} justifyContent="flex-start" alignItems="center">
                            <img style={logoImageStyle} className="logo-image" src={logo} alt="Logo" />
                        </Box>
                    </Link>
                {/* </Box> */}
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box>
                        <Stack sx={{ border: { xs: 'none', sm: 'none', md: 'solid 1px gray'}, borderRadius: '5px' }} direction={'row'} >
                            <Button sx={{ fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: { xs: '1.75vh', sm: '1.75vh', xl: '1.75vh' } }} onClick={getTheApp}>Get The App</Button>
                        </Stack>
                    </Box>
                </Box>
                <Box display="flex" justifyContent={'flex-end'} alignItems="center">
                    <CustomerAccountMenu></CustomerAccountMenu>
                </Box>
            </Stack>
        </AppBar>
    )
}

export default NavigationCustomer