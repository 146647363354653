import { SearchLocationInputContainer } from "./search-location-input.styles";
import { Fragment, useContext, useEffect, useState } from "react";
import StatesList from '../../states-list/states-list.component';
import TwoButtonBar from '../../two-button-bar/two-button-bar.component';
import { TextField, Typography, Stack } from "@mui/material";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";


const SearchLocationInput = ({ showLocationInput, locationWasSelected }) => {

    const { reservationSearchData } = useContext(SearchForReservationContext);
    const [showStates, setShowStates] = useState(false);
    const [city, setCity] = useState(reservationSearchData.selectedCity ? reservationSearchData.selectedCity : '');
    const [miles, setMiles] = useState(reservationSearchData.selectedMiles ? reservationSearchData.selectedMiles : reservationSearchData.selectedCity && reservationSearchData.selectedCity ? null : 50);
    const [state, setState] = useState(reservationSearchData.selectedState ? reservationSearchData.selectedState : '');

    const handleStateClick = () => {
        setShowStates(true);
    }

    const stateSelected = (state) => {
        setShowStates(false);
        setMiles(0);
        if (state !== "CloseView") {
            setState(state);
        }
    }

    const onCityChange = (event) => {
        setMiles(0);
        setCity(event.target.value)
    }

    const onMilesChange = (event) => {
        setMiles(event.target.value);
        setState('');
        setCity('');
    }

    const clearCity = () => {
        setCity('');
    }

    const clearMiles = () => {
        setMiles(0)
    }

    const doneButtonClicked = () => {
        if (locationWasSelected) {
            locationWasSelected(city, state, miles);
        }
    }

    const clearButtonClicked = () => {
        setCity('');
        setState('');
        setMiles(50);
    }

    return (
        <Fragment>
            {
                showLocationInput &&
                <Stack display={'flex'} position={'absolute'} zIndex={10} sx={{ backgroundColor: 'white', borderRadius: '5px' }} width={{ xs: '75%', sm: '75%', md: '25%' }}>
                    <Stack spacing={2} ml={2} mr={2} mt={2} mb={2}>
                        <TextField id="miles" variant="outlined" label="Search Radius (Miles)" type="number" onChange={onMilesChange} name="miles" value={miles > 0 ? miles : city || state ? '' : 50}></TextField>
                        <Typography textAlign={'center'} color={"#14254C"} variant="body1">or</Typography>
                        <TextField id="city" variant="outlined" label="City" placeholder="Enter City" type="text" onChange={onCityChange} name="city" value={city} ></TextField>
                        <TextField id="state" variant="outlined" label="State" placeholder="Enter State" type="text" onFocus={handleStateClick} onClick={handleStateClick} name="state" value={state} ></TextField>
                        <TwoButtonBar button1Text={'Reset'} button1Action={clearButtonClicked} button2Text={'Done'} button2Action={doneButtonClicked}></TwoButtonBar>
                        {
                            showStates &&
                            <StatesList stateSelected={stateSelected}></StatesList>
                        }
                    </Stack>
                </Stack>
            }
        </Fragment>
    )
}

export default SearchLocationInput