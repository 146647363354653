import { Card, CardMedia, CardContent, Stack, Typography, CardActions, Button, Divider, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";


const FacilityMasterEventCard = ({ eventDetails, backToUrl }) => {

    const { eventType, imageUrl, facilityId, name, gradeStart, gradeEnd, ageRangeBegin, ageRangeEnd, anyAge, id, underOrOverAgeIndicator, underOverAge, signupStartsDate, eventStartsDate } = eventDetails;

    const ageInfo = anyAge ? 'All Ages' : ageRangeBegin && ageRangeEnd ? `Ages ${ageRangeBegin} - ${ageRangeEnd}` : underOrOverAgeIndicator ? `${underOverAge} ${underOrOverAgeIndicator}` : null;

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        goToEventDetails();
    }

    const viewEvent = (e) => {
        e.preventDefault();
        goToEventDetails();
    }

    const goToEventDetails = () => {
        const eventTypeValue = eventType.toUpperCase();
        let eventMainPath = '';
        switch (eventTypeValue) {
            case 'LEAGUE':
                eventMainPath = 'leagues';
                break;
            case 'TOURNAMENT':
                eventMainPath = 'tournaments';
                break;
            case 'CAMP':
                eventMainPath = 'camps';
                break;
            default:
                eventMainPath = 'events';
        }

        if (backToUrl) {
            navigate(`/${eventMainPath}/${facilityId}/${id}?redirectUrl=${backToUrl}`);
        } else {
            const path = window.location.pathname;
            navigate(`/${eventMainPath}/${facilityId}/${id}?redirectUrl=${path}`);
        }
    }

    return (
        <Card onClick={handleClick} sx={{ cursor: "pointer", ":hover": { boxShadow: `0 3px 10px #14254C` } }}>
            <CardMedia
                component="img"
                image={imageUrl ? imageUrl : "/facility-events-stock-image.avif"}
                alt="facility photo"
                sx={{
                    "&.MuiCardMedia-img": {
                        width: "15vw",
                        height: "10vw",
                        borderRadius: '5px',
                        aspectRatio: '16/9',
                        objectFit: 'contain'
                    }
                }}
            />
            <CardContent sx={{ display: "flex", flexDirection: 'column', maxWidth: {xs: '25vw', sm: '25vw', md: '15vw'}, minWidth: {xs: '25vw', sm: '25vw', md: '15vw'}, justifyContent: 'stretch', alignItems: 'stretch' }}>
                <Stack spacing={1} mb={2}>
                    <Typography sx={{ fontSize: {xs: '2.5vw', sm: '2.5vw', md:'0.75vw'} }} gutterBottom textAlign={'center'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} component="div">
                        {name.toUpperCase()}
                    </Typography>
                </Stack>
                <Divider></Divider>
            </CardContent>
            <CardActions>
                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                    <Button data-id={id} onClick={viewEvent.bind(this)} sx={{fontSize: {xs: '2.25vw', sm: '2.25vw', md:'1.0vw'}}} color="primary">
                        View Details
                    </Button>
                </Box>

            </CardActions>
        </Card>
    )
}

export default FacilityMasterEventCard;