import FacilityCard from "../facility-card/facility-card.component";
import { GetFacilitiesByName } from '../../../utils/facility-api/facility-service';
import { useState, useEffect } from "react";
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import { Stack, Typography, Box, Divider, IconButton, Popover, Button } from "@mui/material";
import HomePageFacilityFilter from "./home-page-facility-filter.component";
import FacilityBirthdayCard from "../facility-birthday-card/facility-birthday-card.component";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const defaultFilter = {
    sportsSelected: [],
    selectedOfferings: [],
    searchLocation: null
}

const HomePageFacilities = ({ filterType }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = useState(false);
    const [allFacilities, setAllFacilities] = useState([]);
    const [searchFilter, setSearchFilter] = useState(defaultFilter);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null)

    const fetchFacilities = async (startAfter, searchFilter) => {
        setIsLoading(true);
        const response = await GetFacilitiesByName(startAfter, filterType, searchFilter);
        if (response && response.status == 200 && response.data.status === 'success') {
            const { facilities } = response.data;
            if (startAfter) {
                setAllFacilities(allFacilities.concat(facilities));
            } else {
                setAllFacilities(facilities);
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchFacilities();
    }, []);

    const showMoreFacilities = () => {
        let startAfter = null;
        if (allFacilities.length > 0) {
            startAfter = allFacilities.length;
        }
        fetchFacilities(startAfter);
    }

    let filteredFacilities = [];
    if (!filterType) {
        filteredFacilities = allFacilities.filter((facility) => {
            return facility.allowOnlineReservations;
        })
    } else {
        filteredFacilities = allFacilities;
    }

    const applySearchFilter = (searchFilter) => {
        fetchFacilities(null, searchFilter);
        setSearchFilter(searchFilter);
    }

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const showFilter = Boolean(filterAnchorEl);
    return (
        <Stack sx={{ marginBottom: '5.0vh', marginLeft: '1.0vw', borderTop: '1px solid #DEDEDE' }} >
            {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {
                isSmallScreen &&
                <Box>
                    <IconButton onClick={handleFilterClick}>
                        <TuneOutlinedIcon></TuneOutlinedIcon>
                    </IconButton>
                    <Popover
                        open={showFilter}
                        onClose={handleFilterClose}
                        anchorEl={filterAnchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <Box>
                            <Box m={3}>
                                <HomePageFacilityFilter filterType={filterType} applySearchFilter={applySearchFilter} filter={searchFilter}></HomePageFacilityFilter>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <Button onClick={handleFilterClose} sx={{ marginTop: '2.0vh' }}>Close</Button>
                                </Box>

                            </Box>
                        </Box>
                    </Popover>
                </Box>
            }
            {
                !isLoading &&

                <Stack display={'flex'} direction={'row'} sx={{ marginTop: '3.0vh' }} spacing={{ xs: 1, sm: 1, md: 5 }}>
                    {
                        !isSmallScreen &&
                        <HomePageFacilityFilter filterType={filterType} applySearchFilter={applySearchFilter} filter={searchFilter}></HomePageFacilityFilter>
                    }
                    <Box>
                        {
                            filterType !== 'birthday-parties' &&
                            <Stack spacing={1} direction={'row'} flexWrap={'wrap'} columnGap={2} rowGap={{ xs: 1, sm: 1, md: 3 }} justifyContent={'center'} >
                                {!isLoading && filteredFacilities.length > 0 &&
                                    filteredFacilities.map((facility) => {
                                        return <FacilityCard key={`facility-card-${facility.facilityId}`} facility={facility} filterType={filterType}></FacilityCard>
                                    })
                                }
                                {
                                    !isLoading && filteredFacilities.length <= 0 &&
                                    <Stack>
                                        <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={"gray"} component="div" fontSize={{ xs: '4.0vw', sm: '4.0vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' }}>
                                            Would you like your preferred facility on the My Sport Space network?  Submit a referral at www.mysportspace.info.
                                        </Typography>
                                    </Stack>
                                }
                            </Stack>
                        }
                        {
                            filterType === 'birthday-parties' &&
                            <Stack spacing={1} direction={'column'} flexWrap={'wrap'} columnGap={2} rowGap={{ xs: 1, sm: 1, md: 3 }}>
                                {!isLoading && allFacilities.length > 0 &&
                                    filteredFacilities.map((facility) => {
                                        return <Box key={`facility-bday-box-${facility.facilityId}`}><FacilityBirthdayCard key={`facility-bday-card-${facility.facilityId}`} facility={facility} filterType={filterType}></FacilityBirthdayCard> <Divider></Divider></Box>
                                    })
                                }
                                {
                                    !isLoading && allFacilities.length <= 0 &&
                                    <Stack mt={5}>
                                        {
                                            !searchFilter &&
                                            <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={"gray"} component="div" fontSize={{ xs: '4.0vw', sm: '4.0vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' }}>
                                                At this time, there are not any My Sport Space facilities offering Birthday Party packages.  Please check back soon!
                                            </Typography>
                                        }
                                        {
                                            searchFilter && !searchFilter.searchLocation &&
                                            <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={"gray"} component="div" fontSize={{ xs: '4.0vw', sm: '4.0vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' }}>
                                                At this time, there are not any My Sport Space facilities offering Birthday Party packages.  Please check back soon!
                                            </Typography>
                                        }
                                        {
                                            searchFilter && searchFilter.searchLocation &&
                                            <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={"gray"} component="div" fontSize={{ xs: '4.0vw', sm: '4.0vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' }}>
                                                At this time, there are not any My Sport Space facilities in {searchFilter.searchLocation.label} offering Birthday Party packages.  Please check back soon!
                                            </Typography>
                                        }
                                    </Stack>
                                }
                            </Stack>
                        }
                    </Box>
                </Stack>
            }
        </Stack>

    )
}

export default HomePageFacilities;