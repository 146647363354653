import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState, useContext, Fragment, useEffect } from 'react';
import { UserContext } from '../../../contexts/user.context';
import { GetBirthdayPartyGroups } from '../../../utils/birthday-api/birthday-service';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';


const SelectBirthdayPartyGroup = ({ selectGroup, disabled = false }) => {

    const { userData } = useContext(UserContext);
    const [birthdayPartyGroups, setBirthdayPartyGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ groupNameSelected, setGroupNameSelected ] = useState('');

    async function getBirthdayPartyGroups(userData) {

        setIsLoading(true);

        if (userData && userData.facilityId) {
            let groups = [];
            const response = await GetBirthdayPartyGroups(userData.facilityId);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    groups = response.data.birthdayPartyGroups;
                }
            }
            setBirthdayPartyGroups(groups);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getBirthdayPartyGroups(userData);
    }, [userData]);

    const handleChange = (event) => {
        const name = event.target.dataset.value;
        setGroupNameSelected(name);
        if (selectGroup) {
            selectGroup(name);
        }
    }

    return (
        <FormControl fullWidth>
            {
                isLoading &&
                <Box width={'100%'} mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress sx={{ color: "#14254C" }} />
                </Box>
            }
            {
                !isLoading &&
                <Fragment>
                    <InputLabel id="birthday-party-group-select-label">Group Name</InputLabel>
                    <Select
                        labelId="birthday-party-group-select-label"
                        id="birthday-party-group-select"
                        value={groupNameSelected}
                        label="Group Name"
                    >
                        {
                            birthdayPartyGroups.map((group) => {
                                return <MenuItem key={group} onClick={handleChange} value={group.groupName}>{group.groupName}</MenuItem>
                            })
                        }
                    </Select>
                </Fragment>
            }

        </FormControl>
    )
}

export default SelectBirthdayPartyGroup