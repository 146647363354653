import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActions, Stack, TextField, CardMedia, Divider } from '@mui/material';
import { DAYS_OF_WEEK_INT_TO_TEXT } from '../../../utils/constants/constants';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user.context';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BirthdayPartyAvailableOn from './birthday-party-available-on.component';

import { DAYS_OF_WEEK_SORT_ORDER } from '../../../utils/search-utils/search-utils';

const BirthdayPartyInfoCard = ({ birthdayParty, facilityName }) => {

    const { userData } = useContext(UserContext);
    const { name, price, details = {}, imageLocationURL, id } = birthdayParty;
    const { maxCapicity, daysOffered = [], description, timeSlots } = details;
    const { mainTextColor = "#14254C", secondaryTextColor = "#A9A9A9" } = !userData || (userData && !userData.isFacilityUserAccount) && details ? details : {};

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        selectBirthdayParty(birthdayParty, e);
    }

    const selectBirthdayParty = (birthdayParty, event) => {
        navigate(`/birthday-parties/${facilityName}/${birthdayParty.searchTerm ? birthdayParty.searchTerm : birthdayParty.id}`);
    }

    let dowText = "";
    for (const dow of daysOffered) {
        dowText = `${dowText} ${DAYS_OF_WEEK_INT_TO_TEXT[dow]}`
    }

    const timeSlotToDow = {};
    if (timeSlots && timeSlots.length > 0) {
        for (const timeSlot of timeSlots) {
            if (timeSlotToDow[timeSlot.dow]) {
                timeSlotToDow[timeSlot.dow] = [...timeSlotToDow[timeSlot.dow], timeSlot];
            } else {
                timeSlotToDow[timeSlot.dow] = [timeSlot];
            }
        }
    }

    return (
        <Card variant="none" sx={{
            display: "flex", flexDirection: 'row', alignItems: 'stretch', columnGap: 2
        }}>
            <Stack spacing={2} display={'flex'} justifyContent={'center'}>
                <CardMedia
                    component="img"
                    image={imageLocationURL ? imageLocationURL : "/birthday-party-table-image.webp"}
                    alt="facility photo"
                    sx={{
                        "&.MuiCardMedia-img": {
                            width: "25vw",
                            height: "25vw",
                            borderRadius: '5px',
                            aspectRatio: '16/9'
                        }
                    }}
                />
                <Box sx={{ width: '100%', marginBottom: '1.0vh' }} display="flex" justifyContent="center" alignItems="center">
                    <Button  sx={{ backgroundColor: `${mainTextColor}`, fontSize: {xs: '1.75vw', sm: '1.75vw', md: '1.0vw'} }} variant="contained" onClick={handleClick}>
                        Book Party
                    </Button>
                </Box>
            </Stack>

            <Stack mr={2} spacing={1} display={'flex'} justifyContent={'flex-start'} >
                <Stack spacing={2}>
                    <Typography id={id} sx={{ marginLeft: "2.0vw", fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' } }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={mainTextColor}>
                        {name}
                    </Typography>
                    <Divider flexItem variant='middle' sx={{ opacity: 0.5 }} ></Divider>
                    <Typography sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.50vw', lg: '1.50vw', xl: '1.50vw' } }} textAlign={'lef'} fontStyle={'Helvetica'} fontWeight={'bold'} color={mainTextColor}>
                        ${price}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2.5vw', sm: '2.5vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={secondaryTextColor} >
                        {description}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2.5vw', sm: '2.5vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} fontWeight={'bold'} variant="h6" component="div" mt={1}>
                        Number Of Guests  {maxCapicity}
                    </Typography>
                    {
                        timeSlots && timeSlots.length > 0 &&
                        <Box>
                            <Typography mb={1} sx={{ fontSize: { xs: '2.5vw', sm: '2.=5vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} fontWeight={'bold'} variant="h6" component="div" mt={1}>
                                Birthday Parties Available On
                            </Typography>
                            <Divider variant='middle' sx={{ opacity: '0.6' }}></Divider>
                            <Stack mt={2} display={'flex'} direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={2} >
                                {
                                    Object.keys(DAYS_OF_WEEK_SORT_ORDER).map((tsKey) => {
                                        return <BirthdayPartyAvailableOn dayOfWeek={tsKey} timeSlots={timeSlotToDow[tsKey]}></BirthdayPartyAvailableOn>
                                    })
                                }
                            </Stack>
                        </Box>


                    }
                </Stack>
            </Stack>
        </Card>
    )

}

export default BirthdayPartyInfoCard;