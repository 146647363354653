import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Button, Stack, TextField, CardMedia, Divider } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user.context';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BirthdayCardForPartyInGroup from './birthday-card-for-party-in-group';

const StyledTextField = styled(TextField)`
  .MuiInput-input:hover {
    cursor: pointer;
  }
`;

const BirthdayPartyGroupInfoCard = ({ birthdayPartyGroup, facilityName }) => {

    const { userData } = useContext(UserContext);
    const { groupName, id, description = "", lowestPrice, birthdayParties, imageLocationURL } = birthdayPartyGroup;
    const { mainTextColor = "#14254C", secondaryTextColor = "#A9A9A9" } = birthdayPartyGroup && (!userData || (userData && !userData.isFacilityUserAccount)) ? birthdayPartyGroup : {};
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        navigate(`/birthday-parties/${facilityName}/package/${birthdayPartyGroup.searchTerm ? birthdayPartyGroup.searchTerm : birthdayPartyGroup.id}`);

    }

    return (
        <Card variant="none" sx={{
            display: "flex", flexDirection: 'row', alignItems: 'stretch'
        }}>
            <Stack display={'flex'} justifyContent={'center'} spacing={1}>
                <CardMedia
                    component="img"
                    image={imageLocationURL ? imageLocationURL : "/birthday-party-table-image.webp"}
                    alt="facility photo"
                    sx={{
                        "&.MuiCardMedia-img": {
                            width: "25vw",
                            height: "25vw",
                            borderRadius: '5px',
                            aspectRatio: '16/9'
                        }
                    }}
                />
                <Box sx={{ width: '100%', marginBottom: '1.0vh' }} display="flex" justifyContent="center" alignItems="center">
                    <Button sx={{
                        backgroundColor: `${mainTextColor}`, ':hover': {
                            bgcolor: `${secondaryTextColor}`
                        }
                    }} variant="contained" onClick={handleClick}>
                        View All Parties
                    </Button>
                </Box>
            </Stack>
            <Stack ml={3} mr={2} spacing={1} display={'flex'} justifyContent={'flex-start'} >
                <Stack spacing={2}>
                    <Typography id={id} sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' } }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={mainTextColor}>
                        {groupName}
                    </Typography>
                    <Divider flexItem variant='middle' sx={{ opacity: 0.5 }} ></Divider>
                    <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={secondaryTextColor}>
                        {description}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '1.25vw', sm: '1.25vw', md: '1.50vw', lg: '1.50vw', xl: '1.50vw' } }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} fontWeight={'bold'}>
                        {birthdayParties.length} Parties To Choose From Starting At {`$${lowestPrice}`}
                    </Typography>
                    <Divider flexItem variant='middle' sx={{ opacity: 0.5 }} ></Divider>
                    {
                        birthdayParties && birthdayParties.length > 0 &&
                        <Stack display={'flex'} direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={2}  >
                            {
                                birthdayParties.map((birthdayParty) => {
                                    return <Stack key={`bday-party-stack-${birthdayParty.id}`} spacing={2} display={'flex'}>
                                        <BirthdayCardForPartyInGroup key={`bday-party-party-in-group]-${birthdayParty.id}`} birthdayParty={birthdayParty} mainColor={mainTextColor}></BirthdayCardForPartyInGroup>
                                    </Stack>
                                })
                            }
                        </Stack>
                    }

                </Stack>
            </Stack>
        </Card >
    )

}

export default BirthdayPartyGroupInfoCard;