import { Stack, Typography, Divider } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BottomButtonBar from "../../bottom-button-bar/bottom-button-bar.component";
import { useState } from "react";
import FormOfPayment from '../../form-of-payment/form-of-payment.component';

const BirthdayPartyUpdateFopDialog = ({ isOpen = false, bookedParty, updateFormOfPayment, closeDialog, title = "Update Form Of Payment" }) => {

    const [formOfPaymentSelected, setFormOfPaymentSelected] = useState(null);

    const selectFormOfPayment = (formOfPaymentIn) => {
        if (formOfPaymentIn) {
            let formOfPaymentId = formOfPaymentIn.id ? formOfPaymentIn.id : formOfPaymentIn;
            setFormOfPaymentSelected(formOfPaymentId);
        } else {
            setFormOfPaymentSelected(null);
        }
    }

    const close = () => {

        if (closeDialog) {
            closeDialog();
        }
    }

    const performFormOfPaymentUpdate = () => {
        if (updateFormOfPayment) {
            updateFormOfPayment(formOfPaymentSelected);
        }
        setFormOfPaymentSelected(false);
    }

    return (
        <Dialog maxWidth={'xs'}
            fullWidth
            open={isOpen}
            aria-labelledby="update-bdayparty-fop-dialog-title"
            aria-describedby="update-bdayparty-fop-dialog-description"
        >
            <DialogTitle id="update-bdayparty-dialog-fop-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <Stack mt={2} spacing={2}>
                    {
                        bookedParty &&
                        <FormOfPayment facilityCustomer={bookedParty.customer} formOfPayment={formOfPaymentSelected} formOfPaymentType={'Credit Card'} selectFormOfPayment={selectFormOfPayment} allowCashCheck={false} ></FormOfPayment>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <BottomButtonBar okButtonIsDisabled={formOfPaymentSelected ? false : true} okButtonText={"Update"} okAction={performFormOfPaymentUpdate} cancelButtonText={"Cancel"} cancelAction={close} mainColor={'#14254C'} secondaryColor={"gray"}></BottomButtonBar>
            </DialogActions>
        </Dialog>
    );
}

export default BirthdayPartyUpdateFopDialog;