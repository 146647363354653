import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext, Fragment } from 'react';
import CalendarTopHeader from './calendar-top-header/calendar-top-header.component';
import Calendar from './calendar/calendar.component';
import { findFacilityBySearchTerm } from "../../../utils/facility-api/facility-service";
import { CalendarSettingsContext } from '../../../contexts/customer/calendar-settings.context';
import CustomerFacilityCalendarNotFound from '../customer-facility-calendar-not-found/customer-facility-calendar-not-found.component';
import CustomerCalendarOffline from '../customer-facility-calendar-offline/customer-facility-calendar-offline.component';
import { Box, Stack } from '@mui/material';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import useDeviceDetection from '../../../utils/device-detection/device-detection';
import CalendarTopHeaderMobile from './calendar-top-header/calendar-top-header.component-mobile';

function isValidDateFormat(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
}

const CustomerFacilityCalendar = () => {

    const device = useDeviceDetection();
    const [facilityInfo, setFacilityInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setFacilityId, reservationsDate, setReservationsDate } = useContext(CalendarSettingsContext);
    const { facilityName, urlCalendarDate} = useParams();

    useEffect(() => {

        const fetchCalendarDate = async (urlCalendarDate) => {
            if (urlCalendarDate && isValidDateFormat(urlCalendarDate)){
                setReservationsDate(urlCalendarDate);
            }
        }

        const fetchFacilityInfo = async () => {
            setIsLoading(true);
            const facilityResponse = await findFacilityBySearchTerm(facilityName);
            if (facilityResponse && facilityResponse.status == 200 && facilityResponse.data.status === 'success') {
                const { facilityInfo } = facilityResponse.data;
                setFacilityInfo(facilityInfo);
                setFacilityId(facilityInfo.facilityId);
            } else {
                setFacilityInfo(null);
                setFacilityId(null);
            }
            setIsLoading(false);
        }
    

        fetchFacilityInfo();
        fetchCalendarDate(urlCalendarDate)
    }, [urlCalendarDate]);

    const resdateSelected = (date) => {
        setReservationsDate(date);
    }

    return (
        <Stack sx={{ overflow: 'scroll' }} position={'fixed'} top={'9.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            {
                !isLoading && facilityInfo && facilityInfo.allowWebCalendar &&
                <Fragment>
                    {
                        device === 'Mobile' &&
                        <CalendarTopHeaderMobile reservationsDate={reservationsDate} setReservationsDate={resdateSelected} facilityInfo={facilityInfo}></CalendarTopHeaderMobile>
                    }
                    {
                        device !== 'Mobile' &&
                        <CalendarTopHeader reservationsDate={reservationsDate} setReservationsDate={resdateSelected} facilityInfo={facilityInfo}></CalendarTopHeader>
                    }
                    <Calendar facilityName={facilityName} facilityId={facilityInfo.facilityId} reservationsDate={reservationsDate}></Calendar>
                </Fragment>
            }
            {
                !isLoading && facilityInfo && !facilityInfo.allowWebCalendar &&
                <CustomerCalendarOffline></CustomerCalendarOffline>
            }
            {
                !isLoading && !facilityInfo &&
                <CustomerFacilityCalendarNotFound></CustomerFacilityCalendarNotFound>
            }
        </Stack>
    )
}

export default CustomerFacilityCalendar;