import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import FacilityInfoMarker from './facility-info-marker.component';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import FacilityEventsListEventsWithSearch from './facility-events-list-events-with-search.component';
import { GetFacilitiesByName } from '../../../utils/facility-api/facility-service';
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';

const defaultCenter = {
    lat: 38.7946,
    lng: -97
};

const FacilityEventsMap = ({ filterType, backToUrl }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = useState(true);
    const [allFacilities, setAllFacilities] = useState([]);

    const navigate = useNavigate();

    const fetchFacilities = async () => {
        setIsLoading(true);
        const response = await GetFacilitiesByName(null, null);
        if (response && response.status == 200 && response.data.status === 'success') {
            const { facilities } = response.data;
            setAllFacilities(facilities);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchFacilities();

        return () => {
            setAllFacilities([]);
        }
    }, [filterType]);

    const viewEvents = (facilityId) => {
        let eventTypeForPath = '';
        switch (filterType) {
            case 'League':
                eventTypeForPath = 'leagues';
                break;
            case 'Tournament':
                eventTypeForPath = 'tournaments';
                break;
            case 'Camp':
                eventTypeForPath = 'camps';
                break;
            default:
                eventTypeForPath = 'events';
        }

        navigate(`${eventTypeForPath}/${facilityId}`);
    }

    return (
        <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
            <Box display={'flex'} height={'90vh'} width={'100%'}>
                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                <Stack width={'100%'} display={'flex'} spacing={2} ml={1} mr={1} direction={{ xs: 'column', sm: 'column', md: 'row' }}>
                    {
                        !isSmallScreen &&
                        <Box sx={{ height: '80vh', width: '77vw', backgroundColor: 'gray' }}>


                            <Map mapId={`facility-event-map-${filterType}`} defaultCenter={defaultCenter} defaultZoom={5}>
                                {
                                    allFacilities.map((facility) => {
                                        const { facilityId, latitude, longitude, facilityName, mainFacilityColor = '#14254C', secondaryFacilityColor = "#DEDEDE", facilityLogoUrl } = facility;
                                        if (latitude && longitude) {
                                            return <AdvancedMarker key={facilityId} position={{ lat: latitude, lng: longitude }} title={facilityName}>
                                                <FacilityInfoMarker key={facilityId} position={{ lat: latitude, lng: longitude }} facilityId={facilityId} facilityName={facilityName} filterType={filterType} facilityLogoUrl={facilityLogoUrl} mainFacilityColor={mainFacilityColor} secondaryFacilityColor={secondaryFacilityColor} viewEvents={viewEvents}></FacilityInfoMarker>
                                            </AdvancedMarker>
                                        }
                                    })
                                }
                            </Map>
                        </Box>
                    }
                    <Box ml={1} sx={{ height: '85vh', width: {xs: '100%', sm: '100%', md: '20vw' }}} display={'flex'} justifyContent={'center'}>
                        <FacilityEventsListEventsWithSearch backToUrl={backToUrl} filterType={filterType}></FacilityEventsListEventsWithSearch>
                    </Box>
                </Stack>
            </Box>
        </APIProvider>
    )
}

export default FacilityEventsMap;