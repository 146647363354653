import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Modal, Paper, Stack, Typography, Box, Button, IconButton, Tooltip } from "@mui/material";
import { GetBirthdayParties, UpdateBirthdayPartiesStatus, CopyBirthdayParties, UpdateGroupBirthdayParties } from "../../../utils/birthday-api/birthday-service";
import MySportSpaceAlert from "../../alert/alert.component";
import MySportSpaceSnackbarAlert from "../../alert-snackbar/alert-snackbar.component";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BirthdayPartySetup from "./birthday-party-setup.component";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import BirthdayPartyBookings from "./birthday-party-bookings.component";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BirthdayPartyGroupSetup from "./birthday-party-group-setup.component";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import { getDatabase, ref, onValue } from "firebase/database";


function getRowId(row) {
    return row.id;
}

const BirthdayGroupPartiesTable = ({ group, groupStatus }) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const { userData, isAdmin } = useContext(UserContext);
    const [showAddPartyDialog, setShowAddPartyDialog] = useState(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [selectedParties, setSelectedParties] = useState([]);
    const [selectedPartyId, setSelectedPartyId] = useState(null);
    const [editLockName] = useState(`groupedbirthdayParty-${group.id}/editLock`);
    const [viewSignupsId, setViewSignupsId] = useState(null);
    const [openAlert, setOpenAlert] = useState(null);
    const [addingPartyToGroup, setAddingPartyToGroup] = useState(false);
    const [birthdayParties, setBirthdayParties] = useState([]);
    const [isLoading, setIsLoading] = useState()
    const [alertMessage, setAlertMessage] = useState(null);
    const [showGroupDetails, setShowGroupDetails] = useState(false);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    const viewButtonClick = (e, row) => {
        e.stopPropagation();
        setSelectedPartyId(row.id);
    };

    const viewSignupsButtonClick = (e, row) => {
        e.stopPropagation();
        setViewSignupsId(row.id);
    };

    const columns = [
        {
            field: 'name',
            renderHeader: () => (
                <strong>
                    {'Name '}
                </strong>
            ),
            flex: 1.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} >{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'status',
            flex: 0.5,
            minWidth: 25,
            renderHeader: () => (
                <strong>
                    {'Status '}
                </strong>
            ),
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography color={params.value && params.value === 'active' ? 'green' : 'red'} sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'price',
            renderHeader: () => (
                <strong>
                    {'Price'}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'initialDeposit',
            renderHeader: () => (
                <strong>
                    {'Initial Deposit'}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'viewButton',
            headerName: '',
            description: '',
            sortable: false,
            flex: 1.0,
            renderCell: (params) => {
                return (
                    <Stack height={'100%'} display={'flex'} alignItems={'center'} spacing={1} direction={'row'}>
                        {
                            !isEditMode &&
                            <Tooltip title="View or Edit">
                                <IconButton onClick={(e) => viewButtonClick(e, params.row)}>
                                    <VisibilityOutlinedIcon></VisibilityOutlinedIcon>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            !isEditMode &&
                            <Tooltip title="View Signups">
                                <IconButton onClick={(e) => viewSignupsButtonClick(e, params.row)}>
                                    <EditNoteIcon></EditNoteIcon>
                                </IconButton>
                            </Tooltip>
                        }
                    </Stack>
                );
            },
        }
    ];

    async function getBirthdayParties(nextPage, groupId) {
        setIsLoading(true);
        let parties = [];
        if (userData && userData.facilityId) {

            let startAfterId = null;
            let startAt = null;
            let endAt = null;
            if (nextPage) {
                startAfterId = birthdayParties.length > 0 ? birthdayParties[birthdayParties.length - 1].name : null;
            } else if (birthdayParties.length > 0) {
                startAt = birthdayParties[0].name;
            }

            const response = await GetBirthdayParties(userData.facilityId, startAfterId, startAt, endAt, groupId);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    parties = response.data.birthdayParties;
                }
            }

            setBirthdayParties(parties);
            setIsLoading(false);
        }

    }

    useEffect(() => {
        return () => {
            if (userData) {
                removeEditLock(editLockName, userData);
            }
        }

    }, [userData, paginationModel.page]);

    useEffect(() => {

        const db = getDatabase();
        const groupRef = ref(db, `facility-birthday-party-groups/${userData.facilityId}/${group.id}/updatedTimeStamp`);

        const unsubscribe = onValue(groupRef, (snapshot) => {
            getBirthdayParties(false, group.id);
        })
        return unsubscribe;

    }, [userData]);

    const showTheAddToPartyDialog = () => {
        setShowAddPartyDialog(true);
    }

    const closeAddingPartyToGroup = () => {
        setAddingPartyToGroup(false);
        getBirthdayParties(false, group.id);
    }

    const removePartiesFromGroup = async () => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'needs_one_to_remove', message: "Please select at least 1 party to remove from the group.", buttonText: "OK" });
            return;
        }

        setShowRemoveDialog(true);
    }

    const removeParties = async () => {

        setShowRemoveDialog(false);
        // if (selectedParties.length >= birthdayParties.length) {
        //     setOpenAlert({ type: 'needs_one', message: "A birthday party group must have at least 1 party.  You cannot remove all", buttonText: "OK" });
        //     return;
        // }

        updatePartiesInGroup(selectedParties, "remove");
    }

    const addParties = async () => {
        setShowRemoveDialog(false);
        updatePartiesInGroup(selectedParties, "add");
    }

    const updatePartiesInGroup = async (birthdayPartyIds, updateType) => {

        setIsLoading(true);
        const response = await UpdateGroupBirthdayParties(userData.facilityId, group.id, birthdayPartyIds, updateType);
        if (response && response.status === 200 && response.data.status === 'success') {

            await getBirthdayParties(false, group.id);
            setOpenAlert({ type: 'grouping_successful', message: "Birthday Party Group Updated", buttonText: "OK" });
            setSelectedParties([]);
        } else {
            setOpenAlert({ type: 'update_error', message: "Error - The group was NOT updated.", buttonText: "" });
        }
        setIsLoading(false);

    }

    const selectParty = (id) => {
        setSelectedPartyId(id);
    }

    const clearSelectedRows = () => {
        setSelectedParties([])
    }

    const edit = async () => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage(`Another user is currently editing the reservation settings.`);
            }
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const editDetails = () => {
        setShowGroupDetails(true);
    }

    const copy = async () => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'min_copies_reached', message: "Please select at least 1 party to copy.", buttonText: "" });
            return;
        }

        if (selectedParties.length > 5) {
            setOpenAlert({ type: 'max_reached', message: "Only 5 Copies Are Allowed At A Time", buttonText: "Clear" });
        } else {
            setIsLoading(true);
            const response = await CopyBirthdayParties(userData.facilityId, selectedParties);
            if (response && response.status === 200 && response.data.status === 'success') {

                const newBirthdayPartyArray = birthdayParties.concat(response.data.newBirthdayParties);
                const sortedParties = newBirthdayPartyArray.sort((party1, party2) => {
                    if (party1.name < party2.name) {
                        return -1;
                    }

                    if (party1.name > party2.name) {
                        return 1;
                    }

                    return 0;
                });
                setBirthdayParties(sortedParties);
                setSelectedParties([]);
                setOpenAlert({ type: 'copy_successful', message: "Birthday Parties Copied", buttonText: "" });
            } else {
                setOpenAlert({ type: 'copy_error', message: "Copy Error", buttonText: "" });
            }
            setIsLoading(false);
        }
    }

    const updateStatus = async (status, birthdayParties, isGroup = false) => {

        if (birthdayParties.length <= 0) {
            setOpenAlert({ type: 'needs_selection', message: "Please select at least 1 party to update.", buttonText: "" });
            return;
        }

        setIsLoading(true);
        const response = await UpdateBirthdayPartiesStatus(userData.facilityId, birthdayParties, status, isGroup);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'status_update_successful', message: "Status Updated", buttonText: "" });
            getBirthdayParties(false, group.id);
            clearSelectedRows();
        } else {
            setOpenAlert({ type: 'status_update_error', message: "Status Update Error", buttonText: "" });
            setIsLoading(false);
        }
    }

    const inActivate = () => {
        updateStatus('inactive', selectedParties, false);
    }

    const activate = () => {
        updateStatus('active', selectedParties, false);
    }

    const cancelEdit = () => {
        removeEditLock(editLockName, userData);
        setIsEditMode(false);
    }

    const closeAlertMessage = () => {
        setAlertMessage(null);
        setOpenAlert(null);
    }

    const closeRemoveDialog = () => {
        setShowRemoveDialog(false);
    }

    const cancelAddingParty = () => {
        setShowAddPartyDialog(false);
    }

    const addExistingParty = () => {
        setShowAddPartyDialog(false);
        setAlertMessage({
            title: 'Add Party To This Group',
            message: 'To add an existing party to this package, scroll "Single Parties" and use the Group function'
        });
    }

    const addNewParty = () => {
        setShowAddPartyDialog(false);
        setAddingPartyToGroup(true);
    }

    const closeViewBookings = (refresh) => {
        setViewSignupsId(null);
        if (refresh) {
            getBirthdayParties(false, group.id);
        }
    }

    const closeSelectedParty = () => {
        setSelectedPartyId(null);
        getBirthdayParties(false, group.id);
    }

    const closeSetup = () => {
        setShowGroupDetails(false);
    }

    const togglePartyStatus = () => {

        setIsLoading(true)
        updateStatus(!groupStatus || groupStatus === 'inactive' ? 'active' : 'inactive', [group.id], true);
        setIsLoading(false);
    }

    return (
        <Stack>
            {
                alertMessage &&
                <MySportSpaceAlert isOpen={true} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            }
            <MySportSpaceSnackbarAlert isOpen={openAlert ? true : false} close={closeAlertMessage} message={openAlert ? openAlert.message : ""} buttonText={openAlert ? openAlert.buttonText : ''} action={openAlert && openAlert.type === 'max_reached' ? clearSelectedRows : closeAlertMessage} />
            <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '10%', bottom: '1%', left: '1%', right: '1%' }} open={showGroupDetails}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <BirthdayPartyGroupSetup close={closeSetup} selectedGroup={group}></BirthdayPartyGroupSetup>
                </Paper>
            </Modal>
            <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '1%', bottom: '1%', left: '1%', right: '1%' }} open={addingPartyToGroup}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <BirthdayPartySetup key={'birthday-party-create-setup-modal'} close={closeAddingPartyToGroup} isModal={true} birthdayGroupId={group.id}  ></BirthdayPartySetup>
                </Paper>
            </Modal>
            <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '10%', bottom: '1%', left: '5%', right: '5%' }} open={selectedPartyId ? true : false}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <BirthdayPartySetup key={'birthday-party-setup-modal'} birthdayPartyId={selectedPartyId} close={closeSelectedParty} isModal={true} ></BirthdayPartySetup>
                </Paper>
            </Modal>
            <Modal sx={{ position: 'absolute', top: '10%', bottom: '-10%', left: '10%', right: '10%' }} open={viewSignupsId ? true : false}>
                <BirthdayPartyBookings close={closeViewBookings} birthdayPartyId={viewSignupsId} ></BirthdayPartyBookings>
            </Modal>
            <Dialog
                open={showRemoveDialog}
                onClose={closeRemoveDialog}
            >
                <DialogTitle>Remove From Group?</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        These birthday parties will be removed from the group.  Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeRemoveDialog}>No</Button>
                    <Button onClick={removeParties} variant="contained">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showAddPartyDialog}
                onClose={closeRemoveDialog}
            >
                <DialogTitle>Add Party To Group</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        How would you like to add a party to this group?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelAddingParty}>Cancel</Button>
                    <Button onClick={addNewParty} variant="contained">New Party</Button>
                    <Button onClick={addExistingParty} variant="contained">Existing Party</Button>
                </DialogActions>
            </Dialog>
            <Box mt={1} mb={2} sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                    <Stack width={'20%'} direction={'row'}>
                        {
                            isEditMode &&
                            <Tooltip title="Toggle the party package's status">
                                <IconButton onClick={(e) => togglePartyStatus(e)}>
                                    {
                                        group.status.toUpperCase() === 'ACTIVE' &&
                                        <ToggleOnOutlinedIcon></ToggleOnOutlinedIcon>
                                    }
                                    {
                                        group.status.toUpperCase() === 'INACTIVE' &&
                                        <ToggleOffOutlinedIcon></ToggleOffOutlinedIcon>
                                    }

                                </IconButton>
                            </Tooltip>
                        }

                    </Stack>
                    <Stack width={'80%'} spacing={1} direction={'row'} justifyContent={'flex-end'}>
                        {
                            !isEditMode &&
                            <Stack spacing={1} direction={'row'}>
                                <Tooltip title="View or Edit the group details">
                                    <IconButton onClick={(e) => editDetails(e)}>
                                        <EditOutlinedIcon></EditOutlinedIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Group Actions">
                                    <IconButton onClick={(e) => edit(e)}>
                                        <SettingsOutlinedIcon></SettingsOutlinedIcon>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }
                        {
                            isEditMode &&
                            <Fragment>
                                <Tooltip title="Add a party to this package">
                                    <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} disabled={isLoading} size="small" startIcon={<AddOutlinedIcon />} id={'add-button'} onClick={showTheAddToPartyDialog} variant="contained" >Add</Button>
                                </Tooltip>
                                {
                                    birthdayParties.length > 0 &&
                                    <Fragment>
                                        <Tooltip title="Remove parties from this group">
                                            <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} disabled={isLoading} size="small" startIcon={<GroupWorkOutlinedIcon />} id={'remove-button'} onClick={removePartiesFromGroup} variant="contained" >Remove</Button>
                                        </Tooltip>
                                        <Tooltip title="Copy parties in this group">
                                            <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} disabled={isLoading} size="small" startIcon={<ContentCopyOutlinedIcon />} id={'copy-button'} onClick={copy} variant="contained" >Copy</Button>
                                        </Tooltip>
                                        <Tooltip title="Active parties in this group">
                                            <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} disabled={isLoading} size="small" startIcon={<ToggleOffOutlinedIcon />} id={'inactive-button'} onClick={inActivate} variant="contained" >Inactive</Button>
                                        </Tooltip>
                                        <Tooltip title="Inactivate parties in this group">
                                            <Button sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} disabled={isLoading} size="small" startIcon={<ToggleOnOutlinedIcon />} id={'activate-button'} onClick={activate} variant="contained" >Activate</Button>
                                        </Tooltip>
                                    </Fragment>
                                }
                                <Tooltip title="Stop editing this group">
                                    <Button disabled={isLoading} size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancelEdit} variant="contained" >Cancel</Button>
                                </Tooltip>
                            </Fragment>
                        }
                    </Stack>
                </Box>
            </Box >

            <Box sx={{ width: '100%', height: 340 }}>
                <DataGrid sx={{
                    font: 'Helvetica', color: '#14254C',
                    fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                }} getRowId={getRowId} rowCount={1} initialState={{ pagination: paginationModel }} pageSizeOptions={[100]} onPaginationModelChange={setPaginationModel} paginationMode="server"
                    rows={birthdayParties} getRowHeight={() => 'auto'} loading={isLoading} columns={columns} checkboxSelection={isEditMode} disableRowSelectionOnClick={true} rowSelectionModel={selectedParties} onRowSelectionModelChange={(ids) => {
                        if (!isEditMode) {
                            selectParty(ids);
                        } else {
                            setSelectedParties(ids)
                        }
                    }}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
            </Box>
        </Stack >
    )

}

export default BirthdayGroupPartiesTable;