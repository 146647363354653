import { Stack, Typography, Box, Divider, Checkbox, FormControlLabel } from "@mui/material";
import BirthdayUngroupedPartiesTable from "./birthday-ungrouped-parties-table.component";
import BirthdayPartyListGroups from "./birthday-party-list-groups.component";
import { useState } from "react";


const BirthdayPartyManageAll = () => {

    const [showActive, setShowActive] = useState(true);
    const [showInactive, setShowInactive] = useState(true);

    const toggleShowInactive = () => {
        setShowInactive(!showInactive);
    }

    const toggleShowActive = () => {
        setShowActive(!showActive);
    }

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'85px'} left={'0%'} right={'0%'} bottom={'1%'} display={'flex'}>
            <Stack ml={2} width={'100%'}>
                <Stack width={'100%'} direction={'row'}>
                    <Typography sx={{ width: '75%' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={3}>
                        Manage Birthday Parties
                    </Typography>
                </Stack>
                <Divider variant="middle" sx={{ opacity: '0.6' }}></Divider>
                <Stack alignItems={'center'}>
                    <Stack width={'90%'} mt={3} mb={2} spcing={1} display={'flex'}  >
                        <Stack mb={2}>
                            <Stack width={'100%'} direction={'row'} alignItems={'center'}>
                                <Typography width={'60%'} fontSize={'1.40vw'} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'}>
                                    Party Packages
                                </Typography>
                                <Stack width={'40%'} direction={'row'}>
                                    <FormControlLabel control={<Checkbox checked={showInactive} onChange={toggleShowInactive} />} label="Show Inactive Packages" />
                                    <FormControlLabel control={<Checkbox checked={showActive} onChange={toggleShowActive} />} label="Show Active Packages" />
                                </Stack>
                            </Stack>
                            <Typography ml={2} fontSize={'1.0vw'} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'}>
                                To create a party package, create single parties and then group them together
                            </Typography>
                        </Stack>
                        <Divider sx={{ opacity: '0.6' }} variant="middle"></Divider>
                        <BirthdayPartyListGroups showActive={showActive} showInactive={showInactive}></BirthdayPartyListGroups>
                    </Stack>

                    <Divider></Divider>
                    <Stack width={'90%'} mt={3} spcing={1} display={'flex'}>
                        <Typography fontSize={'1.40vw'} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} mb={2}>
                            Single Parties
                        </Typography>
                        <Divider sx={{ opacity: '0.6' }} variant="middle"></Divider>
                        <Box>
                            <BirthdayUngroupedPartiesTable></BirthdayUngroupedPartiesTable>
                        </Box>
                    </Stack>
                </Stack>

            </Stack>
        </Box>
    )
}

export default BirthdayPartyManageAll;