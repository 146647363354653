import { BookingsConfirmationContainer } from './booking-confirmation.styles';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/user.context';
import mssLogo from '../../../assets/logo-01.png';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Stack, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Decimal = require('decimal.js');
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const logoImageStyle = {
    margin: "auto",
    marginLeft: '0.5vw',
    width: '12vw',
    height: '9vw',
    borderRadius: '5px',
}

const defaultBookingTotals = {
    totalWithService: new Decimal(0),
    totalWithoutService: new Decimal(0),
    totalService: new Decimal(0),
}

const getServiceCharge = (booking) => {
    let serviceCharge = '';
    if (booking.charges) {
        for (const charge of booking.charges) {
            const { type, amount } = charge;
            if (type === 'serviceCharge') {
                let serviceDecimal = new Decimal(amount).div(100.0);
                serviceDecimal = serviceDecimal.toDecimalPlaces(2);
                serviceCharge = `$${serviceDecimal.toFixed(2)}`;
            }
        }
    }

    return serviceCharge;
}

const BookingsConfirmation = ({ reservationsBooked, title, backToSearchUrl }) => {

    const { userData } = useContext(UserContext);
    const [bookingsByDate, setBookingsByDate] = useState({});
    const [bookingTotals, setBookingTotals] = useState(defaultBookingTotals);

    useEffect(() => {

        window.scrollTo(0, 0);

        const bookingsByDate = {};
        let totalWithService = new Decimal(0);
        let totalWithoutService = new Decimal(0);
        let totalService = new Decimal(0);
        for (const result of reservationsBooked) {
            const startKey = moment(result.reservationDate, "YYYY-MM-DD").format('dddd, MMM DD, YYYY');
            let bookingsForDateKey = bookingsByDate[startKey];
            if (!bookingsForDateKey) {
                bookingsForDateKey = [];
            }
            bookingsForDateKey.push(result);

            bookingsByDate[startKey] = bookingsForDateKey;

            totalWithService = totalWithService.plus(new Decimal(result.price));
            totalWithoutService = totalWithoutService.plus(new Decimal(result.price));
            if (result.charges) {
                for (const charge of result.charges) {
                    const { type, amount } = charge;
                    if (type === 'serviceCharge') {
                        let serviceDecimal = new Decimal(amount).div(100.0);
                        totalWithService = totalWithService.plus(serviceDecimal);
                        totalService = totalService.plus(serviceDecimal);
                    }
                }
            }
        }

        const bookingTotals = {
            totalWithService: totalWithService,
            totalWithoutService: totalWithoutService,
            totalService: totalService,
        }
        setBookingTotals(bookingTotals);
        setBookingsByDate(bookingsByDate);

    }, []);

    const navigate = useNavigate();

    const backToReservationSearch = () => {

        if (backToSearchUrl) {
            navigate(backToSearchUrl);
        } else {
            navigate('/');
        }

    }

    return (
        <Box display={'flex'} flexDirection={'column'} marginTop={'1.5vh'} marginBottom={'1.5vh'}>
            <Box mb={2} display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}>
                <Button onClick={backToReservationSearch} startIcon={<ArrowBackIcon />}>
                    Back To Search
                </Button>
            </Box>
            <Stack spacing={2} ml={{xs: 5, sm: 5, md: 15}} mr={{xs: 5, sm: 5, md: 15}}>
                <Stack spacing={1}>
                    <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>Thank you for booking with My Sport Space</Typography>
                    <Typography fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>An email confirmation has been sent to {userData ? userData.userEmailAddress : "your email address."}</Typography>
                </Stack>
                <Stack>
                    <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>Reservation Title:</Typography>
                    <Typography fontSize={{ xs: '3.25vw', sm: '3.35vw', md: '1.0vw' }}>{title}</Typography>
                </Stack>
                <Divider></Divider>
                <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} display={'flex'} justifyContent={'space-between'}>
                    <Stack display={'flex'} spacing={1}>
                        <Typography fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>* Reservations within 48 hours of the reservation will be charged immediately.</Typography>
                        <Typography fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>* Reservations outside of 48 hours will be charged 48 hours prior to the reservation.</Typography>
                        <Typography fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>* For invoice customers, your invoice will be updated.</Typography>
                        <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>* All cancelations are subject to any applicable cancellation and/or service fees</Typography>
                    </Stack>
                    <Stack mt={{ xs: 1, sm: 1, md: 0 }} width={{ xs: '100%', sm: '100%', md: '40%' }} display={'flex'} alignContent={'flex-start'}>
                        <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>Reservations(s) Total</Typography>
                        <Typography textAlign={'right'} fontSize={{ xs: '3.25vw', sm: '2.95vw', md: '1.0vw' }} >${bookingTotals.totalWithoutService.toFixed(2)}</Typography>
                        <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>Total Service Charges</Typography>
                        <Typography textAlign={'right'} fontSize={{ xs: '3.25vw', sm: '2.95vw', md: '1.0vw' }} >${bookingTotals.totalService.toFixed(2)}</Typography>
                        <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>Total</Typography>
                        <Divider></Divider>
                        <Typography textAlign={'right'} fontSize={{ xs: '3.25vw', sm: '2.95vw', md: '1.0vw' }} >${bookingTotals.totalWithService.toFixed(2)}</Typography>
                    </Stack>
                </Stack>
                <Divider sx={{ opacity: '0.60' }}></Divider>

                <Stack>
                    {Object.keys(bookingsByDate).length > 0 &&
                        <Stack>
                            {
                                Object.keys(bookingsByDate).map((date) => {
                                    return <Stack spacing={1} key={date}>
                                        <Typography fontWeight={'bold'} fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>{date}</Typography>
                                        <Divider></Divider>
                                        {
                                            bookingsByDate[date].map((booking) => {
                                                return <Stack spacing={5} key={booking.reservationId} direction={'row'} >
                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <img style={logoImageStyle} src={booking.areaImageUrl ? booking.areaImageUrl : mssLogo} alt="Logo" />
                                                    </Box>
                                                    <Stack spacing={2} direction={{xs: 'column', sm: 'column', md: 'row'}}>
                                                        <Stack justifyContent={'flex-start'}>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '3.05vw', sm: '3.25vw', md: '1.0vw' }}>{booking.facilityName}</Typography>
                                                            <Typography fontSize={{ xs: '3.0vw', sm: '3.25vw', md: '1.0vw' }}>{booking.areaReserved}</Typography>
                                                            <Typography fontSize={{ xs: '3.0vw', sm: '3.25vw', md: '1.0vw' }}>{booking.facilityAreaType}</Typography>
                                                            <Typography fontSize={{ xs: '3.0vw', sm: '3.25vw', md: '1.0vw' }}>{booking.reservedForSport}</Typography>
                                                            <Typography fontSize={{ xs: '3.0vw', sm: '3.25vw', md: '1.0vw' }}>{moment(`${booking.reservationDate} ${booking.startTime}`, "YYYY-MM-DD h:mm a").format("h:mm a")} - {moment(`${booking.reservationDate} ${booking.endTime}`, "YYYY-MM-DD h:mm a").format("h:mm a")}</Typography>
                                                            {new Decimal(booking.price).gt(new Decimal(0)) &&
                                                                <Stack>
                                                                    <Typography fontWeight={'bold'} fontSize={{ xs: '3.05vw', sm: '3.25vw', md: '1.0vw' }}>Cancel Policy</Typography>
                                                                    {
                                                                        booking.cancelPolicies.map((policy) => {
                                                                            return <Typography fontSize={{ xs: '3.25vw', sm: '3.25vw', md: '1.0vw' }}>{policy}</Typography>
                                                                        })
                                                                    }
                                                                </Stack>
                                                            }

                                                        </Stack>
                                                        <Stack display={'flex'} >
                                                            <Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
                                                                <Typography fontWeight={'bold'} fontSize={{ xs: '3.05vw', sm: '3.25vw', md: '1.0vw' }}>Price</Typography>
                                                                <Typography textAlign={'right'} fontSize={{ xs: '3.0vw', sm: '3.25vw', md: '1.0vw' }}>${new Decimal(booking.price).toFixed(2)}</Typography>
                                                            </Stack>
                                                            <Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
                                                                <Typography fontWeight={'bold'} fontSize={{ xs: '3.05vw', sm: '3.25vw', md: '1.0vw' }}>Service Charge</Typography>
                                                                <Typography textAlign={'right'} fontSize={{ xs: '3.0vw', sm: '3.25vw', md: '1.0vw' }}>{getServiceCharge(booking)}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            })
                                        }
                                        <Divider sx={{ opacity: '0.60' }}></Divider>
                                    </Stack>
                                })

                            }
                        </Stack>
                    }
                </Stack>
            </Stack >
        </Box >
    )
}

export default BookingsConfirmation;