import { Box, Typography, Stack, Button, CircularProgress, Modal, Paper } from "@mui/material";
import FacilityEventsListCard from "./facility-events-list-card.component";
import FacilityEventsSearch from "./facility-events-search.component";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useState, useEffect, useContext, Fragment } from "react";
import FacilityEventSearchFilter from "./facility-event-search-filter.component";
import { FacilityEventSearchContext } from "../../../contexts/facility-events-search/facility-event-search.context";
import { SearchForFacilityEvents } from "../../../utils/facility-events/facility-events-service";
import dayjs from "dayjs";
import FacilityEventDetail from "./facility-event-detail.component";
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const FacilityEventsListEventsWithSearch = ({ filterType, backToUrl, facilityId }) => {

    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setSearchResults, searchResults } = useContext(FacilityEventSearchContext);
    const [viewEventId, setViewEventId] = useState(null);

    const fetchInitialSearcResults = async (filterType) => {
        if (filterType) {
            setIsLoading(true);
            const response = await SearchForFacilityEvents({ eventType: filterType, startDate: dayjs().format("YYYY-MM-DD"), facilityId });
            if (response && response.data && response.data.searchResults) {
                const sortedSearchResults = response.data.searchResults.sort((result1, result2) => {

                   const result1StartsDate = dayjs.tz(dayjs(`${result1.eventStartsDate} ${result1.timeStart ? result1.timeStart : '12:00 AM'}`, 'YYYY-MM-DD h:mm A'), result1.timeZone);
                   const result2StartsDate = dayjs.tz(dayjs(`${result2.eventStartsDate} ${result2.timeStart ? result2.timeStart : '12:00 AM'}`, 'YYYY-MM-DD h:mm A'), result2.timeZone);
                    if (result1StartsDate.isBefore(result2StartsDate)) {
                        return -1;
                    }
        
                    if (result1StartsDate.isAfter(result2StartsDate)) {
                        return 1;
                    }
        
                    return 0;
                });

                setSearchResults(sortedSearchResults);
            }
            setIsLoading(false);
        } else {
            setSearchResults([]);
        }
    }
    useEffect(() => {

        fetchInitialSearcResults(filterType);

        return () => {
            setSearchResults([]);
        }

    }, [filterType]);

    const toggleFilter = (event) => {
        if (popoverAnchorEl) {
            setPopOverAnchorEl(null);
        } else {
            setPopOverAnchorEl(event.currentTarget);
        }
    }
    const closeFilter = () => {
        setPopOverAnchorEl(null);
    }

    const viewEvent = (id) => {
        setViewEventId(id);
    }

    const closeViewEvent = () => {
        setViewEventId(null);
    }

    return (
        <Stack sx={{ width: '100%', overflowY: {xs: 'visible', sm:'visible', md:'scroll'} }} display={'flex'} ml={1} mr={1} spacing={1}>
            <Modal sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '10%', bottom: '1%', left: '5%', right: '5%' }} open={viewEventId ? true : false}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <FacilityEventDetail backToUrl={backToUrl} close={closeViewEvent} facilityEventId={viewEventId}></FacilityEventDetail>
                </Paper>
            </Modal >
            {
                isLoading &&
                <Box display={'flex'} justifyContent={'center'}>
                    <CircularProgress sx={{ marginTop: '4.0vh', color: '#14254C' }} />
                </Box>
            }
            {
                !isLoading &&
                <Fragment>
                    <Box>
                        <FacilityEventsSearch filterType={filterType} facilityId={facilityId}></FacilityEventsSearch>
                    </Box>
                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                        <Typography paddingLeft={1} paddingRight={1} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} component="div">
                            Showing {searchResults.length} Results
                        </Typography>
                        <Button sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}  variant="contained" endIcon={popoverAnchorEl ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />} onClick={toggleFilter}>
                            Filter
                        </Button>
                        <FacilityEventSearchFilter isOpen={popoverAnchorEl ? true : false} close={closeFilter} popoverAnchorEl={popoverAnchorEl}></FacilityEventSearchFilter>
                    </Stack>
                </Fragment>
            }
            {
                !isLoading &&
                <Box display={'flex'} justifyContent={'center'}>
                    <Stack sx={{ width: '98%', height: '150%'}} spacing={1} >
                        {
                            searchResults && searchResults.map((result) => {
                                return <FacilityEventsListCard backToUrl={backToUrl} key={result.id} searchResult={result} ></FacilityEventsListCard>
                            })
                        }
                    </Stack>
                </Box>
            }
        </Stack >
    )
}

export default FacilityEventsListEventsWithSearch;