import { IconButton, Box, Stack, Button, Avatar, Menu, MenuItem } from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useState, useContext } from "react";
import { UserContext } from "../../../contexts/user.context";
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import { signOutUser } from '../../../utils/firebase/firebase.utils';
import { DisplayContext } from '../../../contexts/dispaly-options.context';
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';


function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "#14254C",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const CustomerAccountMenu = () => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { currentUser, userData, setCurrentUser } = useContext(UserContext);
    const { clearReservationData } = useContext(FacilityReservationsContext)
    const { resetAllDisplays } = useContext(DisplayContext);
    const { reset } = useContext(SearchForReservationContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeAccountMenu = () => {
        setAnchorEl(null);
    }

    const signOut = async () => {
        setAnchorEl(null);
        await signOutUser();
        setCurrentUser(null, null);
        clearReservationData();
        resetAllDisplays();
        reset();
        navigate("/");
    }

    const goToHelp = () => {
        setAnchorEl(null);
        window.location.href = "https://www.mysportspace.info/how-it-works.html";
    }

    const goToContact = () => {
        setAnchorEl(null);
        window.location.href = "https://www.mysportspace.info/contact.html";
    }

    const goToScheduleDemo = () => {
        setAnchorEl(null);
        window.location.href = "https://www.mysportspace.info/facility-infodemo.html";
    }

    const goToSignIn = () => {
        setAnchorEl(null);
        navigate("/auth");
    }

    const menuOpen = Boolean(anchorEl);
    return (
        <Box>
            {currentUser && userData &&
                <Stack sx={{ border: 'solid 1px gray', borderRadius: '5px' }} direction={'row'}>
                    <IconButton sx={{ fontSize: { xs: '1.25vh', sm: '1.1vh', xl: '1.75vh' } }} onClick={handleClick}>
                        <MenuIcon></MenuIcon>
                    </IconButton>
                    <Button size="small" sx={{ fontSize: { xs: '1.25vh', sm: '1.1vh', xl: '1.25vh' } }} onClick={handleClick}>
                        <Avatar {...stringAvatar(`${userData.userFirstName} ${userData.userLastName}`)} />
                    </Button>
                </Stack>
            }
            {
                !currentUser && !userData &&
                <Stack sx={{ border: 'solid 1px gray', borderRadius: '5px' }} direction={'row'}>
                    <IconButton sx={{ fontSize: { xs: '1.25vh', sm: '1.1vh', xl: '1.75vh' } }} onClick={handleClick}>
                        <MenuIcon></MenuIcon>
                    </IconButton>
                    <IconButton sx={{ fontSize: { xs: '1.25vh', sm: '1.1vh', xl: '1.75vh' } }} onClick={handleClick}>
                        <AccountCircleOutlinedIcon></AccountCircleOutlinedIcon>
                    </IconButton>
                </Stack>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={closeAccountMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    currentUser && userData &&
                    <MenuItem onClick={signOut}>Sign Out</MenuItem>
                }
                {
                    !currentUser && !userData &&
                    <MenuItem onClick={goToSignIn}>Sign In</MenuItem>
                }

                <MenuItem onClick={goToHelp}>Help</MenuItem>
                <MenuItem onClick={goToContact}>Contact</MenuItem>
                <MenuItem onClick={goToScheduleDemo}>Schedule a Facility Demo</MenuItem>
            </Menu>
        </Box>
    )
}

export default CustomerAccountMenu;