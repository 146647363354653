import { CustomerSignInOrSignupContainer } from './sign-in-or-sign-up.styles';
import SignInForm from '../sign-in-form/sign-in-form.component';
import CustomerSignup from '../customer/customer-sign-up/customer-sign-up.component';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { Fragment, useContext } from 'react';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import { Box, Stack } from '@mui/material';

const SignInOrSignUp = ({ props }) => {

    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl');
    const { currentUser, userIsLoading } = useContext(UserContext);

    return (

        <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '80%', width: '80%', margin: 'auto' }}>
            {
                userIsLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!userIsLoading && !currentUser &&
                <Stack display={'flex'} justifyContent={'center'} width={'100%'} spacing={{xs: 0, sm: 0, md: 5}} direction={{ xs: 'column-reverse', sm: 'row' }}>
                    <Box width={{xs: '95%', sm: '45%'}}>
                        <CustomerSignup navigateLink={redirectUrl}></CustomerSignup>
                    </Box>
                    <Box width={{xs: '95%', sm: '35%'}}>
                        <SignInForm navigateLink={redirectUrl}></SignInForm>
                    </Box>
                </Stack>
            }
        </Box>

    )
}

export default SignInOrSignUp;