import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Stack, CardMedia, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FacilityBirthdayDetailsCard = ({ birthdayParty, facilityName }) => {

    const { id, groupId, groupName, name = "", price, imageLocationURL, searchTerm, facilityId } = birthdayParty;

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        // navigate(`/birthday-parties/${facilityId}/${searchTerm ? searchTerm : id}`);
        if (groupId) {
            navigate(`/birthday-parties/${facilityId}?scrollToId=${groupId}`);
        } else {
            navigate(`/birthday-parties/${facilityId}?scrollToId=${id}`);
        }

    }
    return (
        <Card onClick={handleClick} variant='none' sx={{
            width: { xs: "100%", sm: '100%', md: "25vw" },
            display: "flex", flexDirection: { xs: 'column', sm: 'column', md: 'row' }, alignItems: 'center'
        }}>
            <CardMedia
                component="img"
                image={imageLocationURL ? imageLocationURL : "/birthday-party-table-image.webp"}
                alt="facility photo"
                sx={{
                    "&.MuiCardMedia-img": {
                        width: { xs: "10vw", sm: '10vw', md: "5vw" },
                        height: { xs: "10vw", sm: '10vw', md: "5vw" },
                        borderRadius: '5px',
                        aspectRatio: '16/9'
                    },
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}
            />
            <Stack sx={{borderRadius: '5px', cursor: "pointer", ":hover": { boxShadow: "0 3px 10px #14254C" }}} m={1} width={'100%'} display={'flex'} justifyContent={'space-between'}  >
                <Typography mb={1} sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} textAlign={'center'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                    {groupName && name ? groupName : name}
                </Typography>
                <Divider sx={{ opacity: 0.5 }} variant='middle'></Divider>
                <Stack mt={1} spacing={2} height={'100%'} display={'flex'} justifyContent={'space-around'} direction={'row'} alignItems={'center'}>
                    <Box display={'flex'}>
                        {
                            groupName &&
                            <Typography mr={1} sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.25vw' } }} color={"gray"} textAlign={'right'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                                Starting At
                            </Typography>
                        }

                        <Typography sx={{ fontSize: { xs: '2.75vw', sm: '2.75vw', md: '1.25vw' } }} textAlign={'right'} fontStyle={'Helvetica'} fontWeight={'bold'}>
                            {`$${price}`}
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
        </Card>
    )

}

export default FacilityBirthdayDetailsCard;