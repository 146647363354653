import { Typography, Card, CardContent, Stack } from '@mui/material';

const FacilityViewCustomerBirthdayPartyRow = ({ birthdayParty, viewBirthdayParty }) => {

    const viewParty = () => {
        if (viewBirthdayParty) {
            viewBirthdayParty(birthdayParty);
        }
    }

    const { birthdayPartyName, startTime, endTime, partyDate, totalAmount, initialDeposit, depositData, reservationCharge, totalAmountDueAfterDeposit } = birthdayParty

    return (
        <Card onClick={viewParty} sx={{
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.5)', ':hover': {
                bgcolor: '#eaeaea',
                cursor: 'pointer'
            }
        }}>
            <CardContent>
                <Stack spacing={1}>
                    <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                        {birthdayPartyName}
                    </Typography>
                    <Typography variant="body1" color={"#14254C"}>
                        {partyDate} {startTime} - {endTime}
                    </Typography>
                    <Typography variant="body1" color={"#14254C"}>
                    Deposit ${initialDeposit} {depositData && depositData.paidStatus ? depositData.paidStatus.toUpperCase() : ""}
                    </Typography>
                    <Typography variant="body1" color={reservationCharge.paymentFailed ? "red" : "#14254C"}>
                        Total Amount Due ${totalAmountDueAfterDeposit} - {reservationCharge.paymentFailed ? 'PAYMENT FAILED' : reservationCharge.paidStatus.toUpperCase()}
                    </Typography>
                    <Typography variant="body1" color={"#14254C"}>
                        Total ${totalAmount}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default FacilityViewCustomerBirthdayPartyRow