import { Stack, Typography, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BottomButtonBar from "../../bottom-button-bar/bottom-button-bar.component";
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component";
import { DAYS_OF_WEEK_SORT_ORDER } from "../../../utils/constants/constants";
import dayjs from "dayjs";
import { useState } from "react";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component";

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);


const BirthdayPartyRescheduleDialog = ({ isOpen = false, bookedParty, birthdayPartyToReschedule = {}, closeDialog, reschedule }) => {

    const [rescheduleDate, setRescheduleDate] = useState(bookedParty && bookedParty.partyDate ? dayjs(bookedParty.partyDate, 'YYYY-MM-DD') : dayjs());
    const [timeSlotForReschedule, setTimeSlotForReschedule ] = useState(null);
    const [ rescheduleStartTime, setRescheduleStartTime ] =  useState(bookedParty && bookedParty.partyDate ? dayjs(`${bookedParty.partyDate} ${bookedParty.startTime}`, 'YYYY-MM-DD h:mm A') : dayjs().startOf('hour'));

    const performReschedule = () => {
        if (reschedule) {
            reschedule(rescheduleDate, timeSlotForReschedule, rescheduleStartTime);
        }
    }

    const close = () => {

        if (closeDialog) {
            closeDialog();
        }

        setRescheduleDate(bookedParty && bookedParty.partyDate ? dayjs(bookedParty.partyDate, 'YYYY-MM-DD') : dayjs());
        setTimeSlotForReschedule(null);
        setRescheduleStartTime(bookedParty && bookedParty.partyDate ? dayjs(`${bookedParty.partyDate} ${bookedParty.startTime}`, 'YYYY-MM-DD h:mm A') : dayjs().startOf('hour'));
    }

    const timeSlotChosen = (e) => {
        const timeSlotId = e.currentTarget.dataset.id;
        const foundTimeSlotById = timeSlots.filter((ts) => { return ts.id === timeSlotId });
        if (foundTimeSlotById.length > 0) {
            const foundTimeSlot = foundTimeSlotById[0];
            setTimeSlotForReschedule(foundTimeSlot);
        }
    }

    const selectRescheduleDate = (value) => {
        setTimeSlotForReschedule(null);
        setRescheduleDate(value);
    }

    const selectStartTime = (time) => {
        setRescheduleStartTime(time);
    }

    const { timeSlots = [], useTimeSlots } = birthdayPartyToReschedule ? birthdayPartyToReschedule.details : {};
    let filteredTimeSlots = timeSlots.filter((ts) => { return DAYS_OF_WEEK_SORT_ORDER[ts.dow] === rescheduleDate.day() });

    if (rescheduleDate && rescheduleDate.isSame(dayjs(bookedParty.partyDate, "YYYY-MM-DD"))) {
        filteredTimeSlots = filteredTimeSlots.filter((ts) => { return dayjs.tz(dayjs(ts.startTime), bookedParty.facilityTimeZone).format("h:mm A") !== bookedParty.startTime });
    }

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="reschedule-bdayparty-dialog-title"
            aria-describedby="reschedule-bdayparty-dialog-description"
        >
            <DialogTitle id="reschedule-bdayparty-dialog-title">
                Reschedule Birthday Party
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography mt={2} variant="body1">Select a Party Date And Time</Typography>
                    <MaterialUIDatePicker labelText="Birthday Party Date:" minDate={dayjs().startOf('day')} onChange={selectRescheduleDate} value={rescheduleDate}></MaterialUIDatePicker>
                    {
                        useTimeSlots && filteredTimeSlots.length > 0 &&
                        filteredTimeSlots.map((ts) => {
                            return <Button onClick={timeSlotChosen.bind(this)} data-id={ts.id} variant={timeSlotForReschedule && timeSlotForReschedule.id === ts.id ? "contained" : "outlined"}>{`${dayjs(ts.startTime).format("h:mm a")} - ${dayjs(ts.endTime).format("h:mm a")}`}</Button>
                        })
                    }
                    {
                        useTimeSlots && filteredTimeSlots.length <= 0 &&
                        <Typography ml={2} color={'secondary'} textAlign={'center'} mt={2} variant="body1">No Times Available</Typography>
                    }
                    {
                        !useTimeSlots &&
                        <MaterialUITimePicker headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} value={rescheduleStartTime} onChange={selectStartTime} />
                    }

                </Stack>

            </DialogContent>
            <DialogActions>
                <BottomButtonBar okButtonIsDisabled={useTimeSlots ? timeSlotForReschedule ? false : true : false} okButtonText={"Reschedule"} okAction={performReschedule} cancelButtonText={"Cancel"} cancelAction={close} mainColor={'#14254C'} secondaryColor={"gray"}></BottomButtonBar>
            </DialogActions>
        </Dialog>
    );
}

export default BirthdayPartyRescheduleDialog;