import { useContext, useState, Fragment, useEffect } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Stack, Box, Button, Typography, Divider, Grid, TextField, Paper, Popover, Menu, MenuItem, MenuList } from "@mui/material";
import Modal from '@mui/material/Modal';
import dayjs from "dayjs";
import Link from '@mui/material/Link';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { UserContext } from '../../../contexts/user.context';
import BirthdayPartyRescheduleDialog from './birthday-party-reschedule-dialog.component';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { GetBirthdayPartyDetails, MakedFailedBirthdayPayment, RescheduleBookedParty, UpdateBirthdayPartyFormOfPayment, CancelBirthdayParty, RefundInitialDeposit } from '../../../utils/birthday-api/birthday-service';
import { SearchForBirthdayParty } from '../../../utils/birthday-api/birthday-service';
import { buildReservationSearchRequest } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';
import BirthdayPartyUpdateFopDialog from './birthday-party-update-fop-dialog.component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const Decimal = require('decimal.js');

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '90%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    let matchWithOne = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matchWithOne) {
        return matchWithOne[1] + '(' + matchWithOne[2] + ') ' + matchWithOne[3] + '-' + matchWithOne[4]
    };

    return str
};


const BirthdayPartyViewBooked = ({ bookedParty = null, close }) => {

    const [editMenuAnchorEl, setEditMenuAnchorEl] = useState(null);
    const { userData, isAdmin } = useContext(UserContext);
    const [showReschedule, setShowReschedule] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [birthdayPartyDetails, setBirthdayPartyDetails] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [editMenuMessage, setEditMenuMessage] = useState(null);
    const [showUpdateFormOfPayment, setShowUpdateFormOfPayment] = useState(false);
    const [showMakeFailedPayment, setShowMakeFailedPayment] = useState(false);
    const [cancelTypeChosen, setCancelChosenType] = useState(null);
    const [cancelThisParty, setCancelThisParty] = useState(false);
    const [showRefundDeposit, setShowRefundDeposit] = useState(false);

    useEffect(() => {

        const alertForFailedPayment = async (reservationCharge) => {

            let failedMessage = "Payment has failed for this birthday party.";
            if (reservationCharge.failureCode) {
                switch (reservationCharge.failureCode) {
                    case 'card_declined':
                        failedMessage = 'The form of payment was declined.';
                        break;
                    case 'card_expired':
                        failedMessage = 'The form of payment has expired.';
                        break;
                    default:
                        failedMessage = "Payment has failed for this birthday party.";
                }
            }

            setAlertMessage({ title: "Payment Failed", message: failedMessage });
        }

        if (bookedParty && bookedParty.reservationCharge && bookedParty.reservationCharge.paymentFailed) {
            alertForFailedPayment(reservationCharge);
        }

    }, []);


    const closeView = () => {
        if (close) {
            close();
        }
    }

    const openEditMenu = (event) => {
        setEditMenuAnchorEl(event.currentTarget)
    }

    const closeEditMenu = () => {
        setEditMenuAnchorEl(null);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const rescheduleBirthdayParty = async (e) => {
        if (editMenuAnchorEl) {
            closeEditMenu();
        }

        if (!isAdmin) {
            alert('You are not authorized to reschedule a birthday party');
            return;
        }

        setIsLoading(true);
        const detailsResponse = await GetBirthdayPartyDetails(bookedParty.facilityId, bookedParty.birthdayPartyId);
        if (detailsResponse && detailsResponse.status == 200 && detailsResponse.data && detailsResponse.data.status === 'success') {
            setBirthdayPartyDetails(detailsResponse.data.birthdayParty);

            setShowReschedule(true);
        } else {
            setShowReschedule(false);
            setBirthdayPartyDetails(null);
        }
        setIsLoading(false);
    }

    const searchAndReschedule = async (rescheduleDate, timeSlot, rescheduleStartTime) => {
        setIsLoading(true);
        const reservationSearchData = {
            selectedSport: 'Birthday Party Package',
            startTime: rescheduleStartTime ? rescheduleStartTime : timeSlot ? dayjs(timeSlot.startTime) : dayjs(),
            endTime: rescheduleStartTime ? rescheduleStartTime : timeSlot ? dayjs(timeSlot.startTime) : dayjs(),
            reservationLength: birthdayPartyDetails.details.length,
            searchDate: rescheduleDate,
            customer: { userId: bookedParty.customerId },
            timeSlotId: timeSlot ? timeSlot.id : null
        }
        const searchRequest = buildReservationSearchRequest(reservationSearchData, null, bookedParty.facilityId, null, true);
        const response = await SearchForBirthdayParty(bookedParty.facilityId, bookedParty.birthdayPartyId, rescheduleDate.format("YYYY-MM-DD"), searchRequest, true);
        if (response && response.data && response.data.status === 'success') {

            const rescheduleResponse = await RescheduleBookedParty(response.data.summaryId, bookedParty.id, rescheduleDate.format("YYYY-MM-DD"));
            if (rescheduleResponse && rescheduleResponse.data && rescheduleResponse.data.status === 'success') {
                setEditMenuMessage({ message: 'The birthday party was rescheduled.' });
            } else {
                let message = "The birthday party reschedule was unsuccessful."
                setAlertMessage({ message: rescheduleResponse && rescheduleResponse.data.message ? rescheduleResponse.data.message : message });
            }

        } else {
            let message = "This birthday party is not available for this date/time. Please try another date/time."
            setAlertMessage({ message: response && response.data.message ? response.data.message : message });
        }

        setIsLoading(false);

    }

    const closeReschedule = () => {
        setShowReschedule(false);
        setBirthdayPartyDetails(null);
        setEditMenuMessage(null);
    }

    const closeAfterSucessfulEdit = () => {
        setShowReschedule(false);
        setBirthdayPartyDetails(null);
        setEditMenuMessage(null);

        if (close) {
            close(true);
        }
    }

    const openUpdateFormOfPayment = () => {
        if (editMenuAnchorEl) {
            closeEditMenu();
        }
        if (!isAdmin) {
            alert('You are not authorized to update the form of payment');
            return;
        }

        setShowUpdateFormOfPayment(true);
    }

    const closeUpdateFormOfPayment = () => {
        setShowUpdateFormOfPayment(false);
    }

    const updateTheFormOfPayment = async (formOfPayment) => {
        setShowUpdateFormOfPayment(false);
        setIsLoading(true);
        const updateFopResponse = await UpdateBirthdayPartyFormOfPayment(bookedParty.id, formOfPayment);
        if (updateFopResponse && updateFopResponse.data && updateFopResponse.data.status === 'success') {
            setEditMenuMessage({ message: "The birthday party's form of payment was updated" });
        } else {
            let message = "The birthday party's form of payment was NOT updated."
            setAlertMessage({ message: updateFopResponse && updateFopResponse.data.message ? updateFopResponse.data.message : message });
        }

        setIsLoading(false);
    }

    const makeFailedPayment = async (formOfPayment) => {
        setShowMakeFailedPayment(false);
        setIsLoading(true);
        const makePaymentResponse = await MakedFailedBirthdayPayment(bookedParty.id, formOfPayment);
        if (makePaymentResponse && makePaymentResponse.data && makePaymentResponse.data.status === 'success') {
            let message = makePaymentResponse.data.message ? makePaymentResponse.data.message : "The payment for the failed birthday party has been received.  The birthday party will be updated once the payment has been processed.";
            setEditMenuMessage({ message: message });
        } else {
            let message = "The birthday party payment failed."
            setAlertMessage({ message: makePaymentResponse && makePaymentResponse.data.message ? makePaymentResponse.data.message : message });
        }

        setIsLoading(false);
    }

    const openMakeFailedPayment = () => {
        if (editMenuAnchorEl) {
            closeEditMenu();
        }

        if (!isAdmin) {
            alert('You are not authorized to make a failed payment');
            return;
        }

        setShowMakeFailedPayment(true);
    }

    const closeMakeFailedPayment = () => {
        setShowMakeFailedPayment(false);
    }

    const applyPoliciesAndCancel = () => {
        cancelTheParty("apply_policies");
    }

    const refundDepositAndCancel = () => {
        cancelTheParty("refund_deposit");
    }

    const fullRefund = () => {
        cancelTheParty("full_refund");
    }

    const openCancelBooking = () => {
        if (editMenuAnchorEl) {
            closeEditMenu();
        }

        if (!isAdmin) {
            alert('You are not authorized to cancel a birthday party');
            return;
        }

        setCancelThisParty(true);
    }

    const closeCancelDialog = () => {
        setCancelThisParty(false);
        setCancelChosenType(null);
    }

    const cancelTheParty = async (cancelType) => {

        setIsLoading(true);
        const response = await CancelBirthdayParty(bookedParty.id, cancelType, userData.userId);
        if (response && response.status === 200) {
            const { message } = response.data;
            if (!response.data.status || response.data.status === 'success') {
                setEditMenuMessage({
                    title: 'Canceled',
                    message: message ? message : 'The birthday party has been canceled.',
                    close: true,
                    refresh: true
                }
                );
                closeCancelDialog();
            } else {
                if (message) {
                    setAlertMessage({
                        title: 'Cancel Error',
                        message: message
                    });
                } else {
                    setAlertMessage({
                        title: 'Cancel Error',
                        message: "There was an issue canceling this birthday party.  The birthday party could not be canceled."
                    });
                }
            }
        } else {
            setAlertMessage({
                title: 'Cancel Error',
                message: "There was an issue canceling this birthday party.  The birthday party could not be canceled."
            });
        }
        setIsLoading(false);
    }

    const openRefundDeposit = () => {

        if (editMenuAnchorEl) {
            closeEditMenu();
        }

        if (!isAdmin) {
            alert('You are not authorized to refund a birthday party deposit');
            return;
        }

        setShowRefundDeposit(true);
    }

    const closeRefundDeposit = () => {
        setShowRefundDeposit(false);
    }

    const refundTheDeposit = async () => {
        setShowRefundDeposit(false);
        setIsLoading(true);
        const response = await RefundInitialDeposit(userData.userId, bookedParty.id);
        if (response && response.status === 200) {
            const { message } = response.data;
            if (!response.data.status || response.data.status === 'success') {
                setEditMenuMessage({
                    title: 'Refunded',
                    message: message ? message : 'The birthday party deposit has been refunded.',
                    close: true,
                    refresh: true
                }
                );
                closeCancelDialog();
            } else {
                if (message) {
                    setAlertMessage({
                        title: 'Refund Error',
                        message: message
                    });
                } else {
                    setAlertMessage({
                        title: 'Refund Error',
                        message: "There was an issue refunding the deposit for this birthday party.  The birthday party deposit could not be refunded."
                    });
                }
            }
        } else {
            setAlertMessage({
                title: 'Refund Error',
                message: "There was an issue refunding the deposit for this birthday party.  The birthday party deposit could not be refunded."
            });
        }
        setIsLoading(false);
    }

    const { customer = {}, depositData = {}, initialDeposit = "0.00", receipts = [], ancillaryTotal = "0.00", serviceChargeTotal = "0.00", serviceChargeOnDeposit = "0.00", totalAmount = "0.00", totalAmountDueAfterDeposit = "0.00", refundableDeposit = false, acceptedAncillaries, birthdayPartyName, customFields = [], partyDate, startTime, endTime, reservationCharge = {}, cancelData = {}, paymentMethod, facilityTimeZone } = bookedParty ? bookedParty : {};
    const { userFirstName, userLastName, userEmailAddress, userPhoneNumber } = customer;
    const { cancelType, canceledOnUTC } = cancelData;

    const editMenuIsOpen = Boolean(editMenuAnchorEl);

    let computedDeposit = new Decimal(0.00);
    if (initialDeposit) {
        computedDeposit = new Decimal(initialDeposit);

        if (depositData.refundAmount) {
            const refundedAmount = new Decimal(depositData.refundAmount).dividedBy(100);
            computedDeposit = computedDeposit.minus(refundedAmount);
        }
    }

    return (
        <Modal open={bookedParty ? true : false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Paper sx={{ overflow: 'scroll' }}>
                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                <MySportSpaceAlert key={'facility-birthday-parties-alert'} isOpen={alertMessage ? true : false} title={alertMessage && alertMessage.title ? alertMessage.title : ""} message={alertMessage ? alertMessage.message : ""} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                {
                    editMenuMessage &&
                    <MySportSpaceAlert key={'facility-birthday-parties-edit-alert'} isOpen={true} title={editMenuMessage && editMenuMessage.title ? editMenuMessage.title : ""} message={editMenuMessage ? editMenuMessage.message : ""} okButtonText={'OK'} okAction={closeAfterSucessfulEdit} ></MySportSpaceAlert>
                }
                {
                    showReschedule && !isLoading && !alertMessage && !editMenuMessage &&
                    <BirthdayPartyRescheduleDialog isOpen={true} bookedParty={bookedParty} birthdayPartyToReschedule={birthdayPartyDetails} closeDialog={closeReschedule} reschedule={searchAndReschedule}></BirthdayPartyRescheduleDialog>
                }
                {
                    showUpdateFormOfPayment && !isLoading && !alertMessage && !editMenuMessage &&
                    <BirthdayPartyUpdateFopDialog isOpen={true} bookedParty={bookedParty} closeDialog={closeUpdateFormOfPayment} updateFormOfPayment={updateTheFormOfPayment}></BirthdayPartyUpdateFopDialog>
                }
                {
                    showMakeFailedPayment && !isLoading && !alertMessage && !editMenuMessage &&
                    <BirthdayPartyUpdateFopDialog isOpen={true} title={"Make Failed Payment"} bookedParty={bookedParty} closeDialog={closeMakeFailedPayment} updateFormOfPayment={makeFailedPayment}></BirthdayPartyUpdateFopDialog>
                }
                <Box sx={{ ...style, width: '80%' }}>
                    <Stack spacing={1} direction={'row'} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button onClick={openEditMenu} startIcon={<MenuOutlinedIcon />}>
                        </Button>

                        <Menu
                            id={'search-for-birthday-popover'}
                            open={editMenuIsOpen}
                            anchorEl={editMenuAnchorEl}
                            onClose={closeEditMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <MenuList sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }}>
                                {!canceledOnUTC && reservationCharge && !reservationCharge.paymentFailed &&
                                    <MenuItem divider={true} sx={{ color: "#14254C" }} onClick={rescheduleBirthdayParty}>Reschedule</MenuItem>
                                }
                                {
                                    !canceledOnUTC && reservationCharge && reservationCharge.paidStatus && reservationCharge.paidStatus.toUpperCase() === 'UNPAID' && !reservationCharge.paymentFailed &&
                                    <MenuItem divider={true} sx={{ color: "#14254C" }} onClick={openUpdateFormOfPayment}>Update Form Of Payment</MenuItem>
                                }
                                {!canceledOnUTC && reservationCharge && reservationCharge.paymentFailed &&
                                    <MenuItem divider={true} sx={{ color: "#14254C" }} onClick={openMakeFailedPayment}>Make Payment</MenuItem>
                                }
                                {
                                    !canceledOnUTC &&
                                    <MenuItem divider={true} sx={{ color: "#14254C" }} onClick={openCancelBooking}>Cancel Booking</MenuItem>
                                }
                                {
                                    depositData && depositData.paidStatus && depositData.paidStatus === 'paid' &&
                                    <MenuItem divider={true} sx={{ color: "#14254C" }} onClick={openRefundDeposit}>Refund Deposit</MenuItem>
                                }
                            </MenuList>
                        </Menu>

                        <Button onClick={closeView} startIcon={<CloseOutlinedIcon />}>
                        </Button>
                    </Stack>
                    <Box mb={1} mt={1} display="flex" justifyContent="flex-start" alignItems="center" >
                        <Typography ml={2} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h5">
                            {birthdayPartyName}
                        </Typography>
                        {
                            bookedParty && process.env.REACT_APP_FIREBASE_PROJECT_ID !== 'mysportspace-d8169' &&
                            <Typography ml={2} textAlign={'left'} gutterBottom color={"darkgray"} fontWeight={'bold'} fontSize={'1.5vw'}>
                                {bookedParty.id} {'*only shows in Qual'}
                            </Typography>
                        }
                    </Box>
                    <Stack>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                            Birthday Party Date and Time
                        </Typography>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="body1">
                            {dayjs(partyDate).format('dddd, MMM D, YYYY')} {startTime} {endTime}
                        </Typography>
                    </Stack>
                    <Stack>
                        {
                            canceledOnUTC &&
                            <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"red"} component="div" variant="body1">
                                Canceled On {dayjs.utc(canceledOnUTC, 'YYYY-MM-DD h:mm a').tz(dayjs.tz.guess()).format('dddd, MMM D, YYYY h:mm a')} - Method {cancelType === 'apply_policies' ? "Policies Applied" : cancelType === 'refund_deposit' ? "Deposit Refunded" : "Full Refund"}
                            </Typography>
                        }
                        <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                            Customer
                        </Typography>
                        <Box sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '100%' }} ml={5} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                {userFirstName} {userLastName}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '75%' }} ml={5} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                {userEmailAddress}
                            </Typography>
                            <Typography sx={{ width: '25%' }} textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                {formatPhoneNumber(userPhoneNumber)}
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                    <Box sx={{ width: '98%' }}>
                        <Typography ml={4} mt={1} mb={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                            Custom Fields
                        </Typography>
                        <Stack sx={{ marginLeft: '2.0vw' }} spacing={2}>
                            {
                                customFields && Array.isArray(customFields) && customFields.map((field) => {
                                    return <TextField key={field.id} InputProps={{ readOnly: true }} multiline fullWidth label={field.text} value={field.answer}></TextField>
                                })
                            }
                            {
                                !customFields || !Array.isArray(customFields) || customFields.length <= 0 &&
                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                    None
                                </Typography>

                            }
                        </Stack>
                    </Box>
                    <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                    <Box sx={{ width: '100%' }}>
                        <Grid container ml={2}>
                            <Typography ml={2} mt={1} mb={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                                Summary
                            </Typography>

                            {
                                acceptedAncillaries && acceptedAncillaries.map((anc) => {
                                    return <Box key={anc.id} sx={{ width: '95%' }} ><Grid container mt={2} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                {anc.name} - {anc.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box display="flex" justifyContent="center" alignItems="center" >
                                                <Typography textAlign={'center'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {anc.quantity}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${anc.price}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${anc.total}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                        <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                                    </Box>
                                })
                            }
                        </Grid>
                        <Box sx={{ width: '100%' }}>
                            <Grid container ml={2}>
                                <Box sx={{ width: '95%' }} >
                                    <Grid container mt={2} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Optional Items Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${ancillaryTotal}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Price
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${bookedParty ? bookedParty.price : 0.00}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Service Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${serviceChargeTotal}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4}>
                                        <Grid item xs={6}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${totalAmount}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Deposit - {`${depositData.paidStatus ? depositData.paidStatus.toUpperCase() : ''}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${computedDeposit.gt(new Decimal(0)) ? `(${computedDeposit.toFixed(2)})` : "0.00"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {depositData.refundAmount &&
                                        <Grid container mt={1} ml={4} mr={4}>
                                            <Grid item xs={5}>
                                                <Typography ml={5} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    Refund Amount - {`$${(depositData.refundAmount / 100.0).toFixed(2)} on ${dayjs.utc(depositData.refundedOnUTC, 'YYYY-MM-DD h:mm a').tz(facilityTimeZone).format('dddd, MMM D, YYYY h:mm a')}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                    <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                        {`$ (${bookedParty ? bookedParty.initialDeposit : 0.00})`}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={8}>
                                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    Deposit Service Charge - PAID
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {`$ (${serviceChargeOnDeposit})`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4}>
                                        <Grid item xs={6}>
                                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    {reservationCharge.paidStatus && reservationCharge.paidStatus.toUpperCase() === 'PAID' ? `Total After Deposit - PAID` : 'Total Amount Due'}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${totalAmountDueAfterDeposit}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                        paymentMethod && paymentMethod.card &&
                                        <Grid container mt={1} ml={4}>
                                            <Grid item xs={6}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    Payment Method
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {`${paymentMethod.card.brand.toUpperCase()} - ${paymentMethod.card.last4} ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        reservationCharge && reservationCharge.paymentFailed &&
                                        <Grid container mt={1} ml={4}>
                                            <Grid item xs={6}>
                                                <Typography textAlign={'left'} gutterBottom color={"red"} fontWeight={'bold'} component="div" variant="body1">
                                                    PAYMENT FAILED - ${(new Decimal(reservationCharge.amount).dividedBy(100)).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {dayjs(reservationCharge.paymentFailedOnUTC).format('dddd, MMM D, YYYY h:mm A')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        reservationCharge && reservationCharge.paidOnUTC &&
                                        <Grid container mt={1} ml={4}>
                                            <Grid item xs={6}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    Paid On
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {dayjs(reservationCharge.paidOnUTC).format('dddd, MMM D, YYYY h:mm A')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginRight: '2.0vw', marginLeft: '2.0vw', marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                        {
                            receipts && receipts.length > 0 &&
                            <Box sx={{ width: '100%' }}>
                                <Typography ml={4} mt={1} mb={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                                    Receipts
                                </Typography>
                                <Stack ml={5} spacing={1}>
                                    {
                                        receipts && receipts.map((receipt) => {
                                            return <Link key={receipt.type} href={receipt.receipt} underline='always'>
                                                {receipt.type}
                                            </Link>
                                        })
                                    }
                                </Stack>
                            </Box>
                        }
                    </Box>


                </Box>

                <Dialog
                    open={!isLoading && cancelThisParty && !alertMessage}
                    onClose={closeCancelDialog}
                >
                    <DialogTitle>Party Cancelation</DialogTitle>
                    <DialogContent>
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            Please choose the type of cancelation.
                        </DialogContentText>
                        <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={3} mt={1}>
                            Apply Policies
                        </Typography>
                        <Typography textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={4} mt={1} mb={1}>
                            This will apply your facilities cancelation policy to refund or charge for the cancelation.  Any refundable deposit will also be refunded.
                        </Typography>
                        <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={3} mt={1}>
                            Refund Deposit
                        </Typography>
                        <Typography textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={4} mt={1} mb={1}>
                            This will refund the deposit only.  This overrides the refundable flag set on the party.
                        </Typography>
                        <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={3} mt={1}>
                            Full Refund
                        </Typography>
                        <Typography textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={4} mt={1} mb={3}>
                            This will refund the deposit and any paid payments.
                        </Typography>
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            All cancelations are final and cannot be undone.  Any MSS application fee(s) will not be refunded.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={closeCancelDialog}>Don't Cancel</Button>
                        <Button size="small" onClick={applyPoliciesAndCancel} variant="contained">Apply Policies</Button>
                        <Button size="small" onClick={refundDepositAndCancel} variant="contained">Refund Deposit</Button>
                        <Button size="small" onClick={fullRefund} variant="contained">Full Refund</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!isLoading && !alertMessage && cancelTypeChosen ? true : false}
                    onClose={closeCancelDialog}
                >
                    <DialogTitle>Party Cancelation</DialogTitle>
                    <DialogContent>
                        {
                            cancelType && cancelType === 'apply_policies' &&
                            <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                                Your cancel policies will be applied and the birthday party will be canceled.  If the deposit is refundable the customer will receive a refund.
                            </DialogContentText>
                        }
                        {
                            cancelType && cancelType === 'refund_deposit' &&
                            <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                                The deposit will be refunded and the birthday party will be canceled.
                            </DialogContentText>
                        }
                        {
                            cancelType && cancelType === 'facility_void' &&
                            <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                                The deposit and birthday party will be refunded.  The facility will be charged the My Sport Space application fee for both the deposit and the total amount.
                            </DialogContentText>
                        }
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            Are you sure you want to cancel?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeCancelDialog}>No</Button>
                        <Button onClick={cancelTheParty} variant="contained">Yes</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!isLoading && !alertMessage && showRefundDeposit ? true : false}
                    onClose={closeRefundDeposit}
                >
                    <DialogTitle>Refund Party Deposit</DialogTitle>
                    <DialogContent>
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            Are you sure you want to refund the initial deposit?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeRefundDeposit}>No</Button>
                        <Button onClick={refundTheDeposit} variant="contained">Yes</Button>
                    </DialogActions>
                </Dialog>
            </Paper>

        </Modal>
    )
}

export default BirthdayPartyViewBooked;