import { UserContext } from "../../../contexts/user.context";
import { useState, useContext, useEffect } from "react";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import { Stack, Box, IconButton, Tooltip } from "@mui/material";
import { GetBirthdayPartyGroups, DeleteBirthdayPartyGroups } from "../../../utils/birthday-api/birthday-service";
import BirthdayPartyGroup from "./birthday-party-group.component";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MySportSpaceAlert from "../../alert/alert.component";
import { getDatabase, ref, onValue } from "firebase/database";


const BirthdayPartyListGroups = ({ showActive, showInactive }) => {
    const { userData, isAdmin } = useContext(UserContext);
    const [birthdayPartyGroups, setBirthdayPartyGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editLockName] = useState(`manageAllParties/editLock`);
    const [alertMessage, setAlertMessage] = useState(null);
    const [deleteThisPartyPackageId, setDeleteThisPackageId] = useState(null);

    async function getBirthdayPartyGroups(userData) {

        setIsLoading(true);
        let groups = [];
        if (userData && userData.facilityId) {
            const response = await GetBirthdayPartyGroups(userData.facilityId);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    groups = response.data.birthdayPartyGroups;
                }
            }
            setBirthdayPartyGroups(groups);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        return () => {
            if (userData) {
                removeEditLock(editLockName, userData);
            }
        }

    }, [userData]);

    useEffect(() => {

        if (userData) {
            const db = getDatabase();
            const groupRef = ref(db, `facility-birthday-party-groups/${userData.facilityId}`);

            const unsubscribe = onValue(groupRef, (snapshot) => {
                getBirthdayPartyGroups(userData);
            })
            return unsubscribe;
        }

    }, [userData]);

    const deleteGroup = async (groupId) => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setDeleteThisPackageId(groupId);
            } else {
                setAlertMessage({
                    title: 'Edit Lock',
                    message: `Another user is currently editing the birthday parties.`
                });
            }
        } else {
            setAlertMessage({
                title: 'Not Authorized',
                message: `You are not authorized to delete party packages`
            });
        }
    }

    const closeAlertMessage = () => {
        setAlertMessage(null);
    }

    const deleteThePartyPackage = async () => {
        setIsLoading(true);
        setDeleteThisPackageId(null);
        if (userData && userData.facilityId) {
            const response = await DeleteBirthdayPartyGroups(userData.facilityId, [deleteThisPartyPackageId]);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    setAlertMessage({
                        title: 'Party Package Deleted',
                        message: `The Party Package has been deleted`
                    });
                } else {
                    setAlertMessage({
                        title: 'Party Package NOT Deleted',
                        message: `An error occurred and the party package was NOT deleted`
                    });
                }
            } else {
                setAlertMessage({
                    title: 'Party Package NOT Deleted',
                    message: `An error occurred and the party package was NOT deleted`
                });
            }
        }
        setIsLoading(false);
    }

    const cancelPartyPackageDelete = () => {
        setDeleteThisPackageId(null);
    }

    let filteredBirthdayGroups = birthdayPartyGroups;
    if (!showActive) {
        filteredBirthdayGroups = filteredBirthdayGroups.filter((grp) => {
            return !grp.status || grp.status === 'inactive'
        });
    }

    if (!showInactive) {
        filteredBirthdayGroups = filteredBirthdayGroups.filter((grp) => {
            return grp.status === 'active'
        });
    }

    return (
        <Box display={'flex'}>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            {
                deleteThisPartyPackageId &&
                <MySportSpaceAlert isOpen={true} title={"Permanently Delete This Party Package"} message={"This party package will be deleted permanently. Any parties in this package will be not deleted and moved into single parties."} okButtonText={'Delete'} okAction={deleteThePartyPackage} cancelButtonText={"Cancel"} cancelAction={cancelPartyPackageDelete} ></MySportSpaceAlert>
            }
            {
                isLoading &&
                <Box width={'100%'} mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress sx={{ color: "#14254C" }} />
                </Box>
            }
            {
                !isLoading &&
                <Stack spacing={1} width={'100%'} mt={2}>
                    {
                        filteredBirthdayGroups && filteredBirthdayGroups.map((group) => {
                            return <Stack key={`group-stack-${group.id}`} spacing={1} width={'100%'} display={'flex'} direction={'row'} >
                                <BirthdayPartyGroup key={group.id} group={group}></BirthdayPartyGroup>
                                <Tooltip key={`group-tool-tip-${group.id}`}  title="Permanently Delete">
                                    <IconButton key={`group-icon-btn-${group.id}`}  sx={{ height: '5.0vh' }} onClick={(e) => deleteGroup(group.id)}>
                                        <DeleteForeverIcon key={`group-delete-icon-${group.id}`} ></DeleteForeverIcon>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        })
                    }
                </Stack>
            }
        </Box>

    )
}

export default BirthdayPartyListGroups;