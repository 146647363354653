import { BookingSummaryContainer } from './booking-summary.styles';
import { Fragment, useContext, useState } from 'react';
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import { Button, Box, Typography, Paper, Stack, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import CustomerFormOfPayments from '../customer/customer-form-of-payments/customer-form-of-payments.component';
import BookingReservationSummary from '../bookings/booking-reservation-summary/booking-reservation-summary.component';
import BookingsReviewReservations from '../bookings/bookings-review-reservations/bookings-review-reservations.component';
import AlertAcceptBookingTerms from '../alert-accept-booking-terms/alert-accept-booking-terms.component';
import MySportSpaceLoadingView from '../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { bookReservations } from '../../utils/customer-api/customer-service';
import { UserContext } from '../../contexts/user.context';
import BookingsConfirmation from '../bookings/bookings-confirmation/booking-confirmation.component'

const Decimal = require('decimal.js');

const BookingSummary = () => {

    const { reset, resetBookingInput, backToSearchUrl, setFormOfPayment, formOfPayment, totalWithoutServiceCharge, searchResultsToBook } = useContext(SearchForReservationContext);
    const [showBookingMessage, setShowBookingMessage] = useState(false);
    const [bookingTheReservations, setBookingTheReservations] = useState(false);
    const { userData } = useContext(UserContext);
    const [title, setTitle] = useState(null);
    const [reservationsBooked, setReservationsBooked] = useState([]);
    const [returnToUrl, setReturnToUrl] = useState(null);

    const navigate = useNavigate();

    const backToReservationSearch = () => {
        resetBookingInput();
        if (backToSearchUrl) {
            navigate(backToSearchUrl);
        } else {
            navigate('/');
        }
    }

    const formOfPaymentSelected = (formOfPaymentId) => {
        if (formOfPayment === formOfPaymentId) {
            setFormOfPayment(null);
        } else {
            setFormOfPayment(formOfPaymentId);
        }
    }

    const bookingTermsWereAccepted = async () => {
        setShowBookingMessage(false)
        setBookingTheReservations(true);
        const bookingResponse = await bookReservations(userData.userId, searchResultsToBook, formOfPayment && formOfPayment === 'Invoice' ? "Invoice" : formOfPayment && formOfPayment.formOfPayment ? formOfPayment.formOfPayment : null, title, true);
        processResponse(bookingResponse);

    }

    const processResponse = (bookingResponse) => {

        if (bookingResponse.status === 200 && bookingResponse.data) {
            const bookingErrors = [];
            const bookingResults = bookingResponse.data.bookingResults;
            if (bookingResults) {
                for (const bookingResult of bookingResults) {
                    if (bookingResult.status === 'failed' || bookingResult.status == "locked") {
                        bookingErrors.push(bookingResult);
                    }
                }
            }
            switch (bookingResponse.data.status) {
                case "success":
                    if (bookingErrors.length <= 0) {
                        setReturnToUrl(backToSearchUrl);
                        reset()
                        setReservationsBooked(bookingResults);
                    } else {
                        alert("Your reservations were unable to be booked.")
                    }
                    break;
                case "failed":
                    if (bookingResults && bookingResults.length > 0) {
                        if (bookingErrors.length === 1) {
                            alert(bookingErrors[0].error);
                        }
                        // setReservationBookingResults(processReservationResults(reservationsToBook, bookingErrors, bookingResults));
                    } else {
                        alert("The reservation was unable to be booked.");
                    }
                    break;
                case "locked":
                    alert("The reservation(s) are no longer available.");
                    break;
                case "master_locked":
                    alert("Booking Unavailable\n\nWe were unable to complete your booking at the moment.  Please try again.\n\n If the issue persists please visit our help desk at https://mysportspace.freshdesk.com/support/home \n");
                    break;
                default:
                    alert("The reservations were not booked.");
                    break;
            }
            setBookingTheReservations(false);
        } else {
            setBookingTheReservations(false);
            alert("The reservations are no longer available. The reservations were not booked.");
        }

    }

    const bookingTermsWereNotAccepted = () => {
        setShowBookingMessage(false);
    }

    const bookTheReservations = (title) => {
        if (!title || title.length <= 0) {
            alert("You must provide a reservation title.\n\nA reservation title helps the facility identify the reservation on their calendar.\n\nExamples:\nTeam Name, Coach Name, Organization, etc. ")
            return
        }
        if (!formOfPayment && totalWithoutServiceCharge.gt(new Decimal(0))) {
            alert("Please select a form of payment.")
            return
        }

        setTitle(title);
        setShowBookingMessage(true);
    }

    return (
        <Stack mt={'9vh'} height={'90%'} width={'100%'} display={'flex'} direction={'column'}>
            {
                showBookingMessage &&
                <AlertAcceptBookingTerms showChargeInfo={totalWithoutServiceCharge.gt(new Decimal(0)) ? true : false} okAction={bookingTermsWereAccepted} cancelAction={bookingTermsWereNotAccepted}></AlertAcceptBookingTerms>
            }
            {bookingTheReservations &&
                <MySportSpaceLoadingView></MySportSpaceLoadingView>
            }
            {reservationsBooked.length > 0 && !bookingTheReservations &&
                <BookingsConfirmation backToSearchUrl={returnToUrl} reservationsBooked={reservationsBooked} title={title}></BookingsConfirmation>
            }
            {reservationsBooked.length <= 0 && !bookingTheReservations &&
                <Box m={{xs: '3.0vw', sm: '3.0vw', md: '1.0vw'}} display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} justifyContent={'flex-start'}>
                        <Button sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '0.75vw' } }} onClick={backToReservationSearch} startIcon={<ArrowBackIcon />}>
                            Back To Search
                        </Button>
                    </Box>
                    <Stack spacing={{xs: 0, sm: 0, md: 10}} display={'flex'} direction={{ xs: 'column', sm: 'column', md: 'row' }} >
                        <Box width={{ xs: '100%', sm: '100%', md: '60%' }} display={'flex'} flexDirection={'column'} >
                            <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"gray"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                Review Reservations
                            </Typography>
                            <Divider></Divider>
                            <BookingsReviewReservations></BookingsReviewReservations>
                        </Box>
                        {totalWithoutServiceCharge.gt(new Decimal(0)) && formOfPayment !== 'Invoice' &&
                            <Stack spacing={1} width={{xs: '100%', sm: '100%', md: '35%'}} >
                                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"gray"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                    Select Payment Method
                                </Typography>
                                <Divider></Divider>
                                <Box width={'100%'} display={'flex'}>
                                    <CustomerFormOfPayments selectFormOfPayment={formOfPaymentSelected}></CustomerFormOfPayments>
                                </Box>
                                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"gray"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                    Reservation Summary
                                </Typography>
                                <Stack spacing={2}>
                                    <Divider></Divider>
                                    <BookingReservationSummary bookTheReservations={bookTheReservations}></BookingReservationSummary>
                                </Stack>
                            </Stack>
                        }
                        {totalWithoutServiceCharge.lte(new Decimal(0)) && formOfPayment !== 'Invoice' &&
                           <Stack spacing={1} width={{xs: '100%', sm: '100%', md: '35%'}} >
                                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"gray"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                    Reservation Summary
                                </Typography>
                                <Divider></Divider>
                                <BookingReservationSummary bookTheReservations={bookTheReservations}></BookingReservationSummary>
                            </Stack>
                        }
                        {formOfPayment === 'Invoice' &&
                            <Stack spacing={1} width={{xs: '100%', sm: '100%', md: '35%'}} >
                                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"gray"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                    Payment method
                                </Typography>
                                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                    Invoice
                                </Typography>
                                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"gray"} fontSize={{ xs: '4.25vw', sm: '4.25vw', md: '1.25vw' }}>
                                    Reservation Summary
                                </Typography>
                                <BookingReservationSummary bookTheReservations={bookTheReservations}></BookingReservationSummary>
                            </Stack>
                        }
                    </Stack>
                </Box>
            }
        </Stack>
    )
}

export default BookingSummary;