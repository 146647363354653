import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { TextField, Autocomplete } from '@mui/material';
import { useState, useEffect } from 'react';


const LocationSearchTextField = ({selectSearchLocation, searchValue}) => {

    const places = useMapsLibrary('places');
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [sessionToken, setSessionToken] = useState(null);
    const [locations, setLocations] = useState([]);

    useEffect(() => {

        if (!places) return;

        setAutocompleteService(new places.AutocompleteService());
        setSessionToken(new places.AutocompleteSessionToken());

        return () => setAutocompleteService(null);
    }, [places]);

    const fetchPredictions = async (value) => {

        if (!autocompleteService || !value) {
            return;
        }
        const request = { types: ['(cities)'], componentRestrictions: { country: 'us' }, input: value, sessionToken };
        const response = await autocompleteService.getPlacePredictions(request);
        if (response.predictions) {
            const newLocations = [];
            for (const prediction of response.predictions) {
                newLocations.push({
                    label: prediction.description, id: prediction.place_id
                })
            }
            setLocations(newLocations);
        } else {
            setLocations([]);
        }
    }

    const onLocationChange = (event) => {
        const { value } = event.target;
        fetchPredictions(value);
    }

    const selectLocation = (event, newValue) => {
        if (selectSearchLocation) {
            selectSearchLocation(newValue);
        }
    }

    return (
        <Autocomplete
            disablePortal
            options={locations}
            onChange={selectLocation}
            value={searchValue}
            renderInput={(params) => <TextField {...params} onChange={onLocationChange} label="Location"/>}
        />
    )
}

export default LocationSearchTextField;