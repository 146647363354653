import { Routes, Route } from 'react-router-dom';
import BirthdayPartyCreate from '../../../components/facility/birthday-party/birthday-party-create.component';
import { UserContext } from '../../../contexts/user.context';
import FacilityUserGuardedRoute from '../../guarded-routes/facility-user-guarded-route.component';
import { useContext } from 'react';
import BirthdayPartyManageAll from '../../../components/facility/birthday-party/birthday-party-manage-all-parties.component';

const FacilityBirthdayPartyRoutes = () => {

    const { userData, userIsLoading } = useContext(UserContext);

    return (
        <Routes>
            <Route path='setup'
                element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><BirthdayPartyCreate /></FacilityUserGuardedRoute>}
            />
            <Route path='/manage-all'
                element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><BirthdayPartyManageAll /></FacilityUserGuardedRoute>}
            />
        </Routes>
    )

}

export default FacilityBirthdayPartyRoutes;